import { RateTypeEnum, ReportSeriesEnum, TimeIntervalBucket } from '@/models/gen/graphql';
import React, { useState } from 'react';
import { TODAY, TODAY_EOD } from '@/constants';
import { getHoursAndMinutesFromMinutes, titleCase } from '@/utils';

import AirlineIataDropdown from '@/components/AirlineDropdown';
import { AirportGroupDropdown } from '@/components/AirportDropdown';
import ClientDropdown from '@/components/ClientDropdown';
import CompanyDropdown from '@/components/CompanyDropdown';
import CurrencyDropdown from '@/components/CurrencyDropdown';
import DateInput from '@/components/DateInput';
import DateRangePicker from '@/components/DateRangePicker';
import DriverDropdown from '@/components/DriverDropdown';
import Dropdown from '@/components/Dropdown';
import EditBucketsModal from '@/pages/Reports/DriveTime/EditBucketsModal';
import EnumDropdown from '@/components/EnumDropdown';
import FormButton from '@/components/FormButton';
import Input from '@/components/Input';
import KindsDropdown from '@/components/KindsDropdown';
import LocationQueryDropdown from '@/components/LocationDropdown';
import RatingInput from '@/components/RatingInput';
import ReportReasonDropdown from '@/components/ReportReasonDropdown';
import Tippy from '@tippyjs/react';
import TripStatusDropdown from '@/components/TripStatusDropdown';
import TripTypeDropdown from '@/components/TripTypeDropdown';

const BucketsFilter = ({ name, value, onChange }: any): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <>
      <FormButton variant="outline-gray" onClick={(): void => setShow(true)}>
        Shifts
      </FormButton>
      <EditBucketsModal
        value={(value || []).map((bucket: TimeIntervalBucket): { name: string; time: number } => ({
          ...bucket,
          time: bucket.hour * 60 + bucket.minute,
        }))}
        show={show}
        onHide={(): void => setShow(false)}
        onSubmit={(buckets: { name: string; time: number }[] = []): void => {
          const result: TimeIntervalBucket[] = buckets.reduce((acc: any[], { name, time }: { name: string; time: number }): any[] => {
            const [hour, minute] = getHoursAndMinutesFromMinutes(time);
            return [...acc, { name, hour, minute }];
          }, []);
          onChange({ target: { name, value: result } });
          setShow(false);
        }}
      />
    </>
  );
};

const handleChange = (onChange, name) => (value) => onChange({ target: { name, value } });

const reportFilters = {
  airlines: ({ report: _report, ...props }: any): JSX.Element => (
    <AirlineIataDropdown {...props} onChange={handleChange(props?.onChange, props?.name)} />
  ),
  airports: ({ report: _report, ...props }: any): JSX.Element => (
    <AirportGroupDropdown
      {...props}
      onChange={handleChange(props?.onChange, props?.name)}
      options={{ locale: { 'Select...': 'Airport' } }}
    />
  ),
  clients: ({ report: _report, ...props }: any): JSX.Element => (
    <ClientDropdown {...props} onChange={handleChange(props?.onChange, props?.name)} />
  ),
  currencies: ({ report: _report, ...props }: any): JSX.Element => (
    <CurrencyDropdown {...props} onChange={handleChange(props?.onChange, props?.name)} />
  ),
  doLocations: ({ report, ...props }: any): JSX.Element => (
    <LocationQueryDropdown
      {...props}
      onChange={handleChange(props?.onChange, props?.name)}
      options={{ locale: { 'Select...': 'D/O Location' } }}
      airports={report?.airports || []}
    />
  ),
  drivers: ({ report, ...props }: any): JSX.Element => (
    <DriverDropdown {...props} onChange={handleChange(props?.onChange, props?.name)} airports={report?.airports || []} />
  ),
  endDatetime: ({ report: _report, ...props }: any): JSX.Element => (
    <DateInput {...props} name="endDatetime" value={Array.isArray(props?.value) ? props?.value?.[0] : props?.value} />
  ),
  kinds: ({ report: _report, ...props }: any): JSX.Element => (
    <KindsDropdown {...props} onChange={handleChange(props?.onChange, props?.name)} />
  ),
  puLocations: ({ report, ...props }: any): JSX.Element => (
    <LocationQueryDropdown
      {...props}
      onChange={handleChange(props?.onChange, props?.name)}
      options={{ locale: { 'Select...': 'P/U Location' } }}
      airports={report?.airports || []}
    />
  ),
  startDatetime: ({ report: _report, ...props }: any): JSX.Element => (
    <DateInput {...props} name="startDatetime" value={Array.isArray(props?.value) ? props?.value?.[0] : props?.value} />
  ),
  dateRange: ({ report: _report, ...props }: any): JSX.Element => (
    <div className="ReportDateRange">
      <DateRangePicker
        {...props}
        name="dateRange"
        value={props?.values || [TODAY, TODAY_EOD]}
        onChange={props.onChange.dateRange('startDatetime', 'endDatetime')}
      />
    </div>
  ),
  types: ({ report: _report, ...props }: any): JSX.Element => (
    <TripTypeDropdown {...props} onChange={handleChange(props?.onChange, props?.name)} />
  ),
  groupBy: ({ filter, ...props }: any): JSX.Element =>
    !!filter && (
      <Dropdown
        {...props}
        items={filter?.map?.((item) => ({ label: item, value: item })) || []}
        onChange={handleChange(props?.onChange, props?.name)}
        options={{ showClearButton: false, locale: { 'Select...': 'Group By' } }}
      />
    ),
  complexFilters: ({ filter, ...props }: any): JSX.Element => {
    return (
      !!filter && (
        <Dropdown
          {...props}
          items={filter?.map?.((item) => ({ label: titleCase(item.replace(/_/g, ' ')), value: item })) || []}
          onChange={handleChange(props?.onChange, props?.name)}
          options={{ locale: { 'Select...': 'Options' } }}
        />
      )
    );
  },
  reason: ({ report: _report, ...props }: any): JSX.Element => (
    <ReportReasonDropdown {...props} onChange={handleChange(props?.onChange, props?.name)} />
  ),
  rating: ({ report: _report, ...props }: any): JSX.Element => <RatingInput {...props} onChange={props?.onChange} />,
  companies: ({ report: _report, ...props }: any): JSX.Element => (
    <CompanyDropdown {...props} onChange={handleChange(props?.onChange, props?.name)} />
  ),
  status: ({ report: _report, ...props }: any): JSX.Element => (
    <TripStatusDropdown {...props} onChange={handleChange(props?.onChange, props?.name)} />
  ),
  series: ({ report: _report, ...props }: any): JSX.Element => (
    <EnumDropdown
      {...props}
      onChange={handleChange(props?.onChange, props?.name)}
      enum={ReportSeriesEnum}
      options={{ locale: { 'Select...': 'Series' } }}
    />
  ),
  thresholds: (props: any): JSX.Element => {
    const handleOnChange = (name: string) => (value: string) =>
      props.onChange({
        target: {
          name: props?.name || 'thresholds',
          value: name === 'min' ? [parseInt(value), parseInt(props?.value?.[1])] : [parseInt(props?.value?.[0]), parseInt(value)],
        },
      });
    return (
      <>
        <Tippy content="Min Threshold">
          <div className="MinThreshold-Input">
            <Input type="number" name="min" placeholder="Min" value={props?.value?.[0] || ''} onChange={handleOnChange('min')} />
          </div>
        </Tippy>
        <Tippy content="Max Threshold">
          <div className="MaxThreshold-Input">
            <Input type="number" name="max" placeholder="Max" value={props?.value?.[1] || ''} onChange={handleOnChange('max')} />
          </div>
        </Tippy>
      </>
    );
  },
  buckets: BucketsFilter,
  rateTypes: ({ report: _report, ...props }: any): JSX.Element => (
    <EnumDropdown
      {...props}
      onChange={handleChange(props?.onChange, props?.name)}
      enum={RateTypeEnum}
      options={{ locale: { 'Select...': 'Rate Type' } }}
    />
  ),
};

export default reportFilters;
