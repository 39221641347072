import './styles.scss';

import { Button, Col, Row } from 'react-bootstrap';
import { Email, EmailTypeEnum, Provider, ProviderTypeEnum } from '@/models/gen/graphql';
import { Validation, createNotification, getBulkValues, getProperty, queryInput } from '@/utils';
import { createAirlineWithEmail, updateAirlineWithEmail } from '@/api/services/airlines';
import { useEffect, useMemo } from 'react';

import EditModal from '@/components/EditModal/new';
import FormButton from '@/components/FormButton';
import FormField from '@/components/FormField';
import Logger from '@/utils/logs';
import PhoneInput from '@/components/MaskedInput/PhoneInput';
import SelectFromEnum from '@/components/SelectFromEnum';
import SelectState from '@/components/SelectState';
import { Toast } from '@/models';
import { createAirlineValidator } from '@/api/services/airlines/createAirlineBulk';
import useForm from 'hooks/useForm';
import { useSearchAirlines } from '@/api/services/providers/searchProviders';
import useValidation from '@/hooks/useValidation';

const log = Logger.of('EditAirlinesModal');

interface Props {
  show: boolean;
  title?: string;
  name: string;
  onHide?: Function;
  onSubmit?: (updates: any, selected: Provider[]) => void;
  onExited?: Function;
  onCancel?: Function;
  data: any;
  options?: {
    selected?: Provider[];
  };
  loading?: boolean;
}

type EditAirlinesState = Partial<Provider> & {
  billingSameAsPrimary?: boolean;
  newEmail?: string;
  newEmailType?: string;
};

// TOOD: remove this
const initState: EditAirlinesState = { type: ProviderTypeEnum.Airline }; // the state needs to be an object for useValidation to work

const EditAirlinesModal = ({ show, onHide, onSubmit, onCancel, onExited, data: initData, options = {}, ...props }: Props): JSX.Element => {
  const [form, onChange, setForm] = useForm<EditAirlinesState>(initState);
  const [isValid, validity] = useValidation(createAirlineValidator, form);

  const [{ data, loading }, { refetch: searchAirlines }] = useSearchAirlines();
  const provider = useMemo((): Provider => data?.rows?.[0], [data]);

  const mode = initData?.id ? 'edit' : 'create';
  const selected = options?.selected || !!provider ? [provider] : [];
  const selectedCount = selected?.length || 0;
  const fields = useMemo(() => (mode === 'edit' ? getBulkValues(selected) : undefined), [selected, mode]);

  const handleSubmit = async (): Promise<void> => {
    try {
      if (onSubmit) await onSubmit(form, selected);
      else {
        if (mode === 'edit') await updateAirlineWithEmail(form, selected);
        else await createAirlineWithEmail(form);
      }
    } catch (err) {
      log.error('handleSubmit', err);
    } finally {
      await onHide();
    }
  };

  const handleSyncPrimaryAndBilling = (event: any): void => {
    const { name, value } = event.target;
    onChange({ target: { name, value } });
    if (form?.billingSameAsPrimary) {
      setForm((current: EditAirlinesState): EditAirlinesState => ({ ...current, [name.replace('primary', 'billing')]: value }));
    }
  };

  const handleAddEmail = (event: any): void => {
    // TODO: Add mutation to add email to the location.
    if (!form?.newEmail) return; // TODO: Add Invalid logic
    if (!form?.newEmailType) return; // TODO: Add Invalid logic
    setForm(
      (current: EditAirlinesState): EditAirlinesState => ({
        ...current,
        emails: [...(current.emails || []), { address: form?.newEmail, type: form?.newEmailType } as Email],
        newEmail: '',
        newEmailType: '',
      })
    );
  };

  const handleRemoveEmail = (index: number): void => {
    setForm(
      (current: EditAirlinesState): EditAirlinesState => ({
        ...current,
        emails: current.emails.filter((email: Email, a: number): boolean => a !== index),
      })
    );
  };

  useEffect((): void => {
    if (form?.billingSameAsPrimary) {
      setForm(
        (current: EditAirlinesState): EditAirlinesState => ({
          ...current,
          billingAddress: current?.primaryAddress,
          billingCityCode: current?.primaryCityCode,
          billingStateCode: current?.primaryStateCode,
          billingZipCode: current?.primaryZipCode,
        })
      );
    }
  }, [form?.billingSameAsPrimary]);

  const handleSearch = async (): Promise<void> => {
    if (!initData?.id || !show) return;
    const res = await searchAirlines([{ id: queryInput(initData?.id) }]);
    const provider = res?.rows?.[0];
    if (!provider) return createNotification('Provider not found.', Toast.Type.WARNING, 'Search Airline');
    setForm(provider);
  };

  useEffect((): void => {
    if (show) {
      handleSearch();
    } else {
      setForm(initState);
    }
  }, [show]);

  const title = props?.title || mode === 'edit' ? 'Edit Airline' : 'Create Airline';

  return (
    <EditModal
      show={show}
      title={title}
      icon="fa fa-location-dot"
      size="xl"
      onHide={onHide}
      onCancel={onCancel}
      onExited={onExited}
      onSubmit={isValid ? handleSubmit : false}
      options={undefined}
      loading={props?.loading || loading}
      name="editAirlines"
      className="EditAirlinesModal"
    >
      <Row className="EditAirlines mt-4">
        <Row>
          <Col md={4}>
            <FormField
              name="name"
              label="Legal Name:"
              value={form?.name || ''}
              onChange={onChange}
              valid={validity?.name?.valid}
              placeholder="Legal Name"
              feedback={validity?.name?.message}
              disabled={(fields?.name || []).length > 1}
              required
            />
            <FormField
              name="displayName"
              label="Shortname:"
              value={form?.displayName || ''}
              onChange={onChange}
              valid={validity?.displayName?.valid}
              placeholder="Shortname"
              feedback={validity?.displayName?.message}
              disabled={(fields?.displayName || []).length > 1}
              required
            />
            <FormField
              name="website"
              label="Website:"
              value={form?.website || ''}
              onChange={onChange}
              valid={validity?.website?.valid}
              placeholder="Website"
              feedback={validity?.website?.message}
              disabled={(fields?.website || []).length > 1}
            />
          </Col>
          <Col md={4}>
            <FormField
              name="iataAirlineCode"
              label="IATA Code:"
              value={form?.iataAirlineCode || ''}
              onChange={onChange}
              valid={!!Validation.convertValidityTypeToBoolean(validity?.iataAirlineCode?.valid as Validation.ValidityType)}
              placeholder="IATA Code"
              feedback={validity?.iataAirlineCode?.message}
              disabled={(fields?.iataAirlineCode || []).length > 1}
            />
            <FormField
              name="referenceNumber"
              label="Ref ID:"
              value={form?.referenceNumber || ''}
              onChange={onChange}
              valid={validity?.referenceNumber?.valid}
              placeholder="Ref ID"
              feedback={validity?.referenceNumber?.message}
              disabled={(fields?.referenceNumber || []).length > 1}
            />
            <FormField
              name="primaryPhoneNumber"
              label="Phone:"
              value={form?.primaryPhoneNumber || ''}
              onChange={onChange}
              valid={validity?.primaryPhoneNumber?.valid}
              placeholder="Phone"
              feedback={validity?.primaryPhoneNumber?.message}
              disabled={(fields?.primaryPhoneNumber || []).length > 1}
              options={{
                input: {
                  as: PhoneInput,
                },
              }}
            />
            <FormField
              type="email"
              name="primaryEmail"
              placeholder="Email"
              label="Email:"
              value={form?.primaryEmail || ''}
              onChange={onChange}
              disabled={selectedCount > 1}
              valid={validity?.primaryEmail?.valid}
            />
          </Col>
          <Col md={4}>
            <FormField
              name="contactPerson"
              label="Contact Person:"
              value={form?.contactPerson || ''}
              onChange={onChange}
              valid={validity?.contactPerson?.valid}
              placeholder="Contact Person"
              feedback={validity?.contactPerson?.message}
              disabled={(fields?.contactPerson || []).length > 1}
            />
            <FormField
              name="mobilePhoneNumber"
              label="Mobile Phone:"
              value={form?.mobilePhoneNumber || ''}
              onChange={onChange}
              valid={validity?.mobilePhoneNumber?.valid}
              placeholder="Mobile Phone"
              feedback={validity?.mobilePhoneNumber?.message}
              disabled={(fields?.mobilePhoneNumber || []).length > 1}
              options={{
                input: {
                  as: PhoneInput,
                },
              }}
            />
            <FormField
              name="occPhoneNumber"
              label="Occ Phone:"
              value={form?.occPhoneNumber || ''}
              onChange={onChange}
              valid={validity?.occPhoneNumber?.valid}
              placeholder="Occ Phone"
              feedback={validity?.occPhoneNumber?.message}
              disabled={(fields?.occPhoneNumber || []).length > 1}
              options={{
                input: {
                  as: PhoneInput,
                },
              }}
            />
            <FormField
              name="faxNumber"
              label="Fax:"
              value={form?.faxNumber || ''}
              onChange={onChange}
              valid={validity?.faxNumber?.valid}
              placeholder="Fax"
              feedback={validity?.faxNumber?.message}
              disabled={(fields?.faxNumber || []).length > 1}
              options={{
                input: {
                  as: PhoneInput,
                },
              }}
            />
          </Col>
        </Row>
      </Row>
      <Row className="mt-4">
        <Col md={6} className="d-flex align-items-center">
          <h5>
            <strong>Primary Address</strong>
          </h5>
        </Col>
        <Col md={6} className="pe-0 d-flex align-items-center justify-content-between">
          <h5>
            <strong>Billing Address</strong>
          </h5>

          <div
            className="bg-gray bg-opacity-10 d-flex align-items-center justify-content-center"
            style={{ borderRadius: '1.5rem 1.5rem 0 0', padding: '1rem 1rem .75rem 1rem' }}
          >
            <input name="billingSameAsPrimary" type="checkbox" checked={form?.billingSameAsPrimary || false} onChange={onChange.toggle} />
            <div className="ps-1">Same As Primary Address</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Row className="bg-gray bg-opacity-10 g-0 {border-radius:1.5rem|0|1.5rem|1.5rem;}">
          <Col md={6} className="p-3">
            <Row style={{ borderRight: '1px solid black' }}>
              <Row>
                <Col>
                  <FormField
                    name="primaryAddress"
                    label="Full Address:"
                    value={form?.primaryAddress || ''}
                    onChange={handleSyncPrimaryAndBilling}
                    valid={validity?.primaryAddress?.valid}
                    placeholder="Full Address"
                    feedback={validity?.primaryAddress?.message}
                    disabled={(fields?.primaryAddress || []).length > 1}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="pe-0">
                  <FormField
                    name="primaryCityCode"
                    label="City:"
                    value={form?.primaryCityCode || ''}
                    onChange={handleSyncPrimaryAndBilling}
                    valid={validity?.primaryCityCode?.valid}
                    placeholder="City"
                    feedback={validity?.primaryCityCode?.message}
                    disabled={(fields?.primaryCityCode || []).length > 1}
                  />
                </Col>
                <Col className="pe-0">
                  <FormField
                    name="primaryStateCode"
                    label="State:"
                    value={form?.primaryStateCode || ''}
                    onChange={handleSyncPrimaryAndBilling}
                    valid={validity?.primaryStateCode?.valid}
                    placeholder="--"
                    feedback={validity?.primaryStateCode?.message}
                    disabled={(fields?.primaryStateCode || []).length > 1}
                    options={{
                      input: {
                        as: SelectState,
                      },
                    }}
                  />
                </Col>
                <Col>
                  <FormField
                    name="primaryZipCode"
                    label="Zip:"
                    type="number"
                    value={form?.primaryZipCode || ''}
                    onChange={handleSyncPrimaryAndBilling}
                    valid={validity?.primaryZipCode?.valid}
                    placeholder="Zip"
                    feedback={validity?.primaryZipCode?.message}
                    disabled={(fields?.primaryZipCode || []).length > 1}
                  />
                </Col>
              </Row>
            </Row>
          </Col>
          <Col md={6} className="p-3">
            <Row>
              <Row>
                <Col>
                  <FormField
                    name="billingAddress"
                    label="Full Address:"
                    value={form?.billingAddress || ''}
                    onChange={onChange}
                    valid={validity?.billingAddress?.valid}
                    placeholder="Full Address"
                    feedback={validity?.billingAddress?.message}
                    disabled={!!form?.billingSameAsPrimary || (fields?.billingAddress || []).length > 1}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="pe-0">
                  <FormField
                    name="billingCityCode"
                    label="City:"
                    value={form?.billingCityCode || ''}
                    onChange={onChange}
                    valid={validity?.billingCityCode?.valid}
                    placeholder="City"
                    feedback={validity?.billingCityCode?.message}
                    disabled={!!form?.billingSameAsPrimary || (fields?.billingCityCode || []).length > 1}
                  />
                </Col>
                <Col className="pe-0">
                  <FormField
                    name="billingStateCode"
                    label="State:"
                    value={form?.billingStateCode || ''}
                    onChange={onChange}
                    valid={validity?.billingStateCode?.valid}
                    placeholder="--"
                    feedback={validity?.billingStateCode?.message}
                    disabled={!!form?.billingSameAsPrimary || (fields?.billingStateCode || []).length > 1}
                    options={{
                      input: {
                        as: SelectState,
                      },
                    }}
                  />
                </Col>
                <Col>
                  <FormField
                    name="billingZipCode"
                    label="Zip:"
                    type="number"
                    value={form?.billingZipCode || ''}
                    onChange={onChange}
                    valid={validity?.billingZipCode?.valid}
                    placeholder="Zip"
                    feedback={validity?.billingZipCode?.message}
                    disabled={!!form?.billingSameAsPrimary || (fields?.billingZipCode || []).length > 1}
                  />
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Row>
      {/* Display emails component */}
      <h3 className="{border-bottom:1px|solid|grey;padding:.7rem|0;}">Emails</h3>
      {form?.emails && (
        <div className="EditAirlinesModal-Emails">
          {(form?.emails || []).map((_email: Email, e: number): JSX.Element => {
            const email = getProperty(`emails.${e}`, form, {});
            return (
              <Row className="mb-1 align-items-center" key={e}>
                <Col md={8}>
                  <FormField
                    label="Email:"
                    name={`emails.${e}.address`}
                    value={email?.address}
                    valid={Validation.isEmail(email.address)}
                    placeholder="Email"
                    onChange={onChange}
                  />
                </Col>
                <Col md={3}>
                  <FormField
                    name={`emails.${e}.type`}
                    label="Type:"
                    value={email?.type}
                    onChange={onChange}
                    source={EmailTypeEnum}
                    placeholder="Role"
                    valid={!!email?.type}
                    disabled={selectedCount > 1}
                    options={{
                      feedback: { asTooltip: true },
                      input: {
                        as: SelectFromEnum,
                      },
                    }}
                  />
                </Col>
                <Col md={1}>
                  <Button
                    className="{margin-top:11px;}"
                    name="REMOVE_EMAIL"
                    variant="outline-danger"
                    onClick={(): void => handleRemoveEmail(e)}
                  >
                    <i className="fa fa-minus align-self-center justify-self-center" />
                  </Button>
                </Col>
              </Row>
            );
          })}
        </div>
      )}
      <Row className="EditAirlinesModal-EmailInput align-items-center">
        <Col md={8}>
          <FormField
            type="email"
            label="Enter Email:"
            name="newEmail"
            placeholder="Enter Email Here"
            value={form?.newEmail || ''}
            onChange={onChange}
            disabled={selectedCount > 1}
            valid={Validation.isEmail(form?.newEmail) ? true : form?.newEmail ? false : undefined}
          />
        </Col>
        <Col md={3}>
          <FormField
            name="newEmailType"
            label="Type:"
            value={form?.newEmailType || ''}
            onChange={onChange}
            source={EmailTypeEnum}
            placeholder="Role"
            valid={form?.newEmailType ? true : undefined}
            disabled={selectedCount > 1}
            options={{
              feedback: { asTooltip: true },
              input: {
                as: SelectFromEnum,
              },
            }}
          />
        </Col>
        <Col md={1}>
          <FormButton
            className="{margin-top:11px;}"
            name="ADD_EMAIL"
            variant="outline-success"
            onClick={handleAddEmail}
            disabled={selectedCount > 1 || !Validation.isEmail(form?.newEmail) || !form?.newEmailType}
            feedback={!Validation.isEmail(form?.newEmail) || !form?.newEmailType ? 'Enter a valid email, and select a type.' : undefined}
          >
            <i className="fa fa-save" />
          </FormButton>
        </Col>
      </Row>
    </EditModal>
  );
};

export default EditAirlinesModal;
