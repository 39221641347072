import {
  AuditSnapshotWithNameConnection,
  AuditSnapshotWithNameSearch,
  SearchSummaryReportSnapshotsResponseSummaryReportSnapshotConnectionArgs,
} from '@/models/gen/graphql';
import {
  ConnectionDetails,
  HandleErrorInput,
  convertConnectionToDetails,
  handleError,
  mergeConnectionDetails as merge,
} from '@/utils/custom';
import { ConnectionOptions, GraphApiMethod } from '@/api/core';
import createGraphApiHook, { CreateGraphApiHookHook } from '@/hooks/createGraphApiHook';

import { SearchSummaryReportSnapshotsDocument } from '@/api/queries';
import { TypedDocumentNode } from '@apollo/client';
import { getProperty } from '@/utils';
import graphApi from '@/api';

// Global Service Variables
const mergeKey = 'searchSummaryReportSnapshots.summaryReportSnapshotConnection';
const onError = (res: HandleErrorInput): void => handleError(res, { notification: { title: 'Search Summary Report Snapshots' } });

// Service Function Signature
interface ServiceMethod {
  (query?: AuditSnapshotWithNameSearch[], options?: ConnectionOptions): Promise<ConnectionDetails<AuditSnapshotWithNameConnection>>;
}

// SearchSummaryReportSnapshotsTable Service
export const { searchSummaryReportSnapshotsTable, refetchRatesTable, useSearchSummaryReportSnapshotsTable } = ((): {
  searchSummaryReportSnapshotsTable: ServiceMethod;
  refetchRatesTable: ServiceMethod;
  useSearchSummaryReportSnapshotsTable: CreateGraphApiHookHook<ServiceMethod>;
} => {
  const [fetch, refetch] = graphApi(SearchSummaryReportSnapshotsDocument, {
    onError,
  }).map(
    (fn: GraphApiMethod<TypedDocumentNode>): ServiceMethod =>
      async (
        query?: AuditSnapshotWithNameSearch[],
        options?: ConnectionOptions
      ): Promise<ConnectionDetails<AuditSnapshotWithNameConnection>> => {
        const args: SearchSummaryReportSnapshotsResponseSummaryReportSnapshotConnectionArgs = {
          input: {
            first: options?.pageSize || null,
            after: options?.pageSize > 0 ? (options?.pageSize * options?.page).toString() : null,
            query,
          },
        };
        const opts = { merge: options?.merge ? mergeKey : undefined };
        const res = await fn(args, opts);
        return convertConnectionToDetails(getProperty(mergeKey, res));
      }
  );
  const useService = createGraphApiHook(fetch, { refetch, merge });
  return {
    searchSummaryReportSnapshotsTable: fetch,
    refetchRatesTable: refetch,
    useSearchSummaryReportSnapshotsTable: useService,
  };
})();
