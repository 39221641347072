import QueryDropdown, { QueryDropdownProps } from '@/components/QueryDropdown';
import { ReactNode, useCallback } from 'react';

import { DropdownItem } from '@/components/Dropdown';
import { FilterOption } from '@/models/gen/graphql';
import { getClients } from '@/api/services/filters/clients';

type ClientDropdownProps = Omit<QueryDropdownProps, 'query'>;
const ClientDropdown = ({ name = 'client', ...props }: ClientDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getClients();
    return res.map?.(
      (option: FilterOption): DropdownItem => ({
        label: option.displayName,
        value: option.id,
        keywords: option.displayName.replace(/[()]/g, '').split(' '), // TODO: Shortname should be handled by BE
      })
    );
  }, []);

  return (
    <QueryDropdown
      {...props}
      name={name}
      query={getItems}
      options={{ ...props?.options, locale: props?.options?.locale || { 'Select...': 'Client' } }}
    />
  );
};

export default ClientDropdown;
