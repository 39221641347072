import './styles.scss';

import { Button, InputGroup } from 'react-bootstrap';
import Field, { FieldProps } from '@/components/Field';
import React, { ChangeEvent, ForwardedRef, InputHTMLAttributes, ReactNode, forwardRef, useImperativeHandle, useRef } from 'react';

import { getClasses } from '@/utils';

export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  onChange?: (value: string) => void;
  isValid?: boolean;
  isInvalid?: boolean;
  isDirty?: boolean;
  ref?: ForwardedRef<HTMLInputElement>;
};

const Input = (() => {
  const Input = ({ onChange, isValid, isInvalid, isDirty, ...inputProps }: InputProps, ref: ForwardedRef<HTMLInputElement>): ReactNode => (
    <input
      {...inputProps}
      className={getClasses(
        'Input',
        'form-control',
        isValid ? 'is-valid' : isInvalid ? 'is-invalid' : undefined,
        isDirty ? 'is-dirty' : undefined,
        inputProps?.className
      )}
      onChange={(event: ChangeEvent<HTMLInputElement>): void => onChange?.(event.target.value)}
      {...(ref ? { ref } : {})}
    />
  );
  return forwardRef(Input);
})();

export type InputFieldProps = InputProps & Omit<FieldProps, 'children'>;
const InputField = (() => {
  const InputField = (
    { label, feedback, valid, required, ...inputProps }: InputFieldProps,
    ref: ForwardedRef<HTMLInputElement>
  ): ReactNode => (
    <Field label={label} feedback={feedback} valid={valid} required={required}>
      <Input {...inputProps} {...(ref ? { ref } : {})} />
    </Field>
  );
  return forwardRef(InputField);
})();

const InputWithIconComponent = ({ icon, ...inputProps }: { icon: ReactNode } & InputProps, ref): ReactNode => {
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: (event): void => {
      event?.preventDefault?.();
      event?.stopPropagation?.();
      if (!inputRef.current) return;
      inputRef.current.focus();
    },
  }));

  return (
    <InputGroup className="InputWithIcon">
      {icon && (
        <InputGroup.Text>
          <Button variant="icon" onClick={(event) => inputRef.current.focus(event)}>
            {icon}
          </Button>
        </InputGroup.Text>
      )}
      <Input {...inputProps} ref={inputRef} />
    </InputGroup>
  );
};
export const InputWithIcon = forwardRef(InputWithIconComponent);

export default Input;
export { InputField };
