import './styles.scss';

import { CST, EST, MST, PST } from '../Icons';
import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useMemo, useState } from 'react';

import NavigationMenu from '@/components/NavigationMenu';
import { QUICK_ROUTES } from '@/constants';
import { Route } from '@/models';
import Shortcut from './Shortcut';
import TimeZone from '@/components/TimeZone';
import useSession from '@/state/session';

const Header = (): JSX.Element => {
  const [title, setTitle] = useState(document.title);
  const [toolbarInfo, setToolbarInfo] = useState(undefined);
  const firstName = useSession(({ state }) => state.user?.firstName || '');
  const menuList = useSession(({ state }) => state?.config?.userConfig?.menus?.shortcuts || QUICK_ROUTES || []);

  const quickRoutes = useMemo(
    () =>
      (menuList || []).map((route: Route, index: number): React.JSX.Element => {
        if (route.url === '/availability') return <Shortcut.Availability {...route} key={index} />;
        return <Shortcut {...route} icon={typeof route?.icon === 'string' ? <i className={route?.icon} /> : route?.icon} key={index} />;
      }),
    [menuList]
  );

  useEffect((): void => {
    setTimeout((): void => setTitle(document.title), 0);
  }, []);

  return (
    <header className="Header">
      <Row className="HeaderNavigation">
        <Col className="d-flex flex-grow-0">
          <NavigationMenu />
        </Col>
        <Col>
          {quickRoutes?.length > 0 && (
            <div className="NavLinks d-flex justify-content-around flex-nowrap flex-grow-0 rounded {gap:26px;}">{quickRoutes}</div>
          )}
        </Col>
        <Col className="position-relative flex-grow-1">
          <div className="text-secondary-subtle d-flex flex-column gap-2 text-end m-0 {white-space:nowrap;top:0;right:0;}">
            <span>Welcome {firstName}</span>
            <span>{title}</span>
          </div>
        </Col>
      </Row>
      <Row className="HeaderFilters">
        <Col id="PageFilters" className="d-flex flex-column" />
      </Row>
      <Row className="HeaderInfo bg-white">
        <Col id="PageInfo" className="d-flex gap-3 text-primary" xs={3} />
        <Col className="d-flex justify-content-between flex-nowrap {line-height:2.25rem;}" xs={6}>
          <div className="d-flex flex-column justify-content-center">
            <TimeZone
              icon={<PST width={30} />}
              className="text-primary"
              label="PST"
              timezone="America/Los_Angeles"
              onHover={(isHovering: boolean): void => setToolbarInfo(isHovering ? 'Pacific Time' : undefined)}
            />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <TimeZone
              icon={<MST width={30} />}
              className="text-primary"
              label="MST"
              timezone="America/Boise"
              onHover={(isHovering: boolean): void => setToolbarInfo(isHovering ? 'Mountain Time' : undefined)}
            />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <TimeZone
              icon={<CST width={30} />}
              className="text-primary"
              label="CST"
              timezone="America/Winnipeg"
              onHover={(isHovering: boolean): void => setToolbarInfo(isHovering ? 'Central Time' : undefined)}
            />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <TimeZone
              icon={<EST width={30} />}
              className="text-primary"
              label="EST"
              timezone="America/New_York"
              onHover={(isHovering: boolean): void => setToolbarInfo(isHovering ? 'Eastern Time' : undefined)}
            />
          </div>
        </Col>
        <Col className="d-flex flex-column justify-content-center" xs={3}>
          <div className="d-flex justify-content-end">
            <div className="InformationToolbar text-left d-flex align-items-center">
              <i className="sv sv-notification-circle {font-size:1.5rem;}" />
              <span className="ps-1 text-primary">{toolbarInfo || 'Information Toolbar'}</span>
            </div>
          </div>
        </Col>
      </Row>
    </header>
  );
};

export default Header;
