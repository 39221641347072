import { Datetime, zeroPadFlightNumber } from '@/utils';
import { InvoiceFormatEnum, Trip } from '@/models/gen/graphql';
import VirtualTable, { DynamicCell, VirtualTableRow } from '@/components/VirtualTable';

import { DATE_FE_FORMAT } from '@/constants';
import EditableRateCell from '@/pages/InvoicePreview/components/EditableRateCell';
import React from 'react';
import { getClasses } from '@/utils/strings';

type InvoicePreviewTableProps = {
  data?: Trip[];
  loading?: boolean;
  format?: InvoiceFormatEnum;
  onChangeRate?: (id: string) => (amount: number) => Promise<void>;
};

const InvoicePreviewTableHeader = {
  index: 'No.',
  type: 'Type',
  scheduledDate: 'Date',
  scheduledTime: 'Time',
  airportCode: 'City',
  servicerIataAirlineCode: 'Airline',
  flightNumber: 'FLT',
  crewId: 'Crew ID',
  pilots: 'PLT',
  attendants: 'FA',
  puLocation: 'P/U',
  doLocation: 'D/O',
  subtotal: 'Subtotal',
  rate: 'Rate',
};

const InvoicePreviewTable = ({
  data = [],
  loading = false,
  format = InvoiceFormatEnum.Standard,
  onChangeRate = (id: string) => async (amount: number) => {},
}: InvoicePreviewTableProps): JSX.Element => (
  <VirtualTable
    name="InvoicePreviewTable"
    data={data}
    header={InvoicePreviewTableHeader}
    rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
      <VirtualTableRow
        className={getClasses(!data?.index ? 'bg-body-secondary' : '')}
        context={{
          ...context,
          rowType: _type,
          data,
          index,
        }}
      >
        <DynamicCell selector="index" placeholder=" " className="text-center alternate" width="calc(100% / 25)" />
        <DynamicCell selector="type" placeholder=" " className="text-center" width="calc(100% / 13)" />
        <DynamicCell
          selector="scheduledDate"
          placeholder=" "
          className="text-center alternate"
          width="calc(100% / 13)"
          render={({ data }: { data: any }): string =>
            data?.scheduled?.trim?.() ? new Datetime(data?.scheduled).format(DATE_FE_FORMAT) : ' '
          }
        />
        <DynamicCell
          selector="scheduledTime"
          placeholder=" "
          className="text-center alternate"
          width="calc(100% / 13)"
          render={({ data }: { data: any }): string => (data?.scheduled?.trim?.() ? new Datetime(data?.scheduled).time : ' ')}
        />
        <DynamicCell selector="airportCode" placeholder=" " className="text-center" width="calc(100% / 13)" />
        <DynamicCell selector="servicerIataAirlineCode" placeholder=" " className="text-center" width="calc(100% / 13)" />
        <DynamicCell
          selector="flightNumber"
          placeholder=" "
          className="text-center"
          width="calc(100% / 13)"
          render={({ data }) => {
            if (data?.name) return data?.name;
            if (data?.flightNumber !== undefined && data?.flightNumber !== null) return zeroPadFlightNumber(data?.flightNumber || 0);
            return '';
          }}
        />
        {format === InvoiceFormatEnum.Crew && (
          <DynamicCell selector="crewId" placeholder=" " className="text-center" width="calc(100% / 13)" />
        )}
        <DynamicCell selector="pilots" placeholder=" " className="text-center alternate" width="calc(100% / 25)" />
        <DynamicCell selector="attendants" placeholder=" " className="text-center alternate" width="calc(100% / 25)" />
        <DynamicCell selector="puLocation" placeholder=" " className="text-center" width="calc(300% / 13)" />
        <DynamicCell selector="doLocation" placeholder=" " className="text-center" width="calc(300% / 13)" />
        <DynamicCell selector="subtotal" placeholder=" " className="text-center" width="calc(100% / 10)" />
        <EditableRateCell
          loading={loading}
          tripId={data?.tripId}
          rateAmount={data?.rate}
          selector="rate"
          placeholder=" "
          className="text-left alternate"
          width="calc(100% / 13)"
          onChange={onChangeRate(data?.tripId)}
          disabled={data?.subtotal ? true : false}
        />
      </VirtualTableRow>
    )}
    locale={{
      'No Records': 'Nothing to Invoice',
    }}
  />
);

export default React.memo(InvoicePreviewTable);
