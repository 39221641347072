import { Button, Col, Row } from 'react-bootstrap';
import { Image, Location, LocationTypeEnum, PickupPoint, Point } from '@/models/gen/graphql';
import MapCoordinatesInput, { MapCoordinate } from '@/components/MapDisplay/MapCoordinatesInput';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import SelectFromEnum, { SelectFromEnumProps } from '@/components/SelectFromEnum';
import { Validation, createNotification, getDiff, onEnter, queryInput } from '@/utils';
import { createLocationWithPickupPoint, updateLocationWithPickupPoint } from '@/api/services/locations';

import Collapsible from '@/components/Collapsible';
import EditImageModal from '@/components/EditImageModal';
import EditModal from '@/components/EditModal/new';
import FileType from '@/models/FileType';
import FormField from '@/components/FormField';
import HasPermission from '@/components/HasPermission';
import Logger from '@/utils/logs';
import NormalizeAddressModal from '@/components/NormalizeAddressModal';
import { OnChange } from '@/hooks/useOnChange';
import PhoneInput from '@/components/MaskedInput/PhoneInput';
import PickupPointInput from '@/components/PickupPointInput';
import PreviewFile from '@/components/PreviewFile';
import ResolveDuplicateModal from '@/components/ManifestOverridesModal/ResolveDuplicateModal';
import { STATUSES } from '@/constants';
import Select from '@/components/Select';
import SelectAirport from '@/components/SelectAirport';
import SelectAirportGroup from '@/components/SelectAirportGroup';
import SelectInt from '@/components/SelectInt';
import SelectState from '@/components/SelectState';
import { Toast } from '@/models';
import ZipCodeInput from '@/components/MaskedInput/ZipCodeInput';
import { createLocationValidator } from '@/api/services/locations/createLocationBulk';
import { getMarkerColor } from '@/utils/styles';
import { handleAddLocationAliases } from '@/api/services/locations/addLocationAliases';
import { useCreateDownloadImportURLs } from '@/api/services/imports/createDownloadImportURLs';
import useForm from '@/hooks/useForm';
import { useSearchLocations } from '@/api/services/locations/searchLocations';
import useValidation from '@/hooks/useValidation';

const log = Logger.of('EditLocationModal');
const SearchableStateDropdown = (props) => <SelectState {...props} searchable />;

export type EditLocationModalProps = {
  show: boolean;
  title?: string;
  onHide: () => void;
  onCancel?: () => void;
  onSubmit?: (update: Location, original: Location, md5ToFileMap: Record<string, File>) => void;
  onAfterSubmit?: (update: Location) => void;
  loading?: boolean;
  data: Partial<Location>;
  options?: {
    importId?: string;
  };
};
type State = {
  loading: boolean;
  duplicates: Location[];
  resolveDuplicates: boolean;
  normalizeAddress: boolean;
  uploadImage: boolean;
  uploadImagePickupPoint: { node: PickupPoint; index: number };
  md5ToFileMap: Record<string, File>;
  center: Point;
};
const initEditLocationModalState: State = {
  loading: false,
  duplicates: [],
  resolveDuplicates: false,
  normalizeAddress: false,
  uploadImage: false,
  uploadImagePickupPoint: undefined,
  md5ToFileMap: {},
  center: undefined,
};
const EditLocationModal = ({
  show,
  onHide,
  onCancel,
  onSubmit,
  onAfterSubmit,
  data: initData,
  options,
  ...props
}: EditLocationModalProps): JSX.Element => {
  // Init State
  const [state, setState] = useState(initEditLocationModalState);
  const { loading, duplicates, resolveDuplicates, normalizeAddress, uploadImage, uploadImagePickupPoint, md5ToFileMap, center } = state;
  const importId = options?.importId || '';

  // Form Management
  const [form, onChange, setForm] = useForm(undefined);
  const [valid, validity] = useValidation(createLocationValidator, form);

  // Init GraphApi Hook
  const [{ data: downloadImportsData }, { fetch: createDownloadImportUrls }] = useCreateDownloadImportURLs();
  const [{ loading: loadingSearchLocations }, { refetch: searchLocations }] = useSearchLocations();
  const original = useRef<Location>(undefined);

  const url = downloadImportsData?.[0]?.url;
  const mode = useRef<'edit' | 'create'>('create');

  // Submit Handler
  const handleSubmit = async (update = undefined): Promise<any> => {
    try {
      const fn = onSubmit ? onSubmit : mode.current === 'edit' ? updateLocationWithPickupPoint : createLocationWithPickupPoint;
      const input: Location = {
        ...(update || form),
        pickupPoints: ((update || form)?.pickupPoints || []).map(({ color: _color, ...point }: any): PickupPoint => point),
      };
      const res = await fn(input, original.current, md5ToFileMap);
      if (onAfterSubmit && res) onAfterSubmit(input);
      return res;
    } catch (err) {
      log.error(err?.message || err);
    }
  };
  const onNormalizeAddress = async (): Promise<void> => {
    try {
      setState((current: State): State => ({ ...current, loading: true }));
      // get address details to compare
      const originalAddress = {
        address: original.current?.address,
        cityName: original.current?.cityName,
        stateCode: original.current?.stateCode,
        zipCode: original.current?.zipCode,
      };
      const updateAddress = {
        address: form?.address,
        cityName: form?.cityName,
        stateCode: form?.stateCode,
        zipCode: form?.zipCode,
      };
      const [partial] = getDiff(originalAddress, updateAddress);
      // if there is a diff enable normalize address flow
      if (Object.keys(partial).length > 0) {
        // Normalize Address Flow
        setState((current: State): State => ({ ...current, normalizeAddress: true, loading: false }));
      } else {
        // if there is no diff handle submit
        await handleSubmit();
        setState((current: State): State => ({ ...current, loading: false }));
        onHide();
      }
    } catch (err) {
      log.error(err?.message || err);
    }
  };
  const handleConfirmAddress = async (confirmedLocation: Location): Promise<void> => {
    try {
      setState((current: State): State => ({ ...current, loading: true }));
      setForm(confirmedLocation);
      // build query
      if (confirmedLocation?.address) {
        // search for duplicates
        const searchLocationsResponse = await searchLocations([{ address: queryInput(confirmedLocation?.address) }]);
        const rows = (searchLocationsResponse?.rows || []).filter((node: Location): boolean => node.id !== initData?.id);
        if (rows.length > 0) {
          setState(
            (current: State): State => ({
              ...current,
              duplicates: rows,
              normalizeAddress: false,
              // Resolve Duplicates Flow
              resolveDuplicates: mode.current === 'edit' ? false : true,
              loading: false,
            })
          );
          if (mode.current === 'edit') {
            createNotification('A location with this address already exists.', Toast.Type.WARNING, 'Resolve Duplicate Locations');
          }
          return;
        }
      }

      // create location
      await handleSubmit(confirmedLocation);
      setState((current: State): State => ({ ...current, normalizeAddress: false, loading: false }));
      onHide();
    } catch (err) {
      log.error(err?.message || err);
    }
  };
  const handleResolveDuplicates = async (loc: Location): Promise<void> => {
    try {
      setState((current: State): State => ({ ...current, loading: true }));
      await handleAddLocationAliases({ aliases: form?.aliases, airports: form?.airports }, loc);
    } catch (err) {
      log.error(err?.message || err);
    } finally {
      setState((current: State): State => ({ ...current, resolveDuplicates: false, loading: false }));
      onHide();
    }
  };
  // Cleanup State Handler
  const handleCleanup = async (): Promise<void> => {
    setState(initEditLocationModalState);
    setForm({});
  };

  // Custom Form Manipulation Methods
  const onAddAlias = async (alias: string): Promise<any> =>
    setForm((current: any): any => ({
      ...current,
      aliases: [...(current?.aliases || []), { name: alias }],
    }));
  const onRemoveAlias = async (index: number): Promise<any> =>
    setForm((current: any): any => ({
      ...current,
      aliases: (current?.aliases || []).filter((_alias: string, a: number): boolean => a !== index),
    }));

  const handleSearch = useCallback(async (): Promise<void> => {
    try {
      if (!show) return;
      if (!initData?.id) {
        const input = {
          countryCode: 'USA',
          radius: 100,
          ...initData,
          type: initData?.type || LocationTypeEnum.Hotel,
          active: Validation.isNumber(initData?.active) ? initData?.active : 1,
          aliases: initData?.aliases || [],
        };
        setForm(input);
        mode.current = 'create';
      } else {
        const res = await searchLocations([{ id: [queryInput(initData?.id)] }]);
        const location: Location = (res?.rows || []).find((node: Location): boolean => node.id === initData?.id);
        if (!location) return createNotification('Location not found.', Toast.Type.WARNING, 'Search Locations');
        original.current = location;
        setForm({
          ...location,
          pickupPoints: (location?.pickupPoints || []).map(
            (point: PickupPoint, p: number): PickupPoint =>
              ({
                ...point,
                color: getMarkerColor(p + 1),
              }) as any
          ),
        });
        setState((current: State): State => ({ ...current, center: location?.coordinates || undefined }));
        mode.current = 'edit';
      }
    } catch (err) {
      console.error(err);
    }
  }, [initData, searchLocations, setForm, show]);

  const onSubmitEditImageModal = async (md5ToFileMap: Record<string, File>, images: FileType.Image[]): Promise<void> => {
    try {
      const files = Object.entries(md5ToFileMap);
      const newImages: Partial<Image>[] = [];

      for (let i = 0; i < files.length; i++) {
        const [md5, file] = files[i];
        const image: Partial<Image> = {
          md5,
          pickupPointId: uploadImagePickupPoint?.node?.id || '',
          filename: file.name,
          path: (images.find((img: FileType.Image): boolean => img.md5 === md5)?.src as string) || '',
        };
        newImages.push(image);
      }

      setForm((current) => {
        const pickupPoints = [...(current?.pickupPoints || [])];
        const { images: currentImages, ...currentPickupPoint } = pickupPoints?.[uploadImagePickupPoint?.index] || [];
        const updatedImages = [...(currentImages || []), ...newImages].filter(
          (image: Image): boolean => !!images.find((node: FileType.Image): boolean => node.md5 === (image?.id || image?.md5))
        );

        pickupPoints.splice(uploadImagePickupPoint?.index, 1, {
          ...currentPickupPoint,
          images: updatedImages,
        });
        return {
          ...current,
          pickupPoints,
        };
      });

      setState((current: State): State => ({ ...current, md5ToFileMap: { ...(current?.md5ToFileMap || {}), ...md5ToFileMap } }));
    } catch (err) {
      log.error(`Failed to Upload Image: ${err.message || err}`).notify({
        title: 'Upload Pickup Point Image',
      });
    }
  };

  // State Helper
  useEffect((): void => {
    if (!show) return setForm(undefined);
    handleSearch();
  }, [handleSearch, setForm, show]);
  useEffect((): void => {
    if (!importId) return;
    createDownloadImportUrls(importId);
  }, [createDownloadImportUrls, importId]);

  const title = props?.title || mode.current === 'edit' ? 'Edit Location' : 'Add Location';

  return (
    <>
      <EditModal
        name="editLocations"
        size="xl"
        title={title}
        subtitle={
          mode.current === 'edit' && (
            <button
              className="Link btn btn-icon"
              onClick={(): Promise<void> =>
                navigator.clipboard.writeText(
                  `${form?.name}\n${`${form?.address}, ${form?.cityName}, ${form?.stateCode} ${form?.zipCode}`}`
                )
              }
            >
              copy address to clipboard
            </button>
          )
        }
        icon="sv sv-locations"
        show={show}
        onHide={onHide}
        onCancel={onCancel}
        onSubmit={valid ? onNormalizeAddress : false}
        onExited={handleCleanup}
        loading={props?.loading || loading || loadingSearchLocations}
        options={{
          footer: {
            submitButtonText: 'Save',
          },
        }}
      >
        <Row className="mt-4">
          <Col>
            <FormField condensed label="Name:" onChange={onChange} name="name" value={form?.name || ''} valid={validity?.name?.valid} />
            <FormField
              condensed
              label="Address:"
              onChange={onChange}
              name="address"
              value={form?.address || ''}
              valid={validity?.address?.valid}
            />
            <FormField
              condensed
              label="City:"
              onChange={onChange}
              name="cityName"
              value={form?.cityName || ''}
              valid={validity?.cityName?.valid}
            />
            <FormField
              condensed
              label="Country:"
              name="countryCode"
              value={form?.countryCode || ''}
              onChange={onChange}
              placeholder="Country"
              valid={validity?.countryCode?.valid}
              options={{
                input: {
                  as: (props) => (
                    <Select {...props} searchable>
                      <option value="USA">United States</option>
                    </Select>
                  ),
                },
              }}
            />
            <FormField
              condensed
              label="State:"
              name="stateCode"
              placeholder="Select States"
              value={form?.stateCode || ''}
              onChange={onChange}
              valid={validity?.stateCode?.valid}
              options={{
                input: {
                  as: SearchableStateDropdown,
                },
              }}
            />
            <FormField
              condensed
              label="Zip Code:"
              name="zipCode"
              value={form?.zipCode || ''}
              onChange={onChange}
              valid={validity?.zipCode?.valid}
              options={{
                input: {
                  as: ZipCodeInput,
                },
              }}
            />
            <FormField
              condensed
              label="Phone Number:"
              name="phoneNumber"
              value={form?.phoneNumber || ''}
              onChange={onChange}
              valid={validity?.phoneNumber?.valid}
              options={{
                input: {
                  as: PhoneInput,
                },
              }}
            />
          </Col>
          <Col>
            <FormField
              condensed
              label="Type:"
              placeholder="Type"
              name="type"
              value={form?.type || ''}
              onChange={onChange}
              valid={validity?.type?.valid}
              options={{
                input: {
                  as: (props: SelectFromEnumProps): ReactNode => <SelectFromEnum {...props} source={LocationTypeEnum} searchable />,
                },
              }}
            />
            <FormField
              condensed
              label="Markets:"
              placeholder="Markets"
              name="airports"
              value={(form?.airports || []).map((node: any): string => node?.airportCode || node)}
              onChange={onChange}
              valid={validity?.airports?.valid}
              searchable
              options={{
                input: {
                  as: SelectAirportGroup,
                },
              }}
            />
            <FormField
              condensed
              label="Primary Market:"
              placeholder="Market"
              name="primaryAirportCode"
              value={form?.primaryAirportCode}
              onChange={onChange}
              searchable
              options={{
                input: {
                  as: SelectAirport,
                },
              }}
            />
            <FormField
              condensed
              label="Status:"
              placeholder="Status"
              name="active"
              value={form?.active || 0}
              onChange={onChange}
              valid={validity?.active?.valid}
              options={{
                input: {
                  as: (props) => (
                    <SelectInt {...props} searchable>
                      {STATUSES.default.map(
                        (node: any, n: number): JSX.Element => (
                          <option key={n} value={node.id}>
                            {node.displayName}
                          </option>
                        )
                      )}
                    </SelectInt>
                  ),
                },
              }}
            />
            <>
              <Row>
                <Col>
                  <h5 className="m-4">Location Aliases</h5>
                </Col>
              </Row>
              {(form?.aliases || []).map(
                (_alias: string, a: number): JSX.Element => (
                  <LocationAlias
                    value={form?.aliases?.[a]?.name || ''}
                    valid={Validation.convertValidityTypeToBoolean(validity?.aliases?.valid)}
                    onChange={onChange}
                    onDelete={(): Promise<void> => onRemoveAlias(a)}
                    index={a}
                    key={a}
                  />
                )
              )}
              <LocationAlias onAdd={onAddAlias} />
            </>
          </Col>
        </Row>
        {/* PickupPoint Input Section */}
        {mode.current !== 'create' && HasPermission.check('allowToViewMap') && (
          <Row className="mt-3">
            <Collapsible
              title="Pickup Points"
              show={!!center}
              disabled={!center}
              className={
                '{max-height:100%!;}>.Collapsible-Body{font-size:1.7rem!;padding-bottom:0.5rem;margin-bottom:0.5rem;border-bottom:1px|solid|#000;letter-spacing:0.25rem}>.Collapsible-Header'
              }
            >
              <MapCoordinatesInput
                name="pickupPoints"
                className="mt-3 {max-height:300px;}"
                value={[{ coordinates: form?.coordinates, radius: form?.radius, color: 'secondary' }, ...(form?.pickupPoints || [])]}
                onChange={(event) => {
                  const { name, value } = event.target;
                  const locationPoint = value.shift();
                  setForm((current: any): any => ({
                    ...current,
                    coordinates: locationPoint?.coordinates,
                    radius: locationPoint?.radius,
                    [name]: value,
                  }));
                }}
                onAddPoint={(event: google.maps.MapMouseEvent, input: { target: { name: string; value: MapCoordinate } }): void => {
                  setForm((current: any): any => ({
                    ...current,
                    pickupPoints: [
                      ...current.pickupPoints,
                      {
                        ...input.target.value,
                        radius: 100,
                        providerId: '',
                        startDatetime: '00:00:00',
                        endDatetime: '23:59:00',
                        instructions: '',
                        keyword: '',
                        color: getMarkerColor((current.pickupPoints || []).length + 1),
                      },
                    ],
                  }));
                }}
                zoom={16}
                center={center}
                options={{
                  streetViewControl: false,
                }}
              />
            </Collapsible>
            <PickupPointInput
              variant="secondary"
              name="coordinates"
              value={{
                coordinates: form?.coordinates,
                radius: form?.radius,
              }}
              onChange={(event): void => {
                const { coordinates, radius } = event.target.value;
                setForm((current: any): any => ({
                  ...current,
                  coordinates,
                  radius,
                }));
              }}
              onMarkerClick={
                form?.coordinates ? (): void => setState((current: State): State => ({ ...current, center: form?.coordinates })) : undefined
              }
              point
            />
            {(form?.pickupPoints || []).map(
              (point, index: number): React.JSX.Element => (
                <PickupPointInput
                  variant={point?.color}
                  name={`pickupPoints.${index}`}
                  value={point}
                  onChange={onChange}
                  onUploadClick={(): void =>
                    setState(
                      (current: State): State => ({
                        ...current,
                        uploadImage: true,
                        uploadImagePickupPoint: { node: point, index },
                      })
                    )
                  }
                  onMarkerClick={
                    point?.coordinates
                      ? (): void => setState((current: State): State => ({ ...current, center: point?.coordinates }))
                      : undefined
                  }
                  key={index}
                  provider
                  point
                  dateRange
                  imageUpload
                />
              )
            )}
          </Row>
        )}
        {importId && url && (
          <Row className="mt-3">
            <Col>
              <PreviewFile className="{height:300px;margin-bottom:1rem;}" src={url} />
            </Col>
          </Row>
        )}
      </EditModal>
      <EditImageModal
        data={(uploadImagePickupPoint?.node?.images || []).map(
          (image: Image): FileType.Image => ({ src: image?.path, md5: image?.id || image?.md5 })
        )}
        show={uploadImage}
        onHide={(): void => setState((current: State): State => ({ ...current, uploadImage: false, uploadImagePickupPoint: undefined }))}
        onSubmit={onSubmitEditImageModal}
      />
      <NormalizeAddressModal
        show={normalizeAddress}
        onHide={(): void =>
          setState((current: State): State => ({ ...current, normalizeAddress: false, resolveDuplicates: false, loading: false }))
        }
        location={form}
        loading={normalizeAddress ? loading : undefined}
        onSubmit={handleConfirmAddress}
      />
      <ResolveDuplicateModal
        show={resolveDuplicates}
        onHide={(): void => setState((current: State): State => ({ ...current, resolveDuplicates: false, loading: false }))}
        loading={resolveDuplicates ? loading : undefined}
        duplicates={duplicates}
        onSubmit={handleResolveDuplicates}
      />
    </>
  );
};

const LocationAlias = ({
  value,
  valid,
  onChange,
  onAdd,
  onDelete,
  index,
}: {
  value?: string;
  valid?: boolean;
  onChange?: OnChange;
  onAdd?: (alias: string) => void;
  onDelete?: () => void;
  index?: number;
}): ReactNode => {
  const [alias, setAlias] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: any): void => {
    if (onChange) return onChange(event);
    setAlias(event.target.value);
  };
  const handleAdd = (): void => {
    onAdd(alias);
    setAlias('');
  };

  return (
    <Row className="mb-2">
      <Col className="{padding:0;}_.input-group-text">
        <FormField
          condensed
          size="sm"
          onChange={handleChange}
          name={`aliases.${index}`}
          value={value || alias}
          valid={valid}
          readOnly={!!onDelete}
          onFocus={(): void => inputRef.current.select()}
          onKeyDown={onEnter(handleAdd)}
          append={
            onDelete ? (
              <Button name="REMOVE_ALIAS" variant="outline-danger" onClick={onDelete}>
                <i className="fa fa-minus" />
              </Button>
            ) : (
              <Button name="ADD_ALIAS" variant="outline-success" onClick={handleAdd}>
                <i className="fa fa-plus" />
              </Button>
            )
          }
          options={{ input: { withRef: inputRef } }}
        />
      </Col>
    </Row>
  );
};

export default React.memo(EditLocationModal);
