import './styles.scss';

import { Datetime, getClasses, parseObj, pascalCase, printScreen, saveFile, stringify, uuid } from '@/utils';
import React, { useMemo, useRef, useState } from 'react';
import VirtualTable, { ExpandCell, VirtualTableRow, useVirtualTable } from '@/components/VirtualTable';
import { reportQueryAsCsv, useReportQuery } from '@/api/services/reports/reportQuery';

import { Alert } from 'react-bootstrap';
import { DATE_INPUT_FORMAT } from '@/constants';
import Details from '@/components/Details';
import DetailsDrawer from './components/DetailsDrawer';
import DynamicChart from './components/DynamicChart';
import Filters from '@/components/Filters';
import { LoadingBlur } from '@/components/LoadingSpinner';
import { Middle } from '@/components/Align';
import ReportTypeDropdown from '@/pages/Reports/DynamicReport/components/ReportTypeDropdown';
import SidebarButton from '@/components/SidebarButton';
import SnapshotsModal from '@/components/SnapshotsModal';
import TemplateCell from '@/components/VirtualTable/TemplateCell';
import reportFilters from './filters';
import { reportTypes } from '..';
import { useParams } from 'react-router-dom';

type DynamicReportState = {
  details: Record<string, unknown>;
  display: ReportDisplayEnum;
  showSnapshots?: boolean;
};
type DynamicReportProps = {
  report?: string;
  onEmpty?: (props) => JSX.Element;
};
enum ReportDisplayEnum {
  TABLE = 'Table',
  CHART = 'Chart',
}
const initDynamicReportState = {
  details: undefined,
  display: ReportDisplayEnum.TABLE,
};

const NoContent = (): JSX.Element => (
  <h3 className="text-gray-subtle text-center">
    <i className="sv sv-chart-growth fa-2x" />
    <br />
    Nothing to Report
  </h3>
);
const useParsedObj = (input) => {
  const lastInput = useRef(input);
  const [result, setResult] = useState({ filters: {}, header: undefined, footer: undefined, tables: [], key: uuid() });
  if (Object.keys(input || {}).length === 0 || stringify.compare(lastInput.current, input)) return result;
  lastInput.current = input || {};
  const { filters = {}, header, footer, tables = [] } = parseObj(input);
  const key = uuid();
  setResult({ filters, header, footer, tables, key });
  return result;
};

const DynamicReport = ({ report, onEmpty: OnEmpty = NoContent }: DynamicReportProps): JSX.Element => {
  const [state, setState] = useState<DynamicReportState>(initDynamicReportState);
  const { details, display, showSnapshots } = state;
  const { type = Object.keys(reportTypes)?.[0] } = useParams();
  const reportType = (report || type) === 'complaintReport' ? 'fcrReport' : report || type;
  const [{ loading, data: { output = undefined, error: gqlError = undefined } = {}, error: apiError = undefined }, { fetch, refetch }] =
    useReportQuery(reportType);
  // TODO: Do not keep this, refactor it better for future errors
  let error = gqlError || apiError;
  if (error?.extensions?.code === 1002) {
    error = undefined;
  }
  const { filters, header, footer, tables, key } = useParsedObj(output);
  if (header?.['Zero Rates'])
    header['Zero Rates'].description = "Number of trips that have not been assigned a rate because a rate doesn't exist in the system";
  const lastQuery = useRef({});

  const getReport = async (values: any): Promise<void> => {
    const payload: any = {};
    // Generate payload.
    Object.entries(values)
      .filter(([key]: [string, unknown]): boolean => key !== 'persist' && Object.keys(filters).includes(key))
      .forEach(([key, value]: any): void => {
        payload[key] = value || null;
      });
    const fn = stringify.compare(lastQuery.current, payload) ? refetch : fetch;
    lastQuery.current = payload;
    return await fn(payload);
  };
  const getCsv = async (): Promise<void> => {
    const payload: any = lastQuery.current;
    const response = await reportQueryAsCsv(reportType)(payload);
    if (!response) return;
    const blob = new Blob([response], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    saveFile(url, `${pascalCase(reportType)}-${new Datetime().format(DATE_INPUT_FORMAT)}.csv`);
  };

  const toggleSnapshotModal = (): void =>
    setState((current: DynamicReportState): DynamicReportState => ({ ...current, showSnapshots: !current?.showSnapshots }));

  const RenderDataSet = useMemo((): React.ComponentType<any> => {
    switch (display) {
      case ReportDisplayEnum.CHART:
        return DynamicChart;
      case ReportDisplayEnum.TABLE:
      default:
        return ReportTable;
    }
  }, [display]);

  const filtersValues = Object.entries(filters).reduce((acc, [key, val]: [string, Record<string, unknown>]) => {
    if (val?.value) acc[key] = val.value;
    return acc;
  }, {});

  return (
    <>
      <Filters
        name={`${reportType}Filters`}
        onSubmit={getReport}
        onReset={(): {} => ({})}
        value={filtersValues}
        primary={({ values = {}, onChange }: any): JSX.Element => (
          <>
            {Object.entries(filters)
              .filter(([key, _value]: [string, any]): boolean => Object.keys(reportFilters).includes(key))
              .slice(0, 6)
              .map(([key]: any, f: number): JSX.Element => {
                const dateRangeValues = [
                  values?.startDatetime || values?.startDatetime?.value?.[0],
                  values?.endDatetime || values?.endDatetime?.value?.[0],
                ].flat();
                if (key === 'endDatetime' && Object.keys(filters).includes('startDatetime')) return null;
                if (key === 'startDatetime' && Object.keys(filters).includes('endDatetime')) key = 'dateRange';
                const Component = reportFilters[key];
                return (
                  <Component
                    name={key}
                    value={values?.[key]}
                    values={dateRangeValues}
                    onChange={onChange}
                    filter={
                      key === 'groupBy' || key === 'complexFilters'
                        ? output?.filters?.find(({ key: filterKey }: any): boolean => filterKey === key)?.options
                        : undefined
                    }
                    report={values}
                    key={f}
                  />
                );
              })}
          </>
        )}
        secondary={
          Object.keys(filters).filter((key: string): boolean => Object.keys(reportFilters).includes(key)).length >= 7
            ? ({ values = {}, onChange }: any): JSX.Element => (
                <>
                  {Object.entries(filters)
                    .filter(([key, _value]: [string, any]): boolean => Object.keys(reportFilters).includes(key))
                    .slice(6)
                    .map(([key]: any, f: number): JSX.Element => {
                      const Component = reportFilters[key];
                      return (
                        <Component
                          name={key}
                          value={values?.[key]}
                          values={[
                            values?.startDatetime?.value || values?.startDatetime,
                            values?.endDatetime?.value || values?.endDatetime,
                          ].flat()}
                          onChange={onChange}
                          filter={
                            key === 'groupBy' || key === 'complexFilters'
                              ? output?.filters?.find(({ key: filterKey }: any): boolean => filterKey === key)?.options
                              : undefined
                          }
                          report={values}
                          key={f}
                        />
                      );
                    })}
                </>
              )
            : undefined
        }
        alternate={(): JSX.Element => <>{!report && <ReportTypeDropdown />}</>}
        submitOnMount={!Object.keys(lastQuery.current).length}
        key={key}
      />
      <div className={`Report ${reportType} py-4`}>
        <LoadingBlur fitViewport loading={loading} />
        {error && (
          <div className="pt-5">
            <Middle.Center>
              <Alert className="text-center" variant="danger">
                <h5>
                  Something went wrong.
                  <small className="d-block mt-3">
                    <em>{error?.message || error}</em>
                  </small>
                </h5>
              </Alert>
            </Middle.Center>
          </div>
        )}
        {!error && (
          <>
            {header && <Details data={Object.entries(header).map(([key, value]: any): any => ({ [key]: value }))} />}
            {!!tables.length &&
              tables.map((table: any, t: number): JSX.Element => {
                const reportFilters = Object.entries(filters).reduce(
                  (acc: Record<string, unknown>, [key, { value = null }]: any): Record<string, unknown> => {
                    if (!key.startsWith('__')) acc[key] = !key.endsWith('s') ? value?.[0] || null : value;
                    return acc;
                  },
                  {}
                );
                const tableFilters =
                  table?.details?.reduce?.(
                    (acc: Record<string, unknown>, { filters = [] }: { filters: Record<string, unknown>[] }): Record<string, unknown> =>
                      filters?.reduce?.((accInner: Record<string, unknown>, filter: Record<string, unknown>): Record<string, unknown> => {
                        Object.entries(filter || {}).forEach(([key, value]: any): void => {
                          if (!key.startsWith('__')) accInner[key] = value;
                        });
                        return accInner;
                      }, acc) || acc,
                    {}
                  ) || {};
                return (
                  <RenderDataSet
                    name={`${reportType}Table`}
                    header={table?.metadata?.header ? table?.metadata?.header : undefined}
                    footer={table?.metadata?.footer ? table?.metadata?.footer : undefined}
                    columns={table?.metadata?.columns}
                    rows={table?.children || []}
                    index={t}
                    key={t}
                    details={details}
                    filters={{ ...reportFilters, ...tableFilters }}
                    onDetails={(detailsObj: Record<string, unknown>): void =>
                      setState((current: DynamicReportState): DynamicReportState => ({ ...current, details: detailsObj }))
                    }
                  />
                );
              })}
            {!loading && !tables.length && (
              <Middle.Center className="pt-5">
                <OnEmpty onSubmit={(): Promise<void> => getReport(lastQuery.current)} />
              </Middle.Center>
            )}
            {footer && <Details data={Object.entries(footer).map(([key, value]: any): any => ({ [key]: value }))} />}
          </>
        )}
        <SidebarButton name="EXPORT_CSV" tooltip="Export as CSV" disabled={loading} onClick={getCsv}>
          <i className="sv sv-excel" />
        </SidebarButton>
        <SidebarButton name="PRINT_SCREEN" tooltip="Print Report" disabled={loading} onClick={printScreen}>
          <i className="sv sv-print" />
        </SidebarButton>
        <SidebarButton
          tooltip={display === ReportDisplayEnum.TABLE ? 'Switch to Chart' : 'Switch to Table'}
          disabled={loading}
          onClick={() =>
            setState(
              (current: DynamicReportState): DynamicReportState => ({
                ...current,
                display: display === ReportDisplayEnum.TABLE ? ReportDisplayEnum.CHART : ReportDisplayEnum.TABLE,
              })
            )
          }
        >
          <i className={`sv sv-${display === ReportDisplayEnum.TABLE ? 'chart-growth' : 'grid'}`} />
        </SidebarButton>
        {/* TODO: Enable when snapshots support for dynamic reports is added on the backend */}
        {reportType === 'clientSummaryReport' && (
          <SidebarButton name="SNAPHOTS" tooltip="Snapshots" disabled={true} onClick={toggleSnapshotModal}>
            <i className="sv sv-camera2" />
          </SidebarButton>
        )}
      </div>
      <DetailsDrawer
        className="ReportDetails"
        show={details}
        onClose={(): void => setState((current: DynamicReportState): DynamicReportState => ({ ...current, details: undefined }))}
        report={report}
        query={lastQuery.current}
      />
      <SnapshotsModal show={showSnapshots} onHide={toggleSnapshotModal} />
    </>
  );
};

type ReportTableProps = {
  name: string;
  columns: any;
  rows: any;
  index: number;
  header?: any;
  footer?: any;
  onDetails?: (detailsObj: Record<string, unknown>) => void;
  condensed?: boolean;
  details?: Record<string, unknown>;
  filters?: Record<string, unknown>;
};

export const ReportTable = ({
  name,
  header,
  footer,
  columns,
  rows,
  index,
  onDetails,
  condensed,
  details,
  filters: tableFilters,
}: ReportTableProps): JSX.Element => {
  const [state, setState] = useState({
    expanded: [],
    sorting: {
      column: undefined,
      direction: undefined,
    },
  });
  const { expanded, sorting } = state;
  const { onExpand, filteredRows, makeSortable } = useVirtualTable(setState, {
    rows: rows || [],
    sorting,
    expanded,
  });
  return (
    <div className={getClasses(condensed !== undefined && condensed !== false ? undefined : 'pt-4')}>
      {header && <Details data={Object.entries(header).map(([key, value]: any): any => ({ [key]: value }))} inline />}
      <VirtualTable
        name={name}
        className="condensed"
        style={{ maxHeight: 'calc(100vh - 29rem)' }}
        header={columns}
        data={filteredRows}
        expanded={expanded}
        dynamicRowHeight
        rowRenderer={({
          index,
          data: { _type, ...data } = {},
          context = {},
          lineage,
        }: {
          index: any;
          data: any;
          context: any;
          lineage: any;
        }): JSX.Element => {
          const rowDetails = [];
          const rowFilters = { ...tableFilters };
          lineage.forEach((next) => {
            next?.details?.forEach?.(({ filters = [], ...rest }: { filters?: Record<string, unknown>[] }): void => {
              filters.forEach((filterObj: Record<string, unknown> = {}): void => {
                Object.entries(filterObj)
                  .filter(([key]: [string, unknown]): boolean => !key.startsWith('__'))
                  .forEach(([key, value]: any): void => {
                    if (value !== null) rowFilters[key] = value;
                  });
              });
              Object.entries(rest)
                .filter(([key]: [string, unknown]): boolean => !key.startsWith('__'))
                .forEach(([key, value]: any): void => {
                  rowDetails.push({ [key]: value });
                });
            });
          });
          data?.details?.forEach?.(({ filters = [], ...rest }: { filters?: Record<string, unknown>[] }): void => {
            filters.forEach((filterObj: Record<string, unknown> = {}): void => {
              Object.entries(filterObj)
                .filter(([key]: [string, unknown]): boolean => !key.startsWith('__'))
                .forEach(([key, value]: any): void => {
                  if (value !== null) rowFilters[key] = value;
                });
            });
            Object.entries(rest)
              .filter(([key]: [string, unknown]): boolean => !key.startsWith('__'))
              .forEach(([key, value]: any): void => {
                rowDetails.push({ [key]: value });
              });
          });
          const detailsInput = { ...rowFilters, details: rowDetails, __id: data?.id || index };

          return (
            <VirtualTableRow
              context={{
                ...context,
                rowType: _type,
                data,
                index,
                expanded: !!expanded.includes(data?.id || index),
              }}
              className={getClasses(
                expanded.includes(data?.id || index) ? 'expanded' : undefined,
                `${data?.id || index}`.includes('_') ? 'nested' : undefined,
                !data?.children?.length ? 'no-children' : undefined,
                stringify.compare(details, detailsInput) ? 'selected' : undefined
              )}
              onDoubleClick={
                _type !== 'header'
                  ? (): void => {
                      if (data?.children?.length) return onExpand(data?.id || index);
                      if (data?.details?.length) onDetails?.(detailsInput);
                    }
                  : undefined
              }
            >
              <ExpandCell className="alternate" onClick={onExpand} />
              {Object.keys(columns).map(
                (col: string, c: number): JSX.Element => (
                  <TemplateCell
                    table={name}
                    className={c > 0 ? 'text-end' : ''}
                    name={col}
                    selector={`values.${col}.value|values.${col}|${col}.value|${col}`}
                    placeholder="--"
                    width={`calc(100% / ${Object.keys(columns)?.length})`}
                    sorting={makeSortable(col)}
                    key={c}
                  />
                )
              )}
            </VirtualTableRow>
          );
        }}
        dynamic={!condensed}
        options={{
          ignoreColumnSettings: index > 0,
        }}
      />
      {footer && <Details data={Object.entries(footer).map(([key, value]: any): any => ({ [key]: value }))} inline />}
    </div>
  );
};

export default DynamicReport;
