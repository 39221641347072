// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RadioButton {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
}
.RadioButton, .RadioButton:hover, .RadioButton:active, .RadioButton:focus, .RadioButton:first-child:hover, .RadioButton:first-child:active, .RadioButton:first-child:focus {
  background-color: transparent;
  color: var(--bs-body);
}
.RadioButton:hover {
  box-shadow: 3px 4px 12px -2px rgba(0, 0, 0, 0.35) inset;
}
.RadioButton.checked {
  border-color: var(--bs-green);
  box-shadow: 3px 4px 12px -2px rgba(0, 0, 0, 0.35) inset;
}
.RadioButton.is-invalid {
  border-color: var(--bs-danger);
}`, "",{"version":3,"sources":["webpack://./src/components/RadioButton/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,WAAA;EACA,qCAAA;EACA,sBAAA;AACF;AAAE;EAOE,6BAAA;EACA,qBAAA;AAJJ;AAME;EACE,uDAAA;AAJJ;AAME;EACE,6BAAA;EACA,uDAAA;AAJJ;AAME;EACE,8BAAA;AAJJ","sourcesContent":[".RadioButton {\n  position: relative;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  gap: 0.5rem;\n  border: 1px solid rgba(0, 0, 0, 0.15);\n  border-radius: 0.75rem;\n}\n.RadioButton, .RadioButton:hover, .RadioButton:active, .RadioButton:focus, .RadioButton:first-child:hover, .RadioButton:first-child:active, .RadioButton:first-child:focus {\n  background-color: transparent;\n  color: var(--bs-body);\n}\n.RadioButton:hover {\n  box-shadow: 3px 4px 12px -2px rgba(0, 0, 0, 0.35) inset;\n}\n.RadioButton.checked {\n  border-color: var(--bs-green);\n  box-shadow: 3px 4px 12px -2px rgba(0, 0, 0, 0.35) inset;\n}\n.RadioButton.is-invalid {\n  border-color: var(--bs-danger);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
