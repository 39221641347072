import React, { useCallback, useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import LoadingSpinner from '@/components/LoadingSpinner';

export type SuggestedRateProps = {
  onCancel: () => void;
  suggestion: {
    getSuggestion: () => Promise<number>;
    applySuggestion: (suggestion: number) => Promise<void>;
    messages?: {
      notFound: string;
    };
  };
};

const SuggestedRate = ({ suggestion, onCancel }: SuggestedRateProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [suggestedValue, setSuggestedValue] = useState<number>(undefined);
  const getSuggestion = useCallback(async () => {
    try {
      setLoading(true);
      const suggestionResult = await suggestion?.getSuggestion?.();
      setSuggestedValue(suggestionResult);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [suggestion]);
  const applySuggestion = async (value: number) => {
    try {
      await suggestion?.applySuggestion?.(value);
      onCancel?.();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getSuggestion();
  }, [getSuggestion]);

  return (
    <div className="d-flex gap-2 align-items-center Suggested-Rates ">
      {loading && <LoadingSpinner size="sm" />}
      {!loading && suggestedValue === undefined && <span>{suggestion?.messages?.notFound}</span>}
      {!loading && suggestedValue && (
        <>
          <span className="flex-grow-1 Suggested-Rates-Value">{`Suggested: ${parseFloat(suggestedValue.toString() || '0').toFixed(2)}`}</span>
          <Button variant="outline-success Suggested-Rates-Apply" onClick={() => applySuggestion(suggestedValue)} size="sm">
            <i className="fa fa-check Suggested-Rates-Icon" />
          </Button>
        </>
      )}
    </div>
  );
};

export default SuggestedRate;
