import { decryptObj, encryptObj } from '@/utils/objects';
import { useNavigate, useSearchParams } from 'react-router-dom';

const useHashNavigation = (): [
  Record<string, unknown>,
  (path: string, obj: Record<string, unknown>) => void,
  (obj: Record<string, unknown>) => void,
  (path: string, obj: Record<string, unknown>) => string,
] => {
  const [searchParams, setQueryString] = useSearchParams();
  const hash = searchParams.get('hash');
  const navigate = useNavigate();
  const setHash = (obj: Record<string, unknown>) => {
    try {
      const hash = encryptObj(obj);
      setQueryString({ hash });
    } catch (err) {
      console.error('Failed to encrypt object', err);
    }
  };
  const navigateWithHash = (path: string, obj: Record<string, unknown>) => {
    try {
      const hash = encryptObj(obj);
      navigate(`${path}?hash=${hash}`);
    } catch (err) {
      console.error('Failed to encrypt object', err);
    }
  };
  const getHash = (path: string, obj: Record<string, unknown>) => {
    try {
      return `${path}?hash=${encryptObj(obj)}`;
    } catch (err) {
      console.error('Failed to encrypt object', err);
    }
  };
  return [decryptObj(hash), navigateWithHash, setHash, getHash];
};

export default useHashNavigation;
