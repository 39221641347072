import './styles.scss';

import { Button, ButtonProps } from 'react-bootstrap';

import { ReactNode } from 'react';
import { getClasses } from '@/utils';

export type RadioButtonProps = { checked?: boolean; onClick?: () => void; valid?: boolean } & Omit<ButtonProps, 'onClick'>;

const RadioButton = ({ checked, onClick, valid, ...buttonProps }: RadioButtonProps): ReactNode => {
  return (
    <Button
      variant="outline-gray"
      {...buttonProps}
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        event.stopPropagation();
        onClick?.();
      }}
      className={getClasses('RadioButton', checked ? 'checked' : '', valid === false ? 'is-invalid' : '')}
      style={{
        ...(buttonProps?.style || {}),
        borderColor: checked ? buttonProps?.style?.borderColor : undefined,
      }}
    />
  );
};

export default RadioButton;
