import { Datetime, createNotification, isDateRangeWithinThreshold } from '@/utils';
import { InvoiceFormatEnum, InvoiceTypeTypeEnum, TripStatusEnum } from '../../models/gen/graphql';
import React, { useCallback, useEffect, useState } from 'react';

import AirlineIataDropdown from '@/components/AirlineDropdown';
import { AirportGroupDropdown } from '@/components/AirportDropdown';
import ClientDropdown from '@/components/ClientDropdown';
import { Collapse } from 'react-bootstrap';
import DateRangePicker from '@/components/DateRangePicker';
import EnumDropdown from '@/components/EnumDropdown';
import FormButton from '../../components/FormButton';
import HasPermission from '@/components/HasPermission';
import QueryFilters from '@/components/QueryFilters';
import { Toast } from '@/models';
import { createComponentQueryState } from '@/state';
import equal from 'fast-deep-equal/es6/react';
import usePopState from '@/hooks/usePopState';

type InvoicePreviewFiltersProps = {
  initValue?: InvoicePreviewFiltersState;
  onSubmit: (filters: InvoicePreviewFiltersState) => Promise<void>;
  onReset?: (filters: InvoicePreviewFiltersState) => Promise<void>;
  loading?: boolean;
  invoiceId?: number;
};
export type InvoicePreviewFiltersState = {
  payerProviderId: string;
  iataAirlineCodes: string[];
  airports: string[];
  types: InvoiceTypeTypeEnum[];
  startDatetime: string;
  endDatetime: string;
  format: InvoiceFormatEnum;
  tripStatus: TripStatusEnum[];
};
export const initInvoicePreviewFiltersState: InvoicePreviewFiltersState = {
  startDatetime: new Datetime().startOf('month').toString(),
  endDatetime: new Datetime().endOf('month').toString(),
  payerProviderId: '',
  iataAirlineCodes: [],
  airports: [],
  types: [],
  format: InvoiceFormatEnum.Standard,
  tripStatus: [],
};

const useInvoiceFilters = createComponentQueryState(initInvoicePreviewFiltersState, {
  reviver: (key: string, value: any) => {
    switch (key) {
      case 'types': {
        const invoiceTypeEnumKeys = Object.values(InvoiceTypeTypeEnum);
        return (Array.isArray(value) ? value : [value]).filter((key) => invoiceTypeEnumKeys.includes(key));
      }
      case 'format': {
        const invoiceFormatEnumKeys = Object.values(InvoiceFormatEnum);
        return invoiceFormatEnumKeys.includes(value) ? value : initInvoicePreviewFiltersState.format;
      }
      case 'tripStatus': {
        const tripStatusEnumKeys = Object.values(TripStatusEnum);
        return (Array.isArray(value) ? value : [value]).filter((key) => tripStatusEnumKeys.includes(key));
      }
      case 'iataAirlineCodes':
      case 'airports': {
        return value ? (Array.isArray(value) ? value : [value]) : initInvoicePreviewFiltersState.airports;
      }
      default: {
        return value;
      }
    }
  },
});

const FormattedInvoiceTypeTypeEnum = Object.entries(InvoiceTypeTypeEnum).reduce(
  (acc, [key, value]) => ({ ...acc, [key.toUpperCase()]: value }),
  {}
);
const FormattedInvoiceFormatEnum = { 'Include Crew ID': InvoiceFormatEnum.Crew };
const FormattedTripStatusEnum = { 'Exclude Cancelled': TripStatusEnum.Active };

const InvoicePreviewFilters = ({ initValue, loading, onSubmit, invoiceId }: InvoicePreviewFiltersProps): JSX.Element => {
  // state
  const state = useInvoiceFilters(({ state }) => state);
  const setState = useInvoiceFilters(({ setState }) => setState);
  const saveState = useInvoiceFilters(({ saveState }) => saveState);
  const rehydrateState = useInvoiceFilters(({ rehydrate }) => rehydrate);

  const [expanded, setExpanded] = useState(false);
  const [lastQuery, setLastQuery] = useState<Partial<InvoicePreviewFiltersState>>(() => (invoiceId && initValue ? initValue : state));

  const handleChange = useCallback(
    (name: string) =>
      (value: unknown): void =>
        setState((current) => ({ ...current, [name]: value })),
    [setState]
  );
  const onExpand = (): void => setExpanded((current: boolean): boolean => !current);

  const handleSubmit = useCallback((): void => {
    if (isDateRangeWithinThreshold(state.startDatetime, state.endDatetime, 30) === false) {
      createNotification('Please select a date range no more than 30 days.', Toast.Type.WARNING, 'Invalid Date Range');
      return;
    }
    setLastQuery(state);
    onSubmit(state);
    saveState();
  }, [state, onSubmit]);
  const handleReset = useCallback((): void => {
    const from = new Datetime().startOf('month').toString();
    const to = new Datetime().endOf('month').toString();
    const result = invoiceId && initValue ? initValue : { ...initInvoicePreviewFiltersState, startDatetime: from, endDatetime: to };
    setState(result);
    setLastQuery(result);
    saveState();
  }, [setState, saveState, initValue, invoiceId]);

  const handlePopState = useCallback(() => rehydrateState().then(handleSubmit), [rehydrateState, handleSubmit]);
  usePopState(handlePopState);

  useEffect(() => {
    if (!invoiceId || !initValue || equal(state, initValue)) return;
    setLastQuery(initValue);
    setState(initValue);
  }, [invoiceId, initValue]);

  return (
    <QueryFilters className="InvoicePreviewFilters justify-content-between">
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2">
          <QueryFilters.Control>
            <DateRangePicker
              name="dates"
              isDirty={state.startDatetime !== lastQuery.startDatetime || state.endDatetime !== lastQuery.endDatetime}
              isInvalid={!isDateRangeWithinThreshold(state.startDatetime, state.endDatetime, 30)}
              value={[state.startDatetime, state.endDatetime]}
              onChange={(event) => {
                const [startDatetime, endDatetime] = event.target.value?.split?.(' - ') || [null, null];
                handleChange('startDatetime')(startDatetime);
                handleChange('endDatetime')(endDatetime);
              }}
            />
          </QueryFilters.Control>
          <QueryFilters.Input>
            <ClientDropdown
              name="payerProviderId"
              value={state.payerProviderId}
              isInvalid={!state.payerProviderId}
              onChange={handleChange('payerProviderId')}
              options={{ locale: { 'Select...': 'Client' } }}
              style={state.payerProviderId !== lastQuery.payerProviderId ? { borderColor: 'var(--bs-secondary)' } : undefined}
            />
          </QueryFilters.Input>
          <QueryFilters.Input>
            <AirlineIataDropdown
              name="iataAirlineCodes"
              value={state.iataAirlineCodes}
              onChange={(val) => handleChange('iataAirlineCodes')(val ? [val] : [])}
              options={{ locale: { 'Select...': 'Airline' } }}
              style={!equal(state.iataAirlineCodes, lastQuery.iataAirlineCodes) ? { borderColor: 'var(--bs-secondary)' } : undefined}
            />
          </QueryFilters.Input>
          <QueryFilters.Input>
            <AirportGroupDropdown
              name="airports"
              value={state.airports}
              onChange={handleChange('airports')}
              options={{ locale: { 'Select...': 'All Airports' } }}
              style={!equal(state.airports, lastQuery.airports) ? { borderColor: 'var(--bs-secondary)' } : undefined}
            />
          </QueryFilters.Input>
          <QueryFilters.Input>
            <EnumDropdown.Multi
              enum={FormattedInvoiceTypeTypeEnum}
              name="types"
              value={state.types}
              onChange={handleChange('types')}
              options={{ locale: { 'Select...': 'All Types' } }}
              style={!equal(state.types, lastQuery.types) ? { borderColor: 'var(--bs-secondary)' } : undefined}
            />
          </QueryFilters.Input>
          <QueryFilters.Control>
            <FormButton variant="outline-secondary-subtle" onClick={onExpand}>
              {!expanded ? 'More' : 'Less'}
            </FormButton>
            <FormButton
              name="submitFilters"
              variant="success"
              className="border-white"
              disabled={!state.payerProviderId || loading}
              onClick={handleSubmit}
            >
              {(loading && <i className="fa fa-spinner fa-pulse" />) || 'Go'}
            </FormButton>
            <FormButton name="resetFilters" variant="outline-secondary-subtle" feedback="Undo filter changes" onClick={handleReset}>
              Reset
            </FormButton>
          </QueryFilters.Control>
        </div>
        <Collapse in={expanded}>
          <div>
            <div className="d-flex gap-2">
              <QueryFilters.Input>
                <EnumDropdown
                  enum={FormattedInvoiceFormatEnum}
                  name="format"
                  value={state.format}
                  onChange={(value: InvoiceFormatEnum): void =>
                    handleChange('format')(value && state.format !== value ? value : InvoiceFormatEnum.Standard)
                  }
                  options={{ locale: { 'Select...': 'Without Crew ID' }, showClearButton: false }}
                  style={state.format !== lastQuery.format ? { borderColor: 'var(--bs-secondary)' } : undefined}
                />
              </QueryFilters.Input>
              <HasPermission name="allowTripStatusInvoiceFilter">
                <QueryFilters.Input>
                  <EnumDropdown
                    enum={FormattedTripStatusEnum}
                    name="tripStatus"
                    value={state.tripStatus}
                    onChange={(value: TripStatusEnum): void =>
                      handleChange('tripStatus')(value && !state.tripStatus.includes(value) ? [value] : [])
                    }
                    options={{ locale: { 'Select...': 'All Statuses' }, showClearButton: false }}
                    style={state.tripStatus !== lastQuery.tripStatus ? { borderColor: 'var(--bs-secondary)' } : undefined}
                  />
                </QueryFilters.Input>
              </HasPermission>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="d-flex justify-content-end gap-2">
        {!!invoiceId && (
          <QueryFilters.Control>
            <FormButton
              name="NEW_INVOICE"
              className="{white-space:nowrap!;}"
              variant="outline-secondary-subtle"
              icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
              feedback="Create New Invoice"
              to="/invoices/0"
              reloadDocument
            >
              Create Invoice
            </FormButton>
          </QueryFilters.Control>
        )}
        <QueryFilters.Control>
          <FormButton
            name="INVOICE_HISTORY"
            icon={<i className="sv sv-invoice-history {font-size:1.5rem;}" />}
            className="{white-space:nowrap!;}"
            variant="outline-secondary-subtle"
            to="/invoices"
          >
            Invoice History
          </FormButton>
        </QueryFilters.Control>
      </div>
    </QueryFilters>
  );
};

export default React.memo(InvoicePreviewFilters);
