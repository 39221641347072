import { ReactNode, useEffect, useRef } from 'react';

import useConfirmation from '@/hooks/useConfirmation';
import useInterval from '@/hooks/useInterval';
import { useLocation } from 'react-router-dom';
import useSessionStorage from '@/hooks/useSessionStorage';

type VersionType = { version: string; release: string; path: string };
// TODO: Confirm these times.
const FETCH_RELEASE_TIME = 1000 * 60 * 10;
const STALE_CHECK_TIME = 1000 * 60 * 3;

const UpgradeConfirmation = (): ReactNode => (
  <div className="text-center">
    <h4 className="mb-4 pb-2 {border-bottom:1px|solid|#ccc!;}">System Update Available</h4>
    <p>This tab is using an outdated version of the app.</p>
    <p>Would you like to reload the tab to get the latest version?</p>
  </div>
);

const useVersionCheck = (): void => {
  const staleTimer = useRef(null);
  const location = useLocation();
  const confirmReload = useConfirmation({
    Body: {
      as: UpgradeConfirmation,
    },
  });
  const [{ version: currentVersion, release: currentRelease, path: lastPath }, setVersion] = useSessionStorage<VersionType>('version', {
    version: undefined,
    release: undefined,
    path: undefined,
  });
  const confirming = useRef(false);

  useInterval(
    (): void => {
      let version = currentVersion;
      const scripts = document.querySelectorAll('script');
      if (!scripts.length) return;
      scripts.forEach((script: HTMLScriptElement): void => {
        if (script.src.startsWith(`${window.location.origin}/main`)) {
          const [_pathFilename, _bundleHash, gitHash] = script.src.replace(window.location.origin, '').split('.');
          version = gitHash;
        }
      });
      fetch('/release.json', { cache: 'no-store' })
        .then((res: Response): Promise<unknown> => res.json())
        .then(
          ({ hash }: { hash: string }): VersionType =>
            setVersion((current: VersionType): VersionType => ({ ...current, version, release: hash }))
        )
        .catch((): VersionType => setVersion((current: VersionType): VersionType => ({ ...current, version, release: 'local' })));
    },
    FETCH_RELEASE_TIME,
    true
  );

  const handleConfirm = async (): Promise<void> => {
    try {
      await confirmReload();
      // TODO: Add metric recording for accepted updates here.
      window.location.reload();
    } catch (e) {
      // TODO: Add metric recording for declined updates here.
      console.log('Declined update.');
    }
  };
  const cleanup = (): void => {
    clearTimeout(staleTimer.current);
  };

  useEffect((): (() => void) => {
    if (location.pathname === '/login') return;
    setVersion((current: VersionType): VersionType => ({ ...current, path: location.pathname }));
    if (currentVersion !== currentRelease) {
      if (lastPath && lastPath !== location.pathname) {
        const reloadTimer = setTimeout((): void => {
          clearTimeout(reloadTimer);
          window.location.reload();
        }, 500);
      } else if (!confirming.current) {
        confirming.current = true;
        staleTimer.current = setTimeout(handleConfirm, STALE_CHECK_TIME);
      }
    }
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVersion, currentRelease, location.pathname, lastPath]);
};

export default useVersionCheck;
