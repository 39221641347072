import './styles.scss';

import React, { useEffect } from 'react';

import LoginForm from '../../components/LoginForm';
import { getCookies } from '../../utils';
import { useNavigate } from 'react-router-dom';

const Login = (): JSX.Element => {
  const navigate = useNavigate();

  const onLogin = (success: boolean): void => {
    const redirect = window.localStorage.getItem('redirect') || `/`;
    window.localStorage.removeItem('redirect');
    if (success) navigate(redirect, { replace: true });
  };

  useEffect((): void => {
    // on mount clear user session
    if (getCookies('token').token) {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <div className="LoginPage vw-100 vh-100">
      <div className="Login vh-100 w-50">
        <div className="skyvibe-logo" style={{ width: '100%', height: '150px', backgroundPosition: 'center' }}></div>
        <LoginForm onLogin={onLogin} />
      </div>
    </div>
  );
};

export default Login;
