import React, { ReactNode, useCallback, useMemo } from 'react';

import CurrencyInput from '@/components/CurrencyInput';
import { EditableCell } from '@/components/VirtualTable/components/EditableCell';
import SuggestedRate from '@/pages/InvoicePreview/components/SuggestedRate';
import { formatDollars } from '@/utils';
import getCorrectRatesForTrips from '@/api/services/rates/getCorrectRatesForTrips';
import runRateAmountOnTrips from '@/api/services/trips/runRateAmountOnTrips';

export type EditableRateCellProps = {
  tripId: string;
  rateAmount: number;
  loading?: boolean;
  onChange: (amount: number) => Promise<void>;
  [key: string]: any;
};

const EditableRateCell = ({ tripId, rateAmount, onChange, loading = false, ...props }: EditableRateCellProps) => {
  const formatCell = (val: string) => (Number(val) ? formatDollars(val) : val);

  const onChangeRate = useCallback(
    async (rate?: number | string) => {
      try {
        const amount = parseFloat(`${rate ?? rateAmount}`);
        await runRateAmountOnTrips([{ tripId, amount }]);
        await onChange?.(amount);
      } catch (err) {
        console.error(err);
      }
    },
    [onChange, rateAmount, tripId]
  );
  const RateDisplayWithTripId = useMemo(() => {
    const RateDisplayWithTripId = (props) => <RateDisplay {...props} tripId={tripId} onChange={onChangeRate} />;
    return RateDisplayWithTripId;
  }, [onChangeRate, tripId]);

  return (
    <EditableCell
      {...props}
      loading={loading}
      display={RateDisplayWithTripId}
      onEdit={onChangeRate}
      format={formatCell}
      options={{
        input: {
          as: ({ value, onChange, onSubmit, onCancel }) => (
            <CurrencyInput
              name="RATE_INPUT_FIELD"
              className="border-primary"
              value={value}
              onChange={onChange}
              autoFocus
              onKeyDown={(event) => {
                switch (event.key) {
                  case 'Enter': {
                    onChange(event.target.value);
                    return onSubmit(event.target.value);
                  }
                  case 'Escape':
                    return onCancel();
                }
              }}
            />
          ),
        },
      }}
    />
  );
};

type RateDisplayProps = {
  tripId: string;
  onChange: (data?: string | number) => Promise<void>;
  onCancel: () => void;
  onValueChange: (value: number) => void;
  children: React.ReactNode;
};
const RateDisplay = ({ tripId, onChange, onCancel, onValueChange, children }: RateDisplayProps): ReactNode => {
  const onSuggestRate = async (id: string): Promise<number> => {
    try {
      const suggestedRate = await getCorrectRatesForTrips([id]);
      return suggestedRate?.[0]?.newRate?.rate;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="d-flex flex-column gap-2">
      <SuggestedRate
        onCancel={onCancel}
        suggestion={{
          getSuggestion: async (): Promise<number> => {
            return onSuggestRate(tripId);
          },
          applySuggestion: async (suggestion: number): Promise<void> => {
            await onValueChange?.(suggestion);
            await onChange(suggestion);
          },
          messages: {
            notFound: 'No suggested rate found',
          },
        }}
      />
      {children}
    </div>
  );
};

export default EditableRateCell;
