import { AcceptInputOption, Completion, CompletionCurrencyEnum, FilterOption, TripState } from '@/models/gen/graphql';
import { Col, Row } from 'react-bootstrap';
import { Datetime, Validation } from '@/utils';
import React, { useMemo } from 'react';

import EditCompletionModalFooter from '@/components/EditCompletionModal/EditCompletionModalFooter';
import EditModal from '@/components/EditModal/new';
import FormField from '@/components/FormField';
import { LoadingBlur } from '@/components/LoadingSpinner';
import RadioButton from '@/components/RadioButton';
import SelectCurrency from 'components/SelectCurrency';
import { createComponentState } from '@/state';
import { getCompletionReasons } from '@/queries';
import { handleDeleteCompletionBulk } from '@/api/services/completion/runRemoveCompletionOnTrip';
import { handleSubmitCompletion } from '@/api/services/completion/runCompletionOnTrip';
import useDb from '@/hooks/useDb';
import useOnChange from '@/hooks/useOnChange';
import { zeroPadFlightNumber } from '@/utils/numbers';

type EditCompletionModalState = {
  show?: boolean;
  tripId?: string;
  servicerIataAirlineCode?: string;
  flightNumber?: number;
  scheduled?: string;
  completion?: Partial<Completion>;
  onSubmit?: (tripId?: string[], data?: TripState) => Promise<void>;
  onHide?: () => void;
};

const initEditCompletionModalState: EditCompletionModalState = {
  show: false,
  completion: {
    completionTypeId: '',
    datetime: new Datetime().toString(),
    cost: 0.0,
    currency: CompletionCurrencyEnum.Usd,
    comment: '',
  },
  tripId: '',
  servicerIataAirlineCode: '',
  flightNumber: 0,
  scheduled: '',
  onSubmit: async (): Promise<void> => undefined,
  onHide: (): void => undefined,
};

export const useEditCompletionModal = createComponentState(initEditCompletionModalState);
const EditCompletion = (): JSX.Element => {
  const [state, setState] = useEditCompletionModal(({ state, setState }) => [state, setState]);
  const {
    show = false,
    tripId = '',
    servicerIataAirlineCode = '',
    flightNumber = 0,
    scheduled = '',
    completion = {},
    onHide = (): void => undefined,
    onSubmit = async (): Promise<void> => undefined,
  } = state;
  const { completionTypeId, datetime, cost, currency, comment } = completion || {};

  const onChange = useOnChange(setState);

  // Init Queries
  const { data: getCompletionReasonsResponse, loading } = useDb('CompletionReasons', getCompletionReasons.query);
  const getCompletionReasonsData = useMemo(
    () => ({
      rows: getCompletionReasonsResponse?.getFilters?.filters?.completionReasons || [],
    }),
    [getCompletionReasonsResponse]
  );

  const handleCostChange = (event: any): void => {
    const { name, value } = event.target;
    const parsedValue = parseFloat(value);
    if (!Validation.isNumber(parsedValue)) return onChange({ target: { name, value: '' } });

    let output: number = 0;
    if (parsedValue > 0) output = parseFloat(parsedValue.toFixed(2));

    onChange({ target: { name, value: output } });
  };

  // CRUD Handler
  const handleSubmit = async (): Promise<void> => {
    handleSubmitCompletion([tripId], completion).then((res) => {
      // if req failed and we didn't already call onSubmit with undefined tripState
      if (!res) return onSubmit?.([tripId]);
      // onSubmit with completion changes
      onSubmit?.([tripId], null);
    });

    setState({ show: false });
  };

  const handleDeleteCompletion = async (): Promise<void> => {
    handleDeleteCompletionBulk([tripId]).then((res) => {
      // if req failed call onSubmit with undefined tripState
      if (!res) return onSubmit?.([tripId]);
      // onSubmit with completion changes
      onSubmit?.([tripId], null);
    });

    setState({ show: false });
  };

  const handleHide = () => {
    onHide?.();
    setState({ show: false });
  };

  const title = `Trip Completion - ${(servicerIataAirlineCode || '').toUpperCase()}${zeroPadFlightNumber(flightNumber) || ''} ${
    scheduled ? new Datetime(scheduled).frontendDatetimeShort : ''
  }`;

  const selectedCompletion = getCompletionReasonsData.rows.find((node: any): boolean => node.id === completionTypeId);
  const showCost =
    ['taxi', 'lyft'].map((str: string): boolean => !!selectedCompletion?.displayName.toLowerCase().includes(str)).includes(true) || false;
  const validity = useMemo((): any => {
    const result: any = {};
    result.completionTypeId = {
      valid: !!completionTypeId,
      message: !Validation.isValidUUID(completionTypeId) ? 'Please select a completion type.' : undefined,
    };
    result.cost = {
      valid: !showCost || Validation.isNumber(cost),
    };
    result.datetime = {
      valid: !!datetime,
    };
    result.currency = {
      valid: !showCost || Object.values(CompletionCurrencyEnum).includes(currency),
    };
    result.comment = {
      valid:
        (!!comment?.length && Validation.isAlphanumeric(comment)) ||
        (completionTypeId &&
          completionTypeId === getCompletionReasonsData?.rows?.find((completion) => completion?.displayName === 'Curbside')?.id),
    };
    return result;
  }, [completionTypeId, cost, currency, comment, datetime, showCost, getCompletionReasonsData]);
  const valid = useMemo(
    (): boolean =>
      !Object.values(validity)
        .map(({ valid }: any): boolean => valid)
        .includes(false),
    [validity]
  );

  return (
    <EditModal
      name="editCompletion"
      icon="sv sv-completion"
      loading={loading}
      title={title}
      show={show}
      onHide={handleHide}
      size="lg"
      options={{
        Footer: (
          <EditCompletionModalFooter onHide={handleHide} onReset={handleDeleteCompletion} onUpdate={valid ? handleSubmit : undefined} />
        ),
      }}
      onSubmit={valid ? handleSubmit : false}
      onCancel={handleDeleteCompletion}
    >
      <LoadingBlur loading={loading} />
      <Row className="d-flex justify-content-center mt-3">
        {/* TextArea Component */}
        <Col className="{height:100%;}_*" md={5}>
          <FormField
            onChange={onChange}
            type="textarea"
            name="completion.comment"
            value={completion?.comment || ''}
            valid={validity?.comment?.valid}
            placeholder="Reason For Manual Completion"
            options={{ input: { className: '{resize:none;}' } }}
            condensed
          />
        </Col>
        {/* Radio Component */}
        <Col className="d-flex flex-column gap-2" xs={3}>
          {(getCompletionReasonsData?.rows || []).map(
            (node: FilterOption, n: number): JSX.Element => (
              <RadioButton
                onClick={() => onChange({ target: { name: 'completion.completionTypeId', value: node.id } })}
                checked={node.id === completion?.completionTypeId}
                valid={validity?.completionTypeId?.valid || false}
                key={n}
              >
                {node.displayName}
              </RadioButton>
            )
          )}
        </Col>
        <Col md={4}>
          <FormField
            label="Completed At:"
            name="completion.datetime"
            type="time"
            value={completion?.datetime ? new Datetime(completion?.datetime).time : ''}
            onChange={onChange.time}
            valid={validity?.datetime?.valid}
          />
          <FormField
            label="Cost:"
            name="completion.cost"
            type="number"
            step={'0.01'}
            min={0}
            value={completion?.cost}
            valid={validity?.cost?.valid}
            onChange={handleCostChange}
            placeholder="Cost"
            options={{ group: { className: showCost ? '' : '{visibility:hidden;}' } }}
          />
          {/* Select Currency */}
          <FormField
            label="Currency:"
            name="completion.currency"
            value={completion?.currency}
            onChange={onChange}
            valid={validity?.currency?.valid}
            placeholder="Currency"
            currencyEnumType="completion"
            options={{ group: { className: showCost ? '' : '{visibility:hidden;}' }, input: { as: SelectCurrency } }}
          />
        </Col>
      </Row>
    </EditModal>
  );
};

export default EditCompletion;
