import './styles.scss';

import { Drawer, DrawerProps } from 'antd';
import React, { ReactNode, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';
import { getClasses } from '@/utils';

export type MenuItem = {
  label?: string;
  icon?: ReactNode;
  menu?: MenuItem[];
  type?: 'group' | 'divider';
  key: string;
  children?: MenuItem[];
  onClick?: (key: string) => void;
};
export type MenuDrawerProps = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  menu: MenuItem[];
  onClick?: (key: string) => void;
  theme?: 'Primary';
} & DrawerProps;

const MenuDrawer = ({ onClick, theme, ...props }: MenuDrawerProps): JSX.Element => {
  const [subMenu, setSubMenu] = useState<React.Key>(undefined);
  const handleClick = useMemo(
    () =>
      (key: string): void => {
        if (!key) return;
        onClick?.(key);
      },
    [onClick]
  );

  const [items, menus] = useMemo<[ReactNode[], ReactNode[]]>((): [ReactNode[], ReactNode[]] => {
    const items = [];
    const menus = [];
    props?.menu?.forEach?.((item: MenuItem, index: number): void => {
      const itemKey = `${item.key}_${index}`;
      switch (item.type) {
        case 'divider': {
          items.push(<ListGroup.Item key={itemKey} />);
          break;
        }
        case 'group': {
          items.push(
            <React.Fragment key={itemKey}>
              {item.children.map(
                (item: MenuItem, i: number): ReactNode => (
                  <ListGroup.Item onClick={() => handleClick(item?.key)} key={`${itemKey}_${i}`}>
                    {item.icon && <span>{item.icon}</span>}
                    <span>{item.label}</span>
                  </ListGroup.Item>
                )
              )}
            </React.Fragment>
          );
          break;
        }
        default: {
          if (item?.menu?.length > 0) {
            items.push(
              <ListGroup.Item onClick={(): void => setSubMenu(itemKey)} key={itemKey}>
                {item.icon && <span>{item.icon}</span>}
                <span>{item.label}</span>
              </ListGroup.Item>
            );
            menus.push(
              <MenuDrawer
                className={getClasses('MenuDrawer-Submenu', theme ? `MenuDrawer-Theme-${theme}` : undefined, props?.className)}
                placement={props?.placement}
                menu={item.menu}
                onClick={onClick}
                open={subMenu === itemKey}
                onClose={(): void => setSubMenu(undefined)}
                width={props?.width}
                push={props?.width ? { distance: parseInt(`${props?.width}`) * 2 } : undefined}
                header={
                  <div className="d-flex gap-2">
                    {item.icon}
                    <span className="flex-grow-1">{item.label}</span>
                  </div>
                }
                styles={{
                  mask: {
                    background: 'transparent',
                  },
                }}
                key={`${itemKey}_menu`}
              />
            );
          } else if (`${item?.key}`?.startsWith('/')) {
            items.push(
              <Link
                to={item.key.startsWith('/invoices/0') ? '/invoices/0' : (item.key as string)}
                reloadDocument={item.key.split('?')[0] === window.location.pathname}
                key={itemKey}
              >
                <ListGroup.Item>
                  {item.icon && <span>{item.icon}</span>}
                  <span>{item.label}</span>
                </ListGroup.Item>
              </Link>
            );
          } else {
            items.push(
              <ListGroup.Item onClick={(): void => handleClick(item?.key)} key={itemKey}>
                {item.icon && <span>{item.icon}</span>}
                <span>{item.label}</span>
              </ListGroup.Item>
            );
            break;
          }
        }
      }
    });
    return [items, menus];
  }, [handleClick, onClick, props?.className, props?.menu, props?.placement, props?.width, subMenu, theme]);

  return (
    <Drawer
      {...props}
      className={getClasses('MenuDrawer', theme ? `MenuDrawer-Theme-${theme}` : undefined, props?.className)}
      title={props?.header}
      footer={props?.footer}
      push={false}
    >
      <ListGroup>{items}</ListGroup>
      {menus}
    </Drawer>
  );
};

export default MenuDrawer;
