import './styles.scss';

import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Datetime, Validation, createNotification, onEnter, queryInput } from '../../utils';
import {
  Error,
  Import,
  ImportStatusEnum,
  ImportTypeEnum,
  IngestTripError,
  Provider,
  SearchTypeEnum,
  SortDirectionEnum,
  StagedTrip,
  StagedTripIngestActionEnum,
  UpdatePotentialTripInput,
} from '../../models/gen/graphql';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import VirtualTable, { DynamicCell, SelectCell, VirtualTableRow, useVirtualTable } from '../../components/VirtualTable';
import { useNavigate, useParams } from 'react-router-dom';

import ConfirmationButton from '../../components/ConfirmationButton';
import { DATE_FE_FORMAT_SHORT } from '@/constants';
import { EditableCell } from '@/components/VirtualTable/components/EditableCell';
import Filters from '../../components/Filters';
import FormButton from '../../components/FormButton';
import FormField from '../../components/FormField';
import { LoadingBlur } from '../../components/LoadingSpinner';
import Logger from '../../utils/logs';
import PageInfo from '../../components/PageInfo';
import { Position } from '../../models/constants';
import PreviewFile from '../../components/PreviewFile';
import SelectClient from '../../components/SelectClient';
import SelectImportProvider from '../../components/SelectImportProvider';
import Tippy from '@tippyjs/react';
import { Toast } from '../../models';
import { getClasses } from '../../utils/strings';
import runArchiveImports from '../../api/services/imports/runArchiveImports';
import searchProviders from '../../api/services/providers/searchProviders';
import { stringify } from '../../utils/objects';
import updateImportProvider from '../../api/services/imports/updateImportProvider';
import { useAppState } from '../../store/appReducer';
import useConfirmation from '@/hooks/useConfirmation';
import { useCreateDownloadImportURLs } from '../../api/services/imports/createDownloadImportURLs';
import useModal from '../../hooks/useModal';
import { useRunIngestImports } from '../../api/services/imports/runIngestImports';
import { useRunPreviewImports } from '../../api/services/imports/runPreviewImports';
import { useUpdatePotentialTripBulk } from '../../api/services/imports/updatePotentialTripBulk';
import useViewport from '@/hooks/useViewport';

const log = Logger.of('ManifestPreview');

export enum StagedTripCategoryEnum {
  UPDATABLE = 'CREATE,UPDATE,DELETE,NOOP,EXCLUDE,RESTORE',
  NOT_UPDATABLE = 'NOT',
  ALL = 'ALL',
  NEW = 'CREATE,RESTORE',
  UPDATED = 'UPDATE,REPLACE',
  DELETED = 'DELETE',
  CANCELLED = 'CANCELLED',
  UNCHANGED = 'NOOP',
  EXCLUDED = 'EXCLUDE',
}

type ManifestPreviewState = {
  type: ImportTypeEnum;
  category: StagedTripCategoryEnum;
  search: string;
  pu_loc: string;
  do_loc: string;
  selected: string[];
  stagedTrips: StagedTrip[];
  creating: unknown;
  currentProviderId: string;
  currentPayerProviderId: string;
  sorting: {
    column: string;
    direction: SortDirectionEnum;
  };
};
const initPreviewState: ManifestPreviewState = {
  type: undefined,
  category: StagedTripCategoryEnum.UPDATABLE,
  search: '',
  pu_loc: '',
  do_loc: '',
  selected: [],
  stagedTrips: [],
  creating: undefined,
  currentProviderId: undefined,
  currentPayerProviderId: undefined,
  sorting: {
    column: undefined,
    direction: undefined,
  },
};
const ManifestPreview = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();
  const id = Validation.isValidUUID(params.id) ? params.id : undefined;

  // State and GraphApi Methods
  const [state, setState] = useState(initPreviewState);
  const [{ settings }] = useAppState();
  const [{ data: imports }, { fetch: createDownloadImportUrls }] = useCreateDownloadImportURLs();
  const url = imports?.[0]?.url || '';
  const [{ data: previewData, loading: generatingPreview }, { fetch: runPreviewImports }] = useRunPreviewImports();
  const [{ import: importDetails = {} as Import, stagedTrips: rawStagedTrips = [], updatable = 0, notUpdatable = 0 } = {}] =
    previewData || [];
  const [{ loading: updating }, { fetch: updatePotentialTripBulk }] = useUpdatePotentialTripBulk();
  const [{ loading: ingesting }, { fetch: runIngestImports }] = useRunIngestImports();
  const [
    {
      content: { height },
    },
  ] = useViewport();
  // original staged trips map
  const rawStagedTripsMap = useRef<Map<string, StagedTrip>>(new Map());
  // this ref is used to keep track of updates
  const updateRef = useRef<{ [key in keyof StagedTrip]?: Record<string, Array<string>> }>({});

  const loading = useMemo((): boolean => generatingPreview || updating || ingesting, [generatingPreview, updating, ingesting]);
  const { category, search, selected, currentProviderId, currentPayerProviderId, sorting, stagedTrips } = state;
  const { filename, total, created, deleted, cancelled, excluded, unchanged, updated, providerId, provider, type } = importDetails;

  const { name, displayName } = provider || {};
  const previewPosition = settings?.manifestPreview?.previewPosition || (type === ImportTypeEnum.Addon ? Position.Left : Position.Top);

  const confirmImport = useConfirmation({
    Footer: {
      resolve: 'Save Changes',
      reject: 'Cancel',
    },
  });
  const confirmFailedImport = useConfirmation({
    Footer: {
      resolve: 'Exclude and Continue',
      reject: 'Cancel',
    },
  });

  // shortcuts
  const isIngested = importDetails?.status === ImportStatusEnum.Ingested;
  const excludes = useMemo(() => (stagedTrips || []).filter((trip: any): boolean => selected.includes(trip?.id)), [stagedTrips, selected]);
  const failedTripIdMap = useRef<Map<string, IngestTripError[]>>(new Map());
  const ConfirmImport = useCallback(
    () => (
      <div className="pt-4 pb-4 d-flex flex-column justify-content-center modal-body">
        <div className="modal-title h4">Confirm Import</div>
        <Row
          style={{
            borderBottom: '1px solid #888',
            paddingBottom: '0.5rem',
            marginBottom: '0.5rem',
          }}
        >
          <Col>
            <p className="mb-1">
              <strong>Provider: </strong>
              <span className="text-muted">{displayName || name || 'Unknown'}</span>
            </p>
            <p className="m-0">
              <strong>File Name: </strong>
              <span className="text-muted">{filename}</span>
            </p>
          </Col>
        </Row>
        <Row className="text-center py-4">
          <div className="d-flex justify-content-between">
            <span className="fs-5">
              <strong>
                All:
                <div>{total}</div>
              </strong>
            </span>
            <span className="text-success fs-5">
              <strong>
                New:
                <div>
                  {Math.max(
                    0,
                    (created || 0) -
                      excludes.filter((trip) =>
                        [StagedTripIngestActionEnum.Create, StagedTripIngestActionEnum['Restore']].includes(trip.ingestAction)
                      ).length
                  )}
                </div>
              </strong>
            </span>
            <span className="text-warning fs-5">
              <strong>
                Updated:
                <div>
                  {Math.max(
                    0,
                    (updated || 0) - excludes.filter((trip) => [StagedTripIngestActionEnum.Update].includes(trip.ingestAction)).length
                  )}
                </div>
              </strong>
            </span>
            <span className="text-danger fs-5">
              <strong>
                Deleted:
                <div>
                  {Math.max(
                    0,
                    (deleted || 0) - excludes.filter((trip) => [StagedTripIngestActionEnum.Delete].includes(trip.ingestAction)).length
                  )}
                </div>
              </strong>
            </span>
            <span className="text-muted fs-5">
              <strong>
                Unchanged:
                <div>
                  {Math.max(
                    0,
                    (unchanged || 0) - excludes.filter((trip) => [StagedTripIngestActionEnum.Noop].includes(trip.ingestAction)).length
                  )}
                </div>
              </strong>
            </span>
            <span className="text-muted fs-5">
              <strong>
                Excluded:
                <div>
                  {Math.max(
                    0,
                    (excluded || 0) - excludes.filter((trip) => [StagedTripIngestActionEnum.Exclude].includes(trip.ingestAction)).length
                  )}
                </div>
              </strong>
            </span>
          </div>
        </Row>
        {(excludes?.length || 0) > 0 && (
          <Row>
            <Col className="text-center mt-2">
              <strong className="text-muted">You are excluding {excludes?.length || 0} trips.</strong>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="text-center mt-2">
            <small>
              <strong>Are you sure you want to commit to these changes?</strong>
            </small>
          </Col>
        </Row>
      </div>
    ),
    [excludes, filename, total, created, deleted, excluded, unchanged, updated, name, displayName]
  );
  const ConfirmFailedImport = useCallback(
    ({ failures }: { failures: StagedTrip[] }) => (
      <div className="pt-4 pb-4 d-flex flex-column justify-content-center modal-body">
        <div className="modal-title h4">Failed To Import Trips</div>
        <div className="my-2 fw-bold">Cannot import the following {failures.length || 0} trips:</div>
        <ul>
          {failures.map((trip) => (
            <li key={trip.id}>
              {`${new Datetime(trip.scheduled).frontendDatetimeShort} ${trip.servicerIataAirlineCode}${trip.flightNumber} - ${(failedTripIdMap.current.has(trip.tripId) && failedTripIdMap.current.get(trip.tripId)[0].action) || 'UNKNOWN'}`}
            </li>
          ))}
        </ul>
      </div>
    ),
    [failedTripIdMap.current]
  );

  const [, { show: showEditPotentialTripModal }] = useModal('EditPotentialTrips', {
    onSubmit: async (): Promise<void> => {
      runPreviewImports(id);
    },
  });

  const onChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setState((current) => ({ ...current, [name]: value }));
    },
    [setState]
  );
  const onChangeTab = useCallback((name) => (value) => onChange({ target: { name, value } }), [onChange]);

  const sortedRows = useMemo(() => {
    return (stagedTrips || [])
      .filter(
        (trip) => category === 'ALL' || category.split(',').includes(trip.ingestAction) || category.split(',').includes(trip.statusName)
      )
      .sort((a, b) => (new Date(a.scheduled) < new Date(b.scheduled) ? -1 : new Date(a.scheduled) > new Date(b.scheduled) ? 1 : 0))
      .sort((trip) => ((excludes || []).map((trip) => trip.id).includes(trip.id) ? -1 : 0))
      .filter((trip) => JSON.stringify(trip).toLocaleLowerCase().includes(search.toLocaleLowerCase()));
  }, [stagedTrips, category, excludes, search]);
  const { onSelect, filteredRows, makeSortable } = useVirtualTable(setState, {
    selected: (excludes || []).map(({ id }) => id),
    rows: sortedRows,
    search,
    sorting,
  });

  const confirmAccept = useCallback(
    async (incomingFailedTrips?: StagedTrip[]): Promise<void> => {
      try {
        // get excluded trip ids
        const excludedTripIds = new Set<string>([]);
        [...excludes, ...(incomingFailedTrips || [])].forEach((trip) => {
          if (!trip.id) return console.error("confirmAccept: staged trip doesn't have an id");
          excludedTripIds.add(trip.id);
        });
        const res = await runIngestImports(id, Array.from(excludedTripIds));
        if (!res?.length) return;
        // check for failures
        const failures = res.flatMap((trip) => trip?.failures || []);
        // if there are no failed trip ids
        if (!failures?.length) {
          createNotification('Click here to view Import History.', Toast.Type.SUCCESS, 'Processing import.', undefined, {
            redirect: type === ImportTypeEnum.Manifest ? `/imports/history` : undefined,
          });
          navigate(`/manifests${window.location.search}`);
          return;
        }
        // get failed trip ids
        failedTripIdMap.current = new Map();
        failures.forEach((failure) => {
          if (!failure.id) return console.error('confirmAccept: unknown failed tripId ');
          if (failedTripIdMap.current.has(failure.id)) {
            const existing = failedTripIdMap.current.get(failure.id);
            existing.push(failure);
            return;
          }
          failedTripIdMap.current.set(failure.id, [failure]);
        });
        // get failed staged tripIds
        const failedStagedTripIds: string[] = [];
        const failedStagedTrips: StagedTrip[] = [];
        const excludedStagedTrips: StagedTrip[] = [];
        stagedTrips.forEach((trip) => {
          // get new failed staged trip
          if (failedTripIdMap.current.has(trip.tripId)) {
            failedStagedTripIds.push(trip.id);
            failedStagedTrips.push(trip);
          }
          // get excluded staged trip
          if (excludedTripIds.has(trip.id)) excludedStagedTrips.push(trip);
        });
        if (!failedStagedTripIds.length) return console.error('confirmAccept: failed but no failed staged trip ids.');
        // exclude failed trip if we have the staged trip ids
        const excludedStagedTripIds = (excludedStagedTrips || []).map((trip) => trip.id);
        onSelect([...failedStagedTripIds, ...excludedStagedTripIds]);
        // createNotification('Failed trips will be auto-excluded. Please review and try again.', Toast.Type.WARNING, 'Processing import.');
        confirmFailedImport({ Body: { as: () => <ConfirmFailedImport failures={failedStagedTrips} /> } })
          .then(() => confirmAccept([...failedStagedTrips, ...excludedStagedTrips]))
          .catch(() => {
            // de-select failed trips
            onSelect(excludedStagedTripIds);
          });
      } catch (err) {
        log.error('confirmAccept', err?.message || err);
      }
    },
    [excludes, selected, id, navigate, runIngestImports, type, onSelect, stagedTrips, ConfirmFailedImport, failedTripIdMap.current]
  );
  const handleAccept = useCallback(async (): Promise<void> => {
    try {
      // if the staged trips have changed and we're done editing then update them
      const updateEntries = Object.entries(updateRef.current || {});
      // format the update payload
      const updatePayload: UpdatePotentialTripInput[] = [];
      updateEntries.forEach(([key, changes]) => {
        switch (key) {
          case 'payerProviderId':
          case 'fromManifest':
            Object.entries(changes).forEach(([value, potentialTripIds]) => {
              if (!potentialTripIds) return;
              updatePayload.push({
                query: [{ importId: [{ type: SearchTypeEnum.Or, values: [id] }] }],
                value: { [key]: [value] },
              });
            });
            break;
          default:
            Object.entries(changes).forEach(([value, potentialTripIds]) => {
              if (!potentialTripIds.length) return;
              updatePayload.push({
                query: [{ id: [{ type: SearchTypeEnum.Or, values: potentialTripIds }] }],
                value: { [key]: value === 'null' ? [null] : [value] },
              });
            });
        }
      });
      if (updatePayload.length > 0) {
        await updatePotentialTripBulk(updatePayload);
        await runPreviewImports(id);
      }
      confirmImport({ Body: { as: ConfirmImport } })
        .then(() => confirmAccept())
        .catch(() => {});
    } catch (err) {
      log.error('handleAccept', err?.message || err);
    }
  }, [confirmAccept, confirmImport, ConfirmImport, id, updatePotentialTripBulk, runPreviewImports, updateRef.current]);
  const removeFromUpdateRef = useCallback(
    (id: string, path: 'flightNumber' | 'loopName' | 'pilots' | 'attendants', val: string | number) => {
      const updatePath = updateRef.current?.[path]?.[val];
      if (!updatePath) return;
      const originalIndex = updatePath.findIndex((potentialTripId) => potentialTripId === id);
      if (originalIndex === -1) return;
      updatePath.splice(originalIndex, 1);
      // remove update val from path if empty to keep obj clean
      if (updatePath.length === 0) delete updateRef.current[path][val];
      // remove path from update if empty
      if (Object.keys(updateRef.current[path]).length === 0) delete updateRef.current[path];
    },
    [updateRef.current]
  );
  const setToUpdateRef = useCallback(
    (id: string, path: 'flightNumber' | 'loopName' | 'pilots' | 'attendants', val: string | number) => {
      const updatePath = updateRef.current?.[path]?.[val];
      if (updatePath) {
        updatePath.push(id);
        return;
      }
      // add trip to new update
      updateRef.current = { ...updateRef.current, [path]: { ...(updateRef.current[path] || {}), [val]: [id] } };
    },
    [updateRef.current]
  );
  const onEdit = useCallback(
    (row: StagedTrip) => (path: 'flightNumber' | 'loopName' | 'pilots' | 'attendants') => (val: string | number) => {
      if (!row.potentialTripId || !rawStagedTripsMap.current.has(row.id)) {
        createNotification('Unable to edit trip.', Toast.Type.DANGER, 'Update Potential Trip');
        return false;
      }
      // get original staged trip
      const original = rawStagedTripsMap.current.get(row.id);
      // update
      const result = { ...row, [path]: val };
      // parse int
      if (path === 'pilots' || path === 'attendants' || path === 'flightNumber') result[path] = parseInt(`${val}`) || 0;
      if (path === 'flightNumber') {
        result.loopName = null;
        removeFromUpdateRef(result.potentialTripId, 'loopName', row.loopName);
        if (original.loopName !== result.loopName) setToUpdateRef(result.potentialTripId, 'loopName', 'null');
      }
      if (path === 'loopName') {
        result.flightNumber = null;
        removeFromUpdateRef(result.potentialTripId, 'flightNumber', row.flightNumber);
        if (original.flightNumber !== result.flightNumber) setToUpdateRef(result.potentialTripId, 'flightNumber', 'null');
      }
      // always clear current update
      removeFromUpdateRef(result.potentialTripId, path, row[path]);
      const isOriginal = original[path] === result[path];
      if (isOriginal) {
        setState((current) => ({
          ...current,
          stagedTrips: (current.stagedTrips || []).map((trip) => (trip?.id === result?.id ? result : trip)),
        }));
        return false;
      }
      // append trip id to existing update
      if (!isOriginal) setToUpdateRef(result.potentialTripId, path, val);
      setState((current) => ({
        ...current,
        stagedTrips: (current.stagedTrips || []).map((trip) => (trip?.id === result?.id ? result : trip)),
      }));
      return true;
    },
    [rawStagedTripsMap.current, updateRef.current, removeFromUpdateRef, setToUpdateRef]
  );
  const bulkUpdate = useCallback(
    async (event) => {
      const { name, value, checked } = event?.target || {};
      const obj: Partial<StagedTrip> = {};
      // format update payload
      switch (name) {
        case 'fromManifest': {
          obj.fromManifest = checked ? 1 : 0;
          updateRef.current = { ...updateRef.current, fromManifest: { [obj.fromManifest]: [] } };
          break;
        }
        case 'payerProviderId': {
          obj.payerProviderId = value;
          updateRef.current = { ...updateRef.current, payerProviderId: { [value]: [] } };
          if (!Validation.isValidUUID(value)) {
            obj.payerProvider = null;
            break;
          }
          setState((current) => ({ ...current, currentPayerProviderId: value }));
          const res = await searchProviders([{ id: queryInput(value) }], { pageSize: 1 });
          const provider = res?.rows?.[0];
          if (!provider) return createNotification('contact support: provider not found', Toast.Type.DANGER, 'Update Potential Trip');
          // format provider to match staged trip
          obj.payerProvider = {
            displayName: provider.displayName,
            iataAirlineCode: provider.iataAirlineCode,
            __typename: provider.__typename,
          } as Provider;
          break;
        }
      }
      // if nothing changed don't update state
      if (!Object.keys(obj).length) return;
      // update state
      setState((current) => {
        const original = rawStagedTripsMap.current.get(current.stagedTrips[0].id);
        if (!original) {
          console.error('bulkUpdate: original not found');
          return current;
        }
        // remove from update if value is the same as original
        if (original.fromManifest === obj.fromManifest) delete updateRef.current.fromManifest;
        if (original.payerProviderId === obj.payerProviderId) delete updateRef.current.payerProviderId;
        // update staged trips
        return {
          ...current,
          stagedTrips: (current.stagedTrips || []).map((trip) => ({ ...trip, ...obj })),
        };
      });
    },
    [rawStagedTripsMap, updateRef.current]
  );
  const archiveImport = async () => {
    try {
      await runArchiveImports([id]);
      window.location.href = `/manifests${window.location.search}`;
    } catch (err) {
      console.log('Archive Cancelled.');
    }
  };
  const onChangeProviderId = useCallback(
    async (event) => {
      const { value: providerId } = event?.target || {};
      if (!providerId) return;
      if (!Validation.isValidUUID(providerId))
        return createNotification(
          'contact support: no provider id, servicerIataAirlineCode not set',
          Toast.Type.WARNING,
          'Update Potential Trip'
        );
      setState((current) => ({ ...current, currentProviderId: providerId }));
      await updateImportProvider(id, providerId);
    },
    [id]
  );

  useEffect(() => {
    if (!id) return;
    runPreviewImports(id);
    createDownloadImportUrls(id);
  }, [createDownloadImportUrls, id, runPreviewImports]);
  useEffect(() => {
    if (stringify.compare(rawStagedTrips, stagedTrips)) return;
    // create original staged trips map only when editing
    rawStagedTripsMap.current = new Map();
    rawStagedTrips.forEach((trip: StagedTrip): void => {
      rawStagedTripsMap.current.set(trip.id, trip);
    });
    // reset update ref when original staged trips change
    updateRef.current = {};
    setState((current) => ({ ...current, stagedTrips: rawStagedTrips }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawStagedTrips]);

  const header = useMemo(
    (): Record<string, unknown> => ({
      typeName: 'TYPE',
      scheduledDate: 'DATE',
      scheduledTime: 'SCH',
      bufferMinutes: 'BUFF',
      airportCode: 'CITY',
      servicerIataAirlineCode: 'AL',
      flightNumber: 'FLT',
      pilots: 'PLT',
      attendants: 'FA',
      puLocation: { name: 'PICKUP' },
      doLocation: { name: 'DROPOFF' },
      payerProvider: { displayName: 'CLT' },
      scheduledUtc: 'UTC',
      statusName: 'STATUS',
    }),
    []
  );

  const isSelectable = (row: StagedTrip): boolean =>
    row.ingestAction !== StagedTripIngestActionEnum.Exclude && row.ingestAction !== StagedTripIngestActionEnum.Not;

  const CategoryTabs = useMemo(
    (): JSX.Element => (
      <>
        <Row className="type-buttons">
          <Col className="d-flex gap-1">
            <Button
              variant=""
              style={{
                color: 'black',
                borderColor: category === StagedTripCategoryEnum.UPDATABLE ? 'gray' : '#ddd',
              }}
              onClick={(): void => onChangeTab('category')(StagedTripCategoryEnum.UPDATABLE)}
            >
              Updatable: {updatable || 0}
            </Button>
            <Button
              variant=""
              style={{
                color: 'black',
                borderColor: category === StagedTripCategoryEnum.NOT_UPDATABLE ? 'gray' : '#ddd',
              }}
              onClick={(): void => onChangeTab('category')(StagedTripCategoryEnum.NOT_UPDATABLE)}
            >
              Not Updatable: {notUpdatable || 0}
            </Button>
            <Button
              variant=""
              style={{
                color: 'black',
                borderColor: category === StagedTripCategoryEnum.EXCLUDED ? 'gray' : '#ddd',
              }}
              onClick={(): void => onChangeTab('category')(StagedTripCategoryEnum.EXCLUDED)}
            >
              Excluded: {excluded || 0}
            </Button>
            <Button
              variant=""
              style={{
                color: 'var(--bs-primary)',
                borderColor: category === StagedTripCategoryEnum.ALL ? 'var(--bs-primary)' : '#ddd',
              }}
              onClick={(): void => onChangeTab('category')(StagedTripCategoryEnum.ALL)}
            >
              Total: {total || 0}
            </Button>
          </Col>
        </Row>
        <Row className="type-buttons">
          <Col className="d-flex gap-1">
            <Button
              variant=""
              style={{
                color: 'var(--bs-success)',
                borderColor: category === StagedTripCategoryEnum.NEW ? 'var(--bs-success)' : '#ddd',
              }}
              onClick={(): void => onChangeTab('category')(StagedTripCategoryEnum.NEW)}
            >
              New: {created || 0}
            </Button>
            <Button
              variant=""
              style={{
                color: 'var(--bs-info)',
                borderColor: category === StagedTripCategoryEnum.UPDATED ? 'var(--bs-info)' : '#ddd',
              }}
              onClick={(): void => onChangeTab('category')(StagedTripCategoryEnum.UPDATED)}
            >
              Updated: {updated || 0}
            </Button>
            <Button
              variant=""
              style={{
                color: 'var(--bs-danger)',
                borderColor: category === StagedTripCategoryEnum.DELETED ? 'var(--bs-danger)' : '#ddd',
              }}
              onClick={(): void => onChangeTab('category')(StagedTripCategoryEnum.DELETED)}
            >
              Deleted: {deleted || 0}
            </Button>
            <Button
              variant=""
              style={{
                color: 'var(--bs-secondary)',
                borderColor: category === StagedTripCategoryEnum.CANCELLED ? 'var(--bs-secondary)' : '#ddd',
              }}
              onClick={(): void => onChangeTab('category')(StagedTripCategoryEnum.CANCELLED)}
            >
              Cancelled: {cancelled || 0}
            </Button>
            <Button
              variant=""
              style={{
                color: 'black',
                borderColor: category === StagedTripCategoryEnum.UNCHANGED ? 'gray' : '#ddd',
              }}
              onClick={(): void => onChangeTab('category')(StagedTripCategoryEnum.UNCHANGED)}
            >
              Unchanged: {unchanged || 0}
            </Button>
          </Col>
        </Row>
      </>
    ),
    [category, created, deleted, excluded, notUpdatable, onChangeTab, total, unchanged, updatable, updated, cancelled]
  );
  const ConfirmationButtons = useMemo(
    (): JSX.Element => (
      <>
        <span>
          <Button className="w-100 fs-5" variant="secondary" onClick={() => navigate(-1)}>
            CANCEL
          </Button>
        </span>
        <FormButton className="w-100 fs-5" variant="primary" onClick={handleAccept} disabled={loading || isIngested}>
          ACCEPT
        </FormButton>
      </>
    ),
    [
      handleAccept,
      created,
      deleted,
      displayName,
      excluded,
      excludes,
      filename,
      isIngested,
      loading,
      name,
      navigate,
      total,
      unchanged,
      updated,
    ]
  );
  const Table = useMemo(
    (): JSX.Element => (
      <VirtualTable
        name="manifestPreviewTable"
        header={header}
        data={filteredRows}
        selected={selected}
        height={height}
        {...(previewPosition === Position.Bottom ? { dynamic: true } : {})}
        locale={{
          Select: 'Exclude',
          Deselect: 'Include',
          'Select All': 'Exclude All',
          'Deselect All': 'Include All',
          'No Records': ' ',
          'sv-inbox': ' ',
        }}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
              selected:
                _type === 'header'
                  ? !!selected.length &&
                    stringify.compare(
                      selected.sort(),
                      context.rows
                        .filter(isSelectable)
                        .map((row) => row.id)
                        .sort()
                    )
                  : selected.includes(data?.id || index),
            }}
            className={getClasses(selected.includes(data?.id) ? 'selected' : '', data?.ingestAction)}
          >
            <SelectCell
              className="{max-width:calc(100%|/|13|*|0.5)!;}"
              onClick={(ids, data) => {
                const input = Array.isArray(data)
                  ? data.filter(isSelectable).map((row) => row.id)
                  : isSelectable(data)
                    ? data?.id
                    : undefined;
                if (!input) return;
                onSelect(input);
              }}
              disabled={!isSelectable(data)}
            />
            <DynamicCell
              selector="typeName"
              className="text-center alternate"
              width="calc(100% / 13 * 0.5)"
              sorting={makeSortable('typeName')}
            />
            <DynamicCell
              selector="scheduledDate"
              className="text-center"
              width="calc(100% / 13 * 0.5)"
              sorting={makeSortable('scheduled')}
              render={({ data }: { data: any }): string =>
                data?.scheduled ? new Datetime(data?.scheduled).format(DATE_FE_FORMAT_SHORT) : ''
              }
            />
            <DynamicCell
              selector="scheduledTime"
              className="text-center"
              width="calc(100% / 13 * 0.5)"
              sorting={makeSortable('scheduled')}
              render={({ data }: { data: any }): string => (data?.scheduled ? new Datetime(data?.scheduled).time : '')}
            />
            {type !== ImportTypeEnum.Addon && (
              <DynamicCell
                selector="bufferMinutes"
                className="text-center alternate"
                width="calc(100% / 13 * 0.5)"
                sorting={makeSortable('bufferMinutes')}
              />
            )}
            <DynamicCell
              selector="airportCode"
              className="text-center"
              width="calc(100% / 13 * 0.5)"
              sorting={makeSortable('airportCode')}
            />
            <DynamicCell
              selector="servicerIataAirlineCode"
              className="text-center"
              width="calc(100% / 13 * 0.5)"
              sorting={makeSortable('servicerIataAirlineCode')}
            />
            <EditableCell
              selector="loopName|flightNumber"
              placeholder="0"
              className="text-center"
              width="calc(100% / 13 * 0.5)"
              sorting={makeSortable('loopName|flightNumber')}
              onEdit={(val: any): boolean => onEdit(data)(val.match(/\D/g) || val?.length > 4 ? 'loopName' : 'flightNumber')(val)}
            />
            <EditableCell
              selector="pilots"
              placeholder="0"
              className="text-center alternate"
              width="calc(100% / 13 * 0.3)"
              sorting={makeSortable('pilots')}
              onEdit={onEdit(data)('pilots')}
              options={{
                input: {
                  type: 'number',
                },
              }}
            />
            <EditableCell
              selector="attendants"
              placeholder="0"
              className="text-center alternate"
              width="calc(100% / 13 * 0.3)"
              sorting={makeSortable('attendants')}
              onEdit={onEdit(data)('attendants')}
              options={{
                input: {
                  type: 'number',
                },
              }}
            />
            <DynamicCell
              selector="puLocation.name"
              className="text-center"
              width="calc(100% / 13 * 1.5)"
              sorting={makeSortable('puLocation.name')}
            />
            <DynamicCell
              selector="doLocation.name"
              className="text-center"
              width="calc(100% / 13 * 1.5)"
              sorting={makeSortable('doLocation.name')}
            />
            <DynamicCell
              selector="payerProvider.displayName"
              className="text-center alternate"
              width="calc(100% / 13 * 0.5)"
              sorting={makeSortable('payerProvider.displayName')}
            />
            <DynamicCell
              selector="statusName"
              className="text-center"
              width="calc(100% / 14 * 0.75)"
              sorting={makeSortable('statusName')}
              options={{ showTooltip: false }}
              render={({ data: { statusName, warnings = [] } }: { data: StagedTrip }): ReactNode => (
                <>
                  <span>{statusName}</span>
                  {warnings?.length > 0 && (
                    <span className="text-danger mx-2">
                      <Tippy content={warnings.map(({ message = '' }: Error): string => message).join('\n')}>
                        <i className="fa fa-warning" />
                      </Tippy>
                    </span>
                  )}
                </>
              )}
            />
          </VirtualTableRow>
        )}
      />
    ),
    [filteredRows, header, height, makeSortable, onEdit, onSelect, previewPosition, selected, type]
  );
  const Preview = useMemo(
    (): JSX.Element => (
      <div className={previewPosition === Position.Bottom ? 'vh-100' : 'h-100'}>
        {url && (
          <div className="manifest-preview-file" style={{ height: '100%' }}>
            <PreviewFile
              className="border-3 border-primary h-100"
              src={url}
              globalSettingsPath="manifestPreview"
              defaultPosition={previewPosition}
            />
          </div>
        )}
        {!url && (
          <div className="PreviewFile-mini border-3 border-primary w-100 h-100 rounded border d-flex flex-column justify-content-center bg-white">
            <p className="text-center lead text-muted m-0">Preview not found.</p>
          </div>
        )}
      </div>
    ),
    [previewPosition, url]
  );
  const Details = useMemo(
    (): JSX.Element => (
      <div className="manifest-header-details fs-6 ps-3">
        <div className="d-flex align-items-center gap-2 text-muted">
          <span>Id:</span>
          <span>{id}</span>
        </div>
        <div className="d-flex align-items-center gap-2">
          <span className="text-gray">Import Name:</span>
          {type !== ImportTypeEnum.Addon && (
            <span className="w-50">
              {!provider?.displayName && <i className="fa fa-warning me-2 text-danger" />}
              <SelectImportProvider
                className="flex-grow-1"
                name="providerId"
                onChange={onChangeProviderId}
                value={currentProviderId || providerId}
                placeholder="Import Name Not Found"
                disabled={!(stagedTrips || [])?.length || updating}
                searchable
              />
            </span>
          )}
          {type === ImportTypeEnum.Addon && <span>{provider?.displayName}</span>}
        </div>
        <div className="d-flex gap-2">
          <span className="text-gray {white-space:nowrap}">File Name:</span>
          <span>{filename?.length > 30 ? `${filename?.slice(0, 30)}...${filename?.substring(filename?.length - 4)}` : filename}</span>
        </div>
        {type === ImportTypeEnum.Addon && (
          <>
            <div className="d-flex align-items-center gap-2">
              <span className="text-gray">Client:</span>
              <span className="w-50">
                <SelectClient
                  name="payerProviderId"
                  onChange={bulkUpdate}
                  value={currentPayerProviderId || (stagedTrips || [])?.[0]?.payerProviderId}
                  placeholder="No Client Selected"
                  disabled={!(stagedTrips || [])?.length || updating}
                  searchable
                />
              </span>
            </div>
            <div className="d-flex align-items-center gap-2">
              <span className="text-gray">Add to Future Manifests:</span>
              {!!updating && <i className="fa fa-spinner fa-pulse" />}
              {!updating && (
                <Form.Switch
                  className="mb-2"
                  checked={(stagedTrips || [])?.[0]?.fromManifest == 1}
                  name="fromManifest"
                  onChange={bulkUpdate}
                  disabled={!(stagedTrips || [])?.length || updating}
                />
              )}
            </div>
          </>
        )}
      </div>
    ),
    [
      id,
      bulkUpdate,
      currentPayerProviderId,
      currentProviderId,
      filename,
      onChangeProviderId,
      provider?.displayName,
      providerId,
      stagedTrips,
      type,
      updating,
    ]
  );

  return (
    <>
      <PageInfo>{sortedRows.length} Trips</PageInfo>
      <Filters
        onSubmit={(): void => {}}
        onReset={(): void => setState((current: any): any => ({ ...current, search: '' }))}
        primary={({ values: { search = '' } = {}, onChange }): JSX.Element => (
          <>
            <FormField
              name="search"
              value={search}
              onChange={onChange}
              onBlur={(): void => setState((current: any): any => ({ ...current, search }))}
              onKeyDown={onEnter((): void => setState((current: any): any => ({ ...current, search })))}
              placeholder="Search"
              inline
              condensed
            />
          </>
        )}
        alternate={(): JSX.Element => (
          <>
            <FormButton
              icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
              variant="outline-gray"
              onClick={(): void =>
                showEditPotentialTripModal({
                  importId: id,
                  url,
                  filename,
                  trip: {
                    type: type === ImportTypeEnum.Addon ? 'ADD' : undefined,
                    payerProviderId: provider?.id,
                    providerId: provider?.id,
                  },
                })
              }
            >
              Add Trip
            </FormButton>
            <ConfirmationButton
              onConfirm={archiveImport}
              disabled={loading || isIngested}
              options={{
                confirmation: {
                  Body: {
                    message: 'Archive this import?',
                  },
                },
              }}
              icon={<i className="sv sv-box {font-size:1.5rem;}" />}
              feedback="Archive Import"
            />
          </>
        )}
      />
      <Container className="page-container d-flex flex-column" style={{ maxHeight: height }} fluid>
        <LoadingBlur loading={loading} label={`${ingesting ? 'Ingesting' : 'Generating'} Preview`} />
        {!loading && (
          <>
            {previewPosition === Position.Left && (
              <Row>
                <Col xs={7} style={{ paddingRight: 0 }}>
                  <Row style={{ height: '100%' }}>
                    <Col>{Preview}</Col>
                  </Row>
                </Col>
                <Col xs={5} style={{ paddingLeft: 0 }}>
                  <Row className="pt-3">
                    <Col className="flex-grow-1">{Details}</Col>
                    <Col className="flex-grow-0 d-flex gap-2 pe-4 submit-buttons">{ConfirmationButtons}</Col>
                  </Row>
                  <Row className="p-3">
                    <Col>{CategoryTabs}</Col>
                  </Row>
                  <Row>
                    <Col>{Table}</Col>
                  </Row>
                </Col>
              </Row>
            )}
            {previewPosition !== Position.Left && (
              <>
                <Row className="p-3">
                  <Col className="d-flex flex-column justify-content-between" xs={5}>
                    {previewPosition === Position.Top && (
                      <Row>
                        <Col>{Details}</Col>
                      </Row>
                    )}
                    <Row>
                      <Col>{CategoryTabs}</Col>
                    </Row>
                  </Col>
                  <Col className="d-flex flex-column gap-2 justify-content-end submit-buttons" xs={1}>
                    {ConfirmationButtons}
                  </Col>
                  <Col xs={6}>{previewPosition === Position.Top ? Preview : Details}</Col>
                </Row>
                <Row>
                  <Col>{Table}</Col>
                </Row>
                {previewPosition === Position.Bottom && (
                  <Row>
                    <Col>{Preview}</Col>
                  </Row>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default ManifestPreview;
