import './styles.scss';

import { ConnectionDetails, QueryInputType, handleError, queryInput } from '@/utils/custom';
import { Import, ImportStatusEnum, ImportTypeEnum, ImportWithAirportSearch, SortDirectionEnum } from '@/models/gen/graphql';
import { OVERRIDE_STATUS, PENDING_STATUS, PREVIEW_STATUS } from '@/constants';
import React, { useMemo, useRef, useState } from 'react';
import { RouteProps, useNavigate } from 'react-router-dom';

import { Datetime } from '@/utils/dates';
import ManifestFilters from '@/pages/Manifests/ManifestFilters';
import ManifestOverridesModal from '@/components/ManifestOverridesModal';
import ManifestUpload from '@/components/ManifestUpload';
import PageInfo from '@/components/PageInfo';
import PendingImportsTable from '@/pages/Manifests/PendingImportsTable';
import { SEARCH_IMPORTS_PAGE_SIZE } from '@/api/services/imports/searchImports';
import ScrollableContainer from '@/components/ScrollableContainer';
import runArchiveImports from '@/api/services/imports/runArchiveImports';
import runDownloadImports from '@/api/services/imports/runDownloadImports';
import { saveFile } from '@/utils/promises';
import { stringify } from '@/utils/objects';
import updateImportsStatus from '@/api/services/imports/updateImportsStatus';
import useInterval from '@/hooks/useInterval';
import usePage from '@/hooks/usePage';
import { useSearchImportsWithAirport } from '@/api/services/imports/searchImportsWithAirport';
import useSettings from '@/state/settings';
import { useVirtualTable } from '@/components/VirtualTable/utils';

const ADDONS_REFETCH_INTERVAL = 60000;

const initPendingImportsTableState = {
  selectedImport: undefined,
  selected: [],
  clearedRows: [],
  sorting: {
    column: undefined,
    direction: undefined,
  },
  importing: false,
};

const parseFilesAndUpdateStatus = async (_uploads, files) => {
  if (!files?.length) return;
  const ids = [];
  for (let u = 0; u < files.length; u++) {
    const { id } = files[u];
    if (!id) continue;
    ids.push(id);
  }
  await updateImportsStatus(ids, ImportStatusEnum.Uploaded);
};

const PendingImports = (_props: RouteProps): React.JSX.Element => {
  const [{ addonAsDrawer }, setSettings] = useSettings(
    ({
      state: {
        addons: { addonAsDrawer = false },
      },
      setState,
    }) => [{ addonAsDrawer }, setState]
  );
  // init state
  const [state, setState] = useState(initPendingImportsTableState);
  const { selectedImport, selected, sorting, importing, clearedRows } = state;
  const tableRef = useRef(null);

  // init page state
  const [page, setPage] = usePage('manifests');

  // init query
  const [{ data, loading }, { fetch: getPendingImportsTable, refetch, fetchMore }] = useSearchImportsWithAirport();
  const { rows = [], hasNextPage = false, totalCount = 0 } = data || {};

  const currentRows = useMemo(() => {
    return rows?.filter((row) => !clearedRows.includes(row.id));
  }, [rows, clearedRows]);

  const { onSelect, makeSortable, filteredRows } = useVirtualTable(setState, { selected, sorting, rows: currentRows });

  const navigate = useNavigate();

  const archiveImports = async () => {
    try {
      await runArchiveImports(Object.values(selected));
      await refetch([page?.query || {}]);
      setState((current) => ({ ...current, selected: [] }));
    } catch (err) {
      console.error(err);
    }
  };

  const onSearch = async (filters): Promise<void> => {
    const { airportCode, providerId, from, to, createdBy, status, type } = filters;
    const query: ImportWithAirportSearch = {
      airportCode: airportCode?.length ? queryInput(airportCode) : null,
      createdAt: queryInput.date([new Datetime(from).toString(), new Datetime(to).toString()], true, SortDirectionEnum.Desc, 0),
      createdBy: createdBy ? queryInput(createdBy) : null,
      providerId: providerId ? queryInput(providerId) : null,
      status: queryInput(status || PENDING_STATUS),
      type: type ? queryInput(type) : null,
      archivedAt: queryInput([], QueryInputType.ISNULL),
    };
    const fn = stringify.compare(page?.query || {}, query) ? refetch : getPendingImportsTable;
    await fn([query]);
    setPage((current) => ({
      ...(current || {}),
      query,
    }));
  };

  const onDownload = async (id: string, name?: string): Promise<void> => {
    const downloadResult = await runDownloadImports(id);
    const downloadURL = downloadResult.length === 1 ? downloadResult[0].url : undefined;
    if (downloadURL) saveFile(downloadURL, name);
  };

  const getMore = async (after: number): Promise<ConnectionDetails<Import>> => {
    if (loading || !hasNextPage) return;
    const result = await fetchMore([page?.query || {}], {
      page: Math.round(after / SEARCH_IMPORTS_PAGE_SIZE),
      merge: true,
    });
    return result;
  };

  const showOverrides = (row: Import): void => {
    if (PREVIEW_STATUS.includes(row.status)) {
      navigate(`/manifests/${row.id}`);
    } else if (OVERRIDE_STATUS.includes(row.status)) {
      setState((current) => ({ ...current, selectedImport: row }));
    }
  };

  const hideManifestOverrideModal = () => setState((current) => ({ ...current, selectedImport: undefined }));
  const handleSubmitManifestOverrideModal = (id: string) => {
    if (addonAsDrawer && selectedImport?.type === ImportTypeEnum.Addon) {
      refetch();
      setState((current) => ({ ...current, selectedImport: undefined }));
      return tableRef?.current?.onExpand?.(id);
    }
    navigate(`/manifests/${id}${window.location.search}`);
  };
  const onManifestUploadSuccess = async (uploads, files) => {
    try {
      if (!files?.length) return;
      await parseFilesAndUpdateStatus(uploads, files);
      const { id, name } = files?.[0] || {};
      setState((current: any): any => ({ ...current, importing: false }));
      if (files.length !== 1) return;
      showOverrides({ id, name, status: ImportStatusEnum.Parsed } as Import);
    } catch (err) {
      handleError(err, { notification: { title: 'Manifest Upload' } });
    }
  };

  useInterval(() => {
    if (stringify((page?.query || {})?.type).includes(ImportTypeEnum.Addon)) refetch([page?.query || {}]);
  }, ADDONS_REFETCH_INTERVAL);

  return (
    <ScrollableContainer name="Manifests" className="page-container">
      <ManifestFilters
        onSubmit={onSearch}
        onReset={async () => {}}
        onImport={() => setState((current) => ({ ...current, importing: true }))}
        onArchive={archiveImports}
        selected={selected}
        addonAsDrawer={addonAsDrawer}
        onChangeAddonAsDrawer={() =>
          setSettings((current) => ({
            ...current,
            addons: {
              ...current?.addons,
              addonAsDrawer: !current?.addons?.addonAsDrawer,
            },
          }))
        }
      />
      <PageInfo>
        {filteredRows.length} / {totalCount} Imports
      </PageInfo>
      <PendingImportsTable
        ref={tableRef}
        addonAsDrawer={addonAsDrawer}
        data={filteredRows}
        loading={loading}
        onSelect={onSelect}
        onDownload={onDownload}
        onSort={makeSortable}
        onSubmitAddon={(importId: string) => setState((current) => ({ ...current, clearedRows: [current?.clearedRows, importId] }))}
        selected={selected}
        onLazyLoad={!!hasNextPage && rows.length < totalCount ? getMore : undefined}
        onDoubleClick={(row: Import): void => {
          setState((current: any): any => ({
            ...current,
            selected: Array.from(new Set([row?.id])),
          }));
          showOverrides(row);
        }}
      />
      <ManifestUpload
        show={importing}
        onHide={(): void => setState((current: any): any => ({ ...current, importing: false }))}
        onSuccess={onManifestUploadSuccess}
      />
      <ManifestOverridesModal
        show={selectedImport}
        onHide={hideManifestOverrideModal}
        importId={selectedImport?.id}
        name={selectedImport?.name}
        onSubmit={handleSubmitManifestOverrideModal}
      />
    </ScrollableContainer>
  );
};

export default PendingImports;
