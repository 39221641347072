import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import React, { useEffect, useMemo } from 'react';
import { Validation, parseQueryString, setCookies } from '@/utils';
import useSession, { SessionState } from '@/state/session';

import ForgotPasswordModal from './ForgotPasswordModal';
import Logger from '../../utils/logs';
import { Middle } from '../Align';
import { onEnter } from '@/utils';
import useForm from '../../hooks/useForm';
import { useGetPortalConfig } from '@/api/services/config/getPortalConfig';
import { useLogin } from '@/api/services/users/login';
import { useNavigate } from 'react-router-dom';

const log = Logger.of('LoginForm');

type State = {
  username: string;
  password: string;
  remember: boolean;
  forgotPassword: boolean;
  feedbackMsg?: string;
};

const initLoginFormForm: State = {
  username: '',
  password: '',
  remember: false,
  forgotPassword: false,
  feedbackMsg: undefined,
};

interface Props {
  onLogin: (success: boolean) => void;
}

const LoginForm: React.FC<Props> = ({ onLogin }: Props): JSX.Element => {
  const setSession = useSession(({ setState }) => setState);
  const [{ loading: loadingPortalConfig }, { fetch: getPortalConfig }] = useGetPortalConfig();
  const [{ loading: loadingLogin, error: loginError }, { fetch: login }] = useLogin();
  const navigate = useNavigate();

  const [form, onChange, setForm] = useForm(initLoginFormForm);
  const { username, password, forgotPassword, remember, feedbackMsg } = form;

  const loading = !!loadingLogin || !!loadingPortalConfig;

  const message = loginError?.message || loginError || feedbackMsg;
  const invalidLogin = useMemo((): boolean => !username || !(password && password.length >= 4), [username, password]);

  const handleLogin = async (): Promise<void> => {
    try {
      const res = await login(username, password);
      const { token, user } = res || {};
      if (!Validation.isValidUUID(token)) throw new Error('Failed to Login token undefined');
      setCookies({ token });
      const portalConfigResponse = await getPortalConfig();
      setSession((current: SessionState): SessionState => ({ ...current, user, config: { ...portalConfigResponse } }));
      const currentWindowConfig = window['__config__'];
      window['__config__'] = Object.freeze({ ...(currentWindowConfig || {}), ...(portalConfigResponse || {}) });
      onLogin(true);
    } catch (err) {
      log.error('handleLogin', err.message);
    }
  };

  useEffect((): void => {
    if (parseQueryString(window.location.search)?.msg) {
      // Store the message from query string in stage, and remove it from the URL.
      setForm((current: State): State => ({ ...current, feedbackMsg: parseQueryString(window.location.search)?.msg }));
      navigate('/login', { replace: true });
    }
  }, []);

  return (
    <>
      {message && (
        <Alert className="text-center" variant={feedbackMsg ? 'warning' : 'danger'}>
          {message}
        </Alert>
      )}
      <div className="LoginForm">
        <Form.Group>
          <Form.Control
            className="shadow mt-4 text-center"
            type="text"
            value={username}
            name="username"
            onChange={onChange}
            onKeyDown={onEnter(handleLogin)}
            disabled={loading}
            placeholder="Username"
          />
          <Form.Control
            className="shadow mt-4 text-center"
            type="password"
            value={password}
            name="password"
            onChange={onChange}
            onKeyDown={onEnter(handleLogin)}
            disabled={loading}
            placeholder="Password"
          />
        </Form.Group>
        <Form.Group className="d-flex justify-content-between align-items-center mt-4">
          <InputGroup className="d-flex">
            <Form.Check id="remember-me" name="remember" onChange={onChange.toggle} disabled={loading} checked={remember} />
            <Form.Label className="{margin:0rem|0.5rem!;}" htmlFor="remember-me">
              <small>Remember Me</small>
            </Form.Label>
          </InputGroup>
          <Button
            className="{white-space:nowrap;outline:none;box-shadow:none;border:0!;padding:0!;}"
            variant=""
            onClick={(): any => onChange.toggle({ target: { name: 'forgotPassword' } })}
            disabled={loading}
          >
            <small>Forgot Password</small>
          </Button>
        </Form.Group>
        <Form.Group className="d-flex justify-content-center mt-4">
          <Button
            className="shadow w-50 {max-width:180px;height:34px;}"
            variant="secondary"
            onClick={handleLogin}
            disabled={invalidLogin || loading}
          >
            {loading ? (
              <Middle.Center>
                <i className="fa fa-spinner fa-spin fa-pulse fs-4" />
              </Middle.Center>
            ) : (
              'Login'
            )}
          </Button>
        </Form.Group>
        <ForgotPasswordModal show={forgotPassword} onHide={(): any => onChange.toggle({ target: { name: 'forgotPassword' } })} />
      </div>
    </>
  );
};

export default LoginForm;
