import './styles.scss';

import { Datetime, formatDollars, saveFile, uuid } from '@/utils';
import InvoicePreviewFilters, { InvoicePreviewFiltersState, initInvoicePreviewFiltersState } from './InvoicePreviewFilters';
import { InvoiceTrip, RunPreviewInvoiceResponse } from '@/models/gen/graphql';
import {
  ParsedInvoiceInput,
  convertParsedInvoiceInputToInvoiceInput,
  parseGetInvoicePreviewResponse,
  useGetInvoicePreview,
} from '@/api/services/invoices';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RouteProps, useNavigate, useParams } from 'react-router-dom';

import { Container } from 'react-bootstrap';
import InvoicePreviewHeader from './InvoicePreviewHeader';
import InvoicePreviewSummary from './InvoicePreviewSummary';
import InvoicePreviewTable from './InvoicePreviewTable';
import InvoicePreviewTotals from './InvoicePreviewTotals';
import { LoadingBlur } from '@/components/LoadingSpinner';
import PageInfo from '@/components/PageInfo';
import { stringify } from '@/utils/objects';
import { useAbortable } from '@/hooks/useAbortable';
import useForm from '@/hooks/useForm';
import { useRunDownloadInvoice } from '@/api/services/invoices/runDownloadInvoice';
import { useRunSaveAndSendInvoice } from '@/api/services/invoices/runSaveAndSendInvoice';
import { useRunSaveAsInvoice } from '@/api/services/invoices/runSaveAsInvoice';
import { useRunSaveInvoice } from '@/api/services/invoices/runSaveInvoice';

const initPreview: ParsedInvoiceInput = {
  ...initInvoicePreviewFiltersState,
  // remaining preview information
  due: new Datetime().endOf('day').toString(),
  emails: [],
  grandTotal: 0,
  headers: [],
  id: 0,
  invoiced: initInvoicePreviewFiltersState.endDatetime,
  items: [],
  name: '',
  total: 0,
  trips: [],
};
const InvoicePreview = (_props: RouteProps): JSX.Element => {
  const { id: invoiceIdString } = useParams();
  const invoiceId = parseInt(invoiceIdString) || 0;
  const navigate = useNavigate();

  const [preview, onChange, setPreview] = useForm(initPreview);
  const { format, grandTotal, total, trips, items } = preview;

  const [{ loading: saving }, { fetch: runSaveInvoice }] = useRunSaveInvoice();
  const [{ loading: savingAs }, { fetch: runSaveAsInvoice }] = useRunSaveAsInvoice();
  const [{ loading: downloading }, { fetch: runDownloadInvoice }] = useRunDownloadInvoice();
  const [{ loading: sending }, { fetch: runSaveAndSendInvoice }] = useRunSaveAndSendInvoice();
  const [{ data, loading: previewLoading }, { fetch: getInvoicePreview }] = useGetInvoicePreview();
  const loading = saving || savingAs || downloading || sending;
  const [submitLoading, setSubmitLoading] = useState<boolean>(saving || savingAs || downloading || sending);
  const { invoiceTerms = '', output, subtotals = [], summary } = data || {};

  //TODO: move logic to service
  const parseAndSetPreview = useCallback((response: RunPreviewInvoiceResponse): void => {
    const parsedResponse = parseGetInvoicePreviewResponse(response);
    const { output } = parsedResponse;
    const newPreview: ParsedInvoiceInput = Object.keys(initPreview).reduce((acc, key) => {
      let result = output?.[key] || output?.invoice?.[key] || initPreview[key];
      if (Array.isArray(result)) {
        result = Array.from(new Set(result));
      }
      acc[key] = result;
      return acc;
    }, {} as ParsedInvoiceInput);
    setPreview(newPreview);
  }, []);

  const lastClient = useRef<string>('');
  const onSubmit = useCallback(
    async (filters: InvoicePreviewFiltersState): Promise<void> => {
      try {
        const query: Partial<ParsedInvoiceInput> = {
          payerProviderId: filters.payerProviderId,
          iataAirlineCodes: filters.iataAirlineCodes,
          airports: filters.airports,
          types: filters.types,
          startDatetime: filters.startDatetime,
          endDatetime: filters.endDatetime,
          format: filters.format,
          tripStatus: filters.tripStatus,
        };
        setSubmitLoading(true);
        const payload = convertParsedInvoiceInputToInvoiceInput({ ...preview, ...query });
        if (lastClient.current !== query.payerProviderId) payload.emails = [];
        lastClient.current = payload.payerProviderId;
        const response = await getInvoicePreview(payload);
        if (!response) return;
        parseAndSetPreview(response);
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitLoading(false);
      }
    },
    [getInvoicePreview, parseAndSetPreview, preview]
  );

  const saveAndLoadInvoice = async (method: 'save' | 'saveAs' | 'send') => {
    const fn = method === 'save' ? runSaveInvoice : method === 'saveAs' ? runSaveAsInvoice : runSaveAndSendInvoice;
    const res = await fn(convertParsedInvoiceInputToInvoiceInput(preview));
    if (res?.invoice?.id) {
      if (method !== 'send') return navigate(`/invoices/${res?.invoice?.id}`);
      const response = await getInvoicePreview(res?.invoice?.id);
      if (!response) return;
      parseAndSetPreview(response);
    }
  };
  const onSave = async (): Promise<void> => saveAndLoadInvoice('save');
  const onSend = async (): Promise<void> => saveAndLoadInvoice('send');
  const onSaveAs = async (): Promise<void> => saveAndLoadInvoice('saveAs');
  const onDownload = async (): Promise<void> => {
    await saveAndLoadInvoice(preview?.id === 0 ? 'saveAs' : 'save');
    const { url } = await runDownloadInvoice({
      ...convertParsedInvoiceInputToInvoiceInput(preview),
      id: preview?.id || invoiceId || parseInt(window.location.pathname.split('/').pop()) || null,
    });
    if (url) saveFile(url, preview?.name);
  };

  const loadExistingInvoice = async (invoiceId: number): Promise<void> => {
    const response = await getInvoicePreview(invoiceId);
    lastClient.current = response.output.payerProvider.id;
    parseAndSetPreview(response);
  };

  const getPreview = useCallback(async () => {
    try {
      const response = await getInvoicePreview(convertParsedInvoiceInputToInvoiceInput(preview));
      lastClient.current = response.output.payerProvider.id;
      parseAndSetPreview(response);
    } catch (err) {
      console.error(err);
    }
  }, [preview]);

  const abortableGetPreview = useAbortable(getPreview);
  const [filteredRows, setFilteredRows] = useState([]);

  const handleChangeRate = useCallback(
    (id: string) => async (rate: number) => {
      setFilteredRows((current) => current.map((row) => (row.tripId === id ? { ...row, rate } : row)));
      abortableGetPreview();
    },
    [abortableGetPreview]
  );

  // This table has custom filtering logic, so we cannot use useVirtualTable to get this.
  // Instead we run our filter logic here.
  useEffect(() => {
    let lastRate;
    const result = [];
    trips.forEach((trip: InvoiceTrip, index: number) => {
      if (lastRate !== undefined && lastRate !== trip?.rate) {
        const subtotal = subtotals.find((sub): boolean => sub?.amount === lastRate);
        result.push({ id: uuid(), subtotal: <strong>SUBTOTAL</strong>, rate: <strong>{formatDollars(subtotal?.total || 0)}</strong> });
      }
      result.push({
        ...trip,
        pilots: <span>{trip?.pilots || 0}</span>,
        attendants: <span>{trip?.attendants || 0 || 0}</span>,
        rate: formatDollars(trip.rate),
      });
      if (trips.length === index + 1) {
        const subtotal = subtotals.find((sub): boolean => sub?.amount === trip?.rate);
        result.push({ id: uuid(), subtotal: <strong>SUBTOTAL</strong>, rate: <strong>{formatDollars(subtotal?.total || 0)}</strong> });
      }
      lastRate = trip?.rate;
    });
    setFilteredRows(result);
  }, [preview]);

  const filters: InvoicePreviewFiltersState = useMemo(() => {
    if (stringify.compare(preview, initPreview)) return;
    return Object.keys(initInvoicePreviewFiltersState).reduce((acc, key) => {
      acc[key] = preview?.[key] || initInvoicePreviewFiltersState[key];
      return acc;
    }, {} as InvoicePreviewFiltersState);
  }, [preview]);

  useEffect(() => {
    if (invoiceId === 0) return;
    loadExistingInvoice(invoiceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  return (
    <Container className="InvoicePreview page-container pt-4" fluid>
      <PageInfo>Total Trips: {trips.length}</PageInfo>
      <InvoicePreviewFilters initValue={filters} onSubmit={onSubmit} loading={submitLoading} invoiceId={invoiceId} />
      <LoadingBlur
        loading={(invoiceId !== 0 && filteredRows.length === 0) || loading || submitLoading}
        label={saving || savingAs || sending ? 'Saving...' : downloading ? 'Downloading...' : 'Loading Invoice...'}
      />
      {filteredRows?.length > 0 && (
        <InvoicePreviewHeader
          data={{
            ...preview,
            invoiceTerms,
            company: output?.company,
            payerProvider: output?.payerProvider,
            documents: output?.invoice?.documents,
          }}
          loading={loading}
          onChange={onChange}
          onDownload={onDownload}
          onSave={!invoiceId ? onSaveAs : onSave}
          onSend={onSend}
          onSaveAs={invoiceId ? onSaveAs : undefined}
        />
      )}
      {((invoiceId === 0 && !previewLoading) || filteredRows?.length > 0) && (
        <InvoicePreviewTable data={filteredRows} format={format} onChangeRate={handleChangeRate} />
      )}
      {filteredRows?.length > 0 && (
        <>
          <InvoicePreviewTotals data={{ total, grandTotal }} onChange={onChange} items={items} loading={previewLoading} />
          {invoiceId === 0 && <InvoicePreviewSummary summary={summary} />}
        </>
      )}
    </Container>
  );
};

export default InvoicePreview;
