import './styles.scss';

import { TimePicker as AntTimePicker, TimePickerProps as AntTimePickerProps } from 'antd';
import Field, { FieldProps } from '@/components/Field';
import React, { ReactNode, useRef } from 'react';
import { TIME_FORMAT, TODAY } from '@/constants';
import dayjs, { Dayjs } from 'dayjs';
import { getClasses, onEnter } from '@/utils';

export type TimeString = `${number}${number}:${number}${number}`;
export type FullTimeString = `${number}${number}:${number}${number}:${number}${number}`;
export type TimePickerProps = Omit<AntTimePickerProps, 'value' | 'onChange'> & {
  value?: FullTimeString;
  onChange?: (value: FullTimeString) => void;
  onBlur?: (value: FullTimeString) => void;
  isDirty?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
};
const TimePicker = (props: TimePickerProps): ReactNode => {
  const inputRef = useRef(null);
  const handleOnChange = (value: TimeString): void => {
    if (!value) return props?.onChange?.(null);
    let seconds: `${number}${number}` = '00';
    if (value?.split(':')[1] === '59') seconds = '59';
    props?.onChange?.(`${value}:${seconds}`);
  };
  const handleBlur = (event): void => {
    let { value = '' } = event?.target || {};
    if (event?.target?.className?.includes?.('ant')) return;
    if (!!value?.length && !value?.includes?.(':'))
      value = value
        .padStart(4, '0')
        .replace(/(\d{2})(\d{2})/, '$1:$2')
        .substring(0, 5);
    handleOnChange(value);
    if (props?.onBlur) return props?.onBlur(value);
    inputRef.current.blur();
  };

  return (
    <AntTimePicker
      placeholder="Select Time"
      suffixIcon={<i className="fa fa-clock" />}
      {...props}
      className={getClasses(
        'TimePicker',
        props?.className,
        props?.isValid ? 'is-valid' : props?.isInvalid ? 'is-invalid' : undefined,
        props?.isDirty ? 'is-dirty' : undefined
      )}
      value={props?.value !== null ? dayjs(props?.value || TODAY, TIME_FORMAT) : null}
      onChange={(_d: Dayjs, timeString: TimeString): void => handleOnChange(timeString)}
      onBlur={handleBlur}
      onKeyDown={onEnter(handleBlur)}
      format={TIME_FORMAT}
      variant="borderless"
      needConfirm={false}
      ref={inputRef}
    />
  );
};

export type TimePickerFieldProps = TimePickerProps &
  Omit<FieldProps, 'children'> & {
    options?: {
      field: Omit<FieldProps, 'children'>;
    };
  };

export const TimePickerField = ({ label, feedback, valid, required, options, ...inputProps }: TimePickerFieldProps): ReactNode => {
  return (
    <Field label={label} feedback={feedback} valid={valid} required={required} {...options?.field}>
      <TimePicker {...inputProps} isValid={valid === true} isInvalid={valid === false} />
    </Field>
  );
};

export default React.memo(TimePicker);
