import SelectFromQuery, { SelectFromQueryProps } from '../SelectFromQuery';

import { GET_AIRPORT_GROUPS } from '../../queries/Filter';
import React from 'react';

const SelectAirportGroup = ({ ...props }: Partial<SelectFromQueryProps>) => (
  <SelectFromQuery
    cache="AirportGroups"
    placeholder="All Airports"
    query={GET_AIRPORT_GROUPS}
    selector={(data) =>
      (data?.getFilters?.filters?.airportGroups || []).map((opt) => ({
        label: opt.displayName,
        value: opt.id,
        values: opt.values,
      }))
    }
    sticky
    {...props}
    options={{
      showSelectAll: false,
      ...(props.options || {}),
    }}
  />
);

export default SelectAirportGroup;
