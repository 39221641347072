import './styles.scss';

import { Button, Modal } from 'react-bootstrap';

import React from 'react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const onBack = () => navigate(-1);

  const privacyEmail = 'privacy@skyhoptechnologies.com';

  return (
    <Modal show={true} dialogClassName="PrivacyPolicy" size="xl">
      <Button className="w-100 shadow" variant="success" onClick={onBack}>
        Back
      </Button>
      <Modal.Header>
        <h4 className="m-0 border-bottom w-100 border-3 pt-2 pb-3 text-center">Privacy Policy</h4>
      </Modal.Header>
      <Modal.Body className="{text-align:justify;}_p">
        <h2 className="text-center mt-4">SKYHOP TECHNOLOGIES, INC. PRIVACY POLICY</h2>
        <p>
          Our Privacy Policy governs your use of any and all of the software applications developed, owned, and operated by Skyhop
          Technologies, Inc. (individually, the “Application” and collectively, the “Applications”).
        </p>
        <h6>WHAT INFORMATION DOES THE APPLICATION OBTAIN AND HOW IS IT USED?</h6>
        <p>User provided information</p>
        <p>
          The Application obtains the information you provide when you download and register the Application. Registration with us is
          optional. However, please keep in mind you may not be able to use some of the features offered by the Application unless you
          register with us. When you download the Application, register it and use it, you generally provide (a) your name, email address,
          age, user name, password and other registration information; (b) transaction-related information, such as when you make purchases,
          respond to any offers, or download or use applications from us; (c) information you provide us when you contact us for support;
          (d) credit card information for purchase and use of the Application; and (e) information you enter into our system when using the
          Application, such as contact information and project management information. This list is not intended to be, nor is it,
          exhaustive. We may use this information occasionally to provide you with important information, required notices and marketing
          promotions.
        </p>
        <p>Automatically Collected information</p>
        <p>
          In addition, the Application may collect certain information automatically, including, but not limited to, the type of mobile
          device you use, your device's unique device ID, your IP address, your mobile operating system and version, the type of mobile
          internet browsers you use, and information about the way you use the Application.
        </p>
        <h6>DOES THE APPLICATION COLLECT PRECISE, REAL TIME LOCATION INFORMATION FROM THE DEVICE?</h6>
        <p>
          When you use the Application, we may use GPS technology (or other similar technology) to determine your current location in order
          to determine within which city you are located and display a location map with relevant advertisements. We share your current
          location with other users and/or partners.
        </p>
        <p>
          If you do not want us to use your location for the purposes set forth above, you should turn off the location services for the
          Application, which can be done in your account settings, in your device's settings and/or within the Application.
        </p>
        <h6>DO THIRD PARTIES SEE AND/OR HAVE ACCESS TO INFORMATION OBTAINED BY THE APPLICATION?</h6>
        <p>
          Yes, but your information will only be shared with third parties in the ways described in this Privacy Policy. Specifically, we
          share information with our subsidiaries and affiliates to help us provide our services and/or conduct data processing on our
          behalf. We also may disclose your information as required or compelled by law, such as to comply with a subpoena, or similar legal
          process; when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of
          others, investigate fraud, or respond to a government request(s); with our trusted services providers who work on our behalf, do
          not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this
          Privacy Policy. If we are involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via
          email and/or a prominent notice on our website of any change in ownership or uses of this information, as well as any choices you
          may have regarding the use of your information.
        </p>
        <p>
          We also may share your information in a way other than as described in this policy if we notify you and you consent to the
          sharing.
        </p>
        <h6>WHAT ARE MY OPT-OUT RIGHTS?</h6>
        <p>
          You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard
          uninstall processes as may be available as part of your device or via the Application marketplace or network. You also can request
          to opt-out via email, at <a href={`mailto:${privacyEmail}`}>{privacyEmail}</a>.
        </p>
        <h6>DATA RETENTION POLICY, MANAGING YOUR INFORMATION</h6>
        <p>
          We will retain User Provided Information, as defined above, for as long as you use the Application and for a reasonable time
          thereafter. We will retain Automatically Collected Information, as defined above, for up to twenty-four (24) months and thereafter
          may store it in aggregate. If you would like us to delete User Provided Information you have provided via the Application, please
          contact us and we will respond within a reasonable time.
        </p>
        <p>
          Please note that some or all of the User Provided Information may be required in order for the Application to function properly.
        </p>
        <p>
          You have the right to obtain a copy of the personal data, free of charge, in an electronic format. To obtain this information
          please contact us at <a href={`mailto:${privacyEmail}`}>{privacyEmail}</a>.
        </p>
        <h6>CHILDREN</h6>
        <p>
          We do not use the Application to knowingly solicit data from or market to children under the age of 13. If a parent or guardian
          becomes aware his or her child has provided us with information without their consent, he or she should contact us at{' '}
          <a href={`mailto:${privacyEmail}`}>{privacyEmail}</a>. We will delete such information from our files within a reasonable time.
        </p>
        <h6>SECURITY</h6>
        <p>
          We are concerned about safeguarding the confidentiality of your information. We provide physical, electronic, and procedural
          safeguards to protect information we process and maintain. For example, we limit access to this information to authorized
          employees and contractors who need to know that information in order to operate, develop or improve our Application. We are
          committed to safeguarding the confidentiality of all user information, including images uploaded through the chat feature.
          Uploaded images are protected using the following measures: Images are encrypted during transmission and storage, Access to stored
          images is restricted to authorized personnel and intended chat recipients. This example is not intended to be an exhaustive list
          of our security efforts. Please be aware that, although we endeavor to provide reasonable security for information we process and
          maintain, no security system can prevent all potential security breaches.
        </p>
        <h6>CHANGES</h6>
        <p>
          This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by
          posting the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is
          deemed approval and acceptance of all changes. You can check the history of this policy by requesting it at{' '}
          <a href={`mailto:${privacyEmail}`}>{privacyEmail}</a>.
        </p>
        <h6>LEGAL BASIS</h6>
        <p>The legal basis for collecting and processing data is legitimate interest.</p>
        <h6>YOUR CONSENT</h6>
        <p>
          By using the Application, you are consenting to our processing of your information as set forth in this Privacy Policy now and as
          amended by us. "Processing” means using cookies on a computer/handheld device or using or touching information in any way,
          including, but not limited to, collecting, storing, deleting, using, combining and disclosing information, all of which activities
          will take place in the Application.
        </p>
        <h6>CONTACT US</h6>
        <p>
          If you have any questions regarding privacy while using the Application, or have questions about our practices, please contact us
          via email at <a href={`mailto:${privacyEmail}`}>{privacyEmail}</a>.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default PrivacyPolicy;
