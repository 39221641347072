import { useCallback, useEffect } from 'react';

import { createComponentState } from '@/state';
import useEventListener from '@/hooks/useEventListener';
import { useLocation } from 'react-router-dom';

type HotkeysState = Record<string, unknown>;
const initHotkeysState: HotkeysState = {};
export const useHotkeysState = createComponentState(initHotkeysState);

const useHotkeys = (source?: string, hotkeys?: Record<string, any>, target: Document | HTMLElement = document): HotkeysState => {
  const hotkeysState = useHotkeysState(({ state }) => state);
  const setHotkeysState = useHotkeysState(({ setState }) => setState);
  const location = useLocation();

  const keyDownHandler: EventListener = useCallback(
    (event: KeyboardEvent): void => {
      const isCtrl = event?.ctrlKey || event?.metaKey;
      const isShift = event?.shiftKey;
      const isAlt = event?.altKey;

      const checkKey = (keyCombination: string): boolean => {
        const keys = keyCombination.split('+').map((key) => key.trim().toLowerCase());
        const result = keys.every((key: string): boolean => {
          return (
            (key === 'ctrl' && isCtrl) || (key === 'shift' && isShift) || (key === 'alt' && isAlt) || event?.key?.toLowerCase() === key
          );
        });
        return result;
      };

      for (const keyCombination in hotkeys) {
        if (checkKey(keyCombination)) {
          event?.preventDefault();
          hotkeys[keyCombination].action(event);
          break;
        }
      }
    },
    [hotkeys]
  );

  useEffect(() => {
    setHotkeysState(initHotkeysState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const keys = Object.entries(hotkeys).reduce(
      (acc, [key, { name, description }]) => ({ ...acc, [key]: { name, description, source } }),
      {}
    );
    setHotkeysState((current) => ({ ...current, ...keys }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEventListener('keydown', keyDownHandler, target);

  return hotkeysState;
};

export default useHotkeys;
