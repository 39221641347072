import MenuDrawer, { MenuItem } from '@/components/MenuDrawer';
import React, { ReactNode, useMemo, useState } from 'react';
import { Route, Toast } from '@/models';
import { clearCache, createNotification, generateQueryString, logout, parseQueryString } from '@/utils';
import useSession, { Config } from '@/state/session';

import { Button } from 'react-bootstrap';
import HotkeysModal from '@/components/HotkeysModal';
import LoadingSpinner from '@/components/LoadingSpinner';
import NavigationMenuHeader from './NavigationMenuHeader';
import { ROUTES } from '@/constants';
import { apolloClient } from '@/components/ApolloRoot';
import useModal from '@/hooks/useModal';
import { useNavigate } from 'react-router-dom';

type NavigationMenuState = {
  open: {
    menu: boolean;
    password: boolean;
    hotKeys: boolean;
    settings: boolean;
  };
  loading: {
    logout: boolean;
  };
};
const initNavitationMenuState = {
  open: {
    menu: false,
    password: false,
    hotKeys: false,
    settings: false,
  },
  loading: {
    logout: false,
  },
};

const NavigationMenu = (): ReactNode => {
  const permissions = useSession(({ state }) => state.config?.auth || ({} as Config['auth']));
  const [state, setState] = useState<NavigationMenuState>(initNavitationMenuState);
  const { open, loading } = state;
  const [, { show: showChangePasswordModal }] = useModal('ChangePasswordModal');
  const navigate = useNavigate();
  const menuList = useSession(({ state }) => state?.config?.userConfig?.menus?.navigation || ROUTES || []);

  const onToggle =
    (key: keyof NavigationMenuState['open']): (() => void) =>
    (): void =>
      setState(
        (current: NavigationMenuState): NavigationMenuState => ({ ...current, open: { ...current.open, [key]: !current.open[key] } })
      );

  const handleClick = async (key): Promise<void> => {
    try {
      switch (key) {
        case 'password':
          onToggle('password')();
          break;
        case 'hot-keys':
          onToggle('hotKeys')();
          break;
        case 'clear-cache':
          clearCache();
          break;
        case 'logout':
          setState((current: NavigationMenuState): NavigationMenuState => ({ ...current, loading: { ...current.loading, logout: true } }));
          await apolloClient.clearStore();
          await logout();
          break;
        case 'privacy-policy':
          navigate('/privacy-policy');
          break;
        case 'change-password':
          showChangePasswordModal();
          break;
        default: {
          if (key.startsWith('/')) {
            const path = key.split('_')[0];
            const lastPath = `${window.location.pathname}`;
            navigate(path);
            if (
              lastPath.split('/')[1] === path.split('/')[1] ||
              lastPath === path.split('?')?.[0] ||
              path === '/invoices/0' ||
              path.includes('?')
            )
              window.location.reload();
          }
          break;
        }
      }
    } catch (err) {
      console.error(err);
      createNotification('Something went wrong.', Toast.Type.WARNING, 'Warning', 'If this problem persists, please contact support.');
    } finally {
      setState((current: NavigationMenuState): NavigationMenuState => ({ ...current, loading: { ...current.loading, logout: false } }));
    }
  };

  const settings = useMemo<MenuItem>((): MenuItem => {
    const menu = [];
    if (permissions.allowViewBufferTime) menu.push({ key: '/settings/buffer-times', label: 'Buffer Times' });
    if (permissions.allowViewVariableTime) menu.push({ key: '/settings/variable-times', label: 'Variable Times' });
    if (permissions.allowViewCombineRule) menu.push({ key: '/settings/combine-rules', label: 'Combine Rules' });
    if (!menu.length) return {} as MenuItem;
    return {
      key: 'settings',
      icon: <i className="sv sv-cog" />,
      label: 'Settings',
      menu,
    };
  }, [permissions]);

  const items = useMemo<MenuItem[]>((): MenuItem[] => {
    return !Object.keys(permissions).length
      ? [{ key: 'permissions-loading', icon: <LoadingSpinner /> }]
      : ([
          ...menuList.map((route: Route): MenuItem => {
            const currentPath = window.location.pathname;
            const searchObj = parseQueryString(window.location.search);
            const pathSearchObj = parseQueryString(route?.search || '');
            const search =
              currentPath === route?.url
                ? generateQueryString({ ...searchObj, ...pathSearchObj }).replace(/=undefined/g, '')
                : (route?.search || '').replace(/^\?/, '') || '';
            return {
              key: `${route.url}?${search}`,
              icon: typeof route.icon === 'string' ? <i className={`sv sv-${route.icon}`} /> : route.icon,
              label: route.label,
            };
          }),
          settings,
          { key: 'divider1', type: 'divider' },
          {
            key: 'utilities',
            type: 'group',
            children: [
              { key: 'hot-keys', label: 'Hot Keys' },
              { key: 'privacy-policy', label: 'Privacy Policy' },
              { key: 'change-password', label: 'Change Password', onClick: (): void => showChangePasswordModal() },
              { key: 'clear-cache', label: 'Clear Cache', onClick: (): Promise<void> => clearCache() },
            ],
          },
          { key: 'divider2', type: 'divider' },
          {
            key: 'logout',
            icon: <i className="fa fa-sign-out" />,
            label: 'Logout',
            disabled: loading.logout,
            onClick: (): Promise<void> => logout(),
          },
        ].filter((item: MenuItem): boolean => !!item.key) as MenuItem[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, loading.logout, settings, showChangePasswordModal, permissions]);

  return (
    <>
      <Button variant="icon" onClick={onToggle('menu')}>
        <i className="sv sv-menu fs-1" />
      </Button>
      <MenuDrawer
        placement="left"
        open={open.menu}
        onClose={onToggle('menu')}
        header={<NavigationMenuHeader />}
        menu={items}
        width={256}
        onClick={handleClick}
        theme="Primary"
      />
      {/* TODO: Make this a global modal so that we don't need to render it here. */}
      <HotkeysModal show={open.hotKeys} onHide={onToggle('hotKeys')} />
    </>
  );
};

export default NavigationMenu;
