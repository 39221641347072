import React, { useEffect } from 'react';
import VirtualTable, { DynamicCell, VirtualTableRow } from '../VirtualTable';

import { Button } from 'react-bootstrap';
import { Datetime } from '../../utils';
import EditModal from '../../components/EditModal/new';
import Tippy from '@tippyjs/react';
import { useNavigate } from 'react-router-dom';
import { useSearchSummaryReportSnapshotsTable } from '@/api/services/reports/searchSummaryReportSnapshots';

type Props = {
  show: boolean;
  onHide: () => void;
};
const SnapshotsModal = ({ show, onHide }: Props) => {
  const [{ data, loading }, { fetch: searchSummaryReportSnapshots }] = useSearchSummaryReportSnapshotsTable();
  const snapshots = data?.rows || [];

  const handleViewSnapshot = async (snapshotId: string) => {
    navigate(`/reports/snapshots/${snapshotId}`);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      searchSummaryReportSnapshots();
    }
  }, [show]);

  return (
    <EditModal
      name="snapshotsModal"
      show={show}
      onHide={onHide}
      title="Snapshots"
      options={{
        Footer: <></>,
      }}
    >
      <VirtualTable
        name="snapshotModalTable"
        data={snapshots}
        loading={loading}
        header={{
          name: 'Snapshot',
          id: <i className="sv sv-camera2 fs-4" />,
          createdAt: 'Created',
        }}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
            }}
          >
            <DynamicCell selector="name" placeholder="--" className="text-center" width="calc(100% / 3)" />
            <DynamicCell
              selector="createdAt"
              placeholder="--"
              className="text-center"
              width="calc(100% / 3)"
              render={({ value }) => new Datetime(value).frontendDatetime}
            />
            <DynamicCell
              selector="id"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 3)"
              render={({ value }: { value: string }) => (
                <Tippy content="View Snapshot">
                  <Button variant="icon" onClick={() => handleViewSnapshot(value)}>
                    <i className="sv sv-camera2 fs-4 pointer" />
                  </Button>
                </Tippy>
              )}
            />
          </VirtualTableRow>
        )}
      />
    </EditModal>
  );
};

export default SnapshotsModal;
