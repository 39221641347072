import './styles.scss';

import { Button, Form, InputGroup } from 'react-bootstrap';
import React, { ReactNode, useMemo, useRef, useState } from 'react';

import { DynamicCell } from '..';
import Tippy from '@tippyjs/react';
import useOnClickOutside from '@/hooks/useOnClickOutside';

// TODO: Better typing support - Dane E. Parchment Jr.
export const EditableCell = ({
  onEdit,
  options = {},
  disabled = false,
  display: Display = ({ children }: { children: ReactNode; onCancel?: () => void }) => children,
  format = undefined,
  ...props
}: any): JSX.Element => {
  const formRef = useRef(undefined);
  const [state, setState] = useState({ editing: false, savedValue: undefined });
  const { editing, savedValue } = state;

  const Render = ({ value }: { value: string }): string | JSX.Element => {
    const [localValue, setLocalValue] = useState(savedValue !== undefined ? savedValue : value);
    const onSubmit = (value?): void => {
      setState((current: any): any => ({ ...current, editing: false, savedValue: value ?? localValue }));
      onEdit(value ?? localValue);
    };
    const onSuggestion = (value): void => {
      setState((current: any): any => ({ ...current, editing: false, savedValue: value }));
      onEdit(value);
    };
    const onCancel = (): void => setState((current: any): any => ({ ...current, editing: false }));
    // TODO: Better typing support - Dane E Parchment Jr.
    const onChange = (event: any): void => {
      const value = options?.input?.onChange ? options.input.onChange(event) : event.target.value;
      setLocalValue(value);
    };
    useOnClickOutside(formRef, (e) => {
      const target = e.target as HTMLElement;
      if (target.className.includes('Suggested-Rates')) return;
      onCancel();
    });

    const RenderAs = useMemo(() => options?.input?.as, []);
    const displayValue = useMemo(() => {
      try {
        return format ? format(savedValue || value) : savedValue || value;
      } catch (err) {
        console.log(err.message || err);
        return savedValue || value;
      }
    }, [value]);

    return (
      <div className="EditableCell">
        <Tippy
          content={
            editing ? (
              <Display
                onCancel={onCancel}
                onValueChange={(val) => {
                  onSuggestion(val);
                }}
              >
                <InputGroup
                  ref={formRef}
                  onDoubleClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {RenderAs && (
                    <RenderAs
                      value={localValue}
                      onChange={(value) => onChange({ target: { value } })}
                      onSubmit={onSubmit}
                      onCancel={onCancel}
                    />
                  )}
                  {!RenderAs && (
                    <Form.Control
                      {...(options?.input || {})}
                      onChange={onChange}
                      onKeyDown={(event: any): void => {
                        if (event.key === 'Enter') onSubmit();
                        if (event.key === 'Escape') onCancel();
                      }}
                      value={localValue}
                      onFocus={(event: any): void => event.target.select()}
                      autoFocus={options?.input?.autoFocus || false}
                    />
                  )}
                  <Button name="SUBMIT" variant="outline-success" onClick={() => onSubmit()}>
                    <i className="fa fa-save" />
                  </Button>
                  <Button name="CANCEL" variant="outline-danger" onClick={onCancel}>
                    <i className="fa fa-times" />
                  </Button>
                </InputGroup>
              </Display>
            ) : null
          }
          visible={editing}
          interactive={true}
          appendTo={document.body}
        >
          <Button
            variant="icon"
            className="EditableCell-Value d-flex w-100 p-0 {position:relative;}"
            onClick={
              onEdit && !disabled
                ? (): void => {
                    setState((current: any): any => ({ ...current, editing: true }));
                    setLocalValue(savedValue !== undefined ? savedValue : value);
                  }
                : undefined
            }
          >
            <span className="flex-grow-1">{displayValue}</span>
            {onEdit && !disabled && (
              <small className="cursor:pointer;">
                <i className="fa fa-pencil opacity-hover" />
              </small>
            )}
          </Button>
        </Tippy>
      </div>
    );
  };
  return <DynamicCell {...props} value={savedValue} render={Render} />;
};
