import HasPermission, { PermissionAuth } from '@/components/HasPermission';
import { InitComponentStateType, PersistComponentOptions, createPersistentComponentState } from '@/state';

export enum ColumnKeyEnum {
  FLAGS = 'flags',
  TYPE = 'type',
  SCHEDULED_DATE = 'scheduledDate',
  SCHEDULED_TIME = 'scheduledTime',
  ACTUAL = 'actual',
  CITY = 'city',
  AIRPORT_CODE = 'airportCode',
  IATA_CODE = 'iataCode',
  FLIGHT_NUMBER = 'flightNumber',
  PILOT = 'pilot',
  ATTENDANT = 'attendant',
  DRIVER = 'driver',
  VEHICLE = 'vehicle',
  PU_LOCATION = 'puLocation',
  DO_LOCATION = 'doLocation',
  CLIENT = 'client',
  COMPLETION = 'completion',
  ACTIVITY = 'activity',
  FCR = 'fcr',
  COMBINE = 'combine',
  RATE = 'rate',
}
export enum IntervalUnitEnum {
  SECONDS = 'Seconds',
  MINUTES = 'Minutes',
}
export type TripSettingsState = {
  show: boolean;
  remember: boolean;
  columns: Map<ColumnKeyEnum, boolean>;
  tripsPagePollInterval: number;
  tripsPagePollIntervalUnit: IntervalUnitEnum;
  onToggleColumn: (key: ColumnKeyEnum) => void;
  setColumns: () => void;
};
const COLUMN_PERMISSION_MAPPING: Partial<Record<ColumnKeyEnum, keyof PermissionAuth>> = {
  [ColumnKeyEnum.RATE]: 'allowSearchTripsByRate',
};
export const DEFAULT_TRIPS_PAGE_POLL_INTERVAL = 1000 * 60 * 2; // 2 minutes
const initTripSettingsState: InitComponentStateType<TripSettingsState> = (set, get) => ({
  show: false,
  remember: false,
  columns: new Map(),
  tripsPagePollInterval: DEFAULT_TRIPS_PAGE_POLL_INTERVAL,
  tripsPagePollIntervalUnit: IntervalUnitEnum.MINUTES,
  onToggleColumn: (key: ColumnKeyEnum): void => {
    set((current: TripSettingsState): TripSettingsState => {
      const value = current.columns.get(key);
      if (value === undefined) return current; // tried to set a column that should be hidden
      // otherwise toggle the column
      const columns = new Map(current.columns);
      columns.set(key, !value);
      return { ...current, columns };
    });
  },
  setColumns: (): void => {
    set((current: TripSettingsState): TripSettingsState => {
      // convert the set to map
      const keys = Object.values(ColumnKeyEnum);
      const columns = new Map();
      const currentColumns = new Map(current.columns);
      keys.forEach((k: ColumnKeyEnum): void => {
        const checkPermission = COLUMN_PERMISSION_MAPPING[k];
        if (checkPermission && !HasPermission.check(checkPermission)) return;
        // if remember is true and the column is already in the map
        if (current.remember && currentColumns.has(k)) {
          columns.set(k, currentColumns.get(k));
          return;
        }
        columns.set(k, true);
      });
      return { ...current, columns };
    });
  },
});

const persistComponentOptions: PersistComponentOptions<TripSettingsState> = {
  live: false,
  reviver: (key: string, value: any): any => {
    if (key === 'columns') {
      if (Array.isArray(value)) return new Map(value);
      if (typeof value === 'object') return new Map(Object.entries(value));
    }
    return value;
  },
  replacer: (key: string, value: any): any => {
    if (key === 'columns') return Array.from(value.entries());
    return value;
  },
};

export const TRIPS_SETTINGS_STORAGE_KEY = 'tripSettings';
const useTripSettings = createPersistentComponentState(TRIPS_SETTINGS_STORAGE_KEY, initTripSettingsState, persistComponentOptions);

export default useTripSettings;
