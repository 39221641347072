import './styles.scss';

import { Button, Col, Row } from 'react-bootstrap';
import { DATETIME_RFC_FORMAT, TIMEZONE_OFFSET, TODAY, TODAY_EOD } from '@/constants';
import MessageCenterFilters, { useMessageCenterFilters } from './components/MessageCenterFilters';
import React, { useCallback, useEffect, useRef } from 'react';
import useChatRooms, { ChatRoomType, useChatState, useSelectedRoomState } from '@/hooks/useSocket/chat/useChatRooms';

import { Datetime } from '@/utils';
import MessageCenterChatList from './components/MessageCenterChatList';
import MessageCenterContent from './components/MessageCenterContent';
import MessageCenterNoContent from './components/MessageCenterNoContent';
import ProtectedRoute from '@/components/ProtectedRoute';
import useInterval from '@/hooks/useInterval';
import useViewport from '@/hooks/useViewport';

type MessageCenterProps = {};
const MessageCenter = ({ ..._props }: MessageCenterProps) => {
  const filters = useMessageCenterFilters(({ state }) => state);
  const [, setState] = useChatState(({ state: { totalUnread }, setState }) => [totalUnread, setState]);
  const [selectedRoom, setSelectedRoom] = useSelectedRoomState(({ state: { room }, setState }) => [room, setState]);
  const [, , { getRooms }] = useChatRooms();
  const defaultChatRef = useRef<HTMLButtonElement>(null);
  const buttonTabsRef = useRef<HTMLDivElement>(null);
  const [{ content: { height: viewportHeight = 0 } = {} } = {}] = useViewport();

  useEffect(() => {
    setSelectedRoom({ room: undefined });
  }, []);

  useInterval(() => {
    setState((current) => {
      const now = Date.now();
      return {
        ...current,
        usersTyping: current.usersTyping.filter((user: any) => now - user.timestamp < 3000),
      };
    });
  }, 3000);

  const getList = useCallback(
    (vals = filters) => {
      const nonFormatFilters = {
        start_datetime: new Datetime(vals?.startDatetime || TODAY)
          .startOf('day')
          .add(TIMEZONE_OFFSET, 'minutes')
          .format(DATETIME_RFC_FORMAT),
        end_datetime: new Datetime(vals?.endDatetime || TODAY_EOD).endOf('day').add(TIMEZONE_OFFSET, 'minutes').format(DATETIME_RFC_FORMAT),
        user_provider_id: vals?.userProviderId ? [vals?.userProviderId] : null,
        keyword: vals?.keyword ? vals?.keyword : null,
      };
      getRooms({
        format: ChatRoomType.ALL_CHAT,
        ...nonFormatFilters,
      });
      getRooms({
        format: ChatRoomType.MY_CHAT,
        ...nonFormatFilters,
      });
      setSelectedRoom({ room: undefined });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getRooms]
  );

  useEffect(() => {
    getList();
  }, [getList]);

  return (
    <ProtectedRoute title="Message Center" className="MessageCenter">
      {/* TODO: Implement actual filtering */}
      <MessageCenterFilters onSubmit={getList} />
      {/* <PageInfo>Unread Messages: {totalUnread || 0}</PageInfo> */}
      <Row className={`MessageCenter-Container m-0`}>
        <Col sm="2">
          <Row ref={buttonTabsRef}>
            <Col className="g-0">
              <Button className="MessageCenter-Button" variant="primary" size="lg">
                All Chats
              </Button>
            </Col>
          </Row>
          <Row>
            <Col
              className={`position-relative {max-height:${viewportHeight - (buttonTabsRef.current?.offsetHeight || 0)}px;height:${viewportHeight}px;overflow:auto;} p-0`}
            >
              <MessageCenterChatList type={ChatRoomType.ALL_CHAT} />
            </Col>
          </Row>
        </Col>
        <Col sm="8" className={`d-flex p-0`}>
          {selectedRoom && <MessageCenterContent room={selectedRoom} />}
          {!selectedRoom && <MessageCenterNoContent />}
        </Col>
        <Col sm="2">
          <Row>
            <Col className="g-0">
              <Button className="MessageCenter-Button" ref={defaultChatRef} variant="primary" size="lg">
                My Chats
              </Button>
            </Col>
          </Row>
          <Row>
            <Col
              className={`position-relative {max-height:${viewportHeight - (buttonTabsRef.current?.offsetHeight || 0)}px;height:${viewportHeight}px;overflow:auto;} p-0`}
            >
              <MessageCenterChatList type={ChatRoomType.MY_CHAT} />
            </Col>
          </Row>
        </Col>
      </Row>
    </ProtectedRoute>
  );
};

export default MessageCenter;
