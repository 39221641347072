import { Button, Col, Collapse, Container, InputGroup, Row } from 'react-bootstrap';
import { Datetime, Validation, queryInput, stringify } from '@/utils';
import { EventObject, OnChange } from '@/hooks/useOnChange';
import { Location, Stop, StopTypeEnum, TripKindEnum } from '@/models/gen/graphql';
import React, { useCallback, useMemo, useState } from 'react';
import useTripView, { TripStop, UseTripViewState } from '@/features/Trips/components/TripModal/views/trip/hook';

import FormField from '@/components/FormField';
import SelectAirlineIata from '@/components/SelectAirlineIata';
import SelectAirport from '@/components/SelectAirport';
import SelectClient from '@/components/SelectClient';
import SelectFlag from '@/components/SelectFlag';
import SelectKind from '@/components/SelectKind';
import SelectLocation from '@/components/SelectLocation';
import SelectStatus from '@/components/SelectStatus';
import SelectStopType from '@/components/SelectStopType';
import SelectType from '@/components/SelectType';
import TippyWhen from '@/components/TippyWhen';
import { TripViewProps } from '@/features/Trips/components/TripModal/views/trip';
import { TripViewHeader } from '@/features/Trips/components/TripModal/views/trip/modal';
import { NumberInputField } from '@/components/NumberInput';
import Calendar from '@/components/Calendar';
import equal from 'fast-deep-equal/es6/react';
import useEventListener from '@/hooks/useEventListener';

const TripDrawerView = (props: TripViewProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [
    { data, title, mode, trip, stopsDisabled, validity, loopOrFlightNumber, selectedCount, uuid, isValid },
    { onChange, setState, onSaveAndCopy, handleSubmit, handleHide, onBlurFlightNumber, onAddStop },
  ] = useTripView(props);
  const valid = isValid && !equal(trip, data) && (trip?.pilots > 0 || trip?.attendants > 0);

  const MemoizedTripStop = useMemo(() => {
    const TripsStopComponent = (props: { index: number; data: Partial<Stop> }): JSX.Element => {
      return (
        <TripStop
          onDelete={(): void =>
            setState(
              (current: UseTripViewState): UseTripViewState => ({
                ...current,
                trip: {
                  ...(current?.trip || {}),
                  stops: (current?.trip?.stops || []).filter((_stop: Partial<Stop>, i: number): boolean => i !== props?.index),
                },
              })
            )
          }
          onChange={(event: EventObject): void => {
            const { name, value } = event?.target || {};
            switch (name.split('.').pop()) {
              case 'locationId':
                return value !== '' ? onChange(event) : undefined;
              case 'airportFilter':
                onChange({ target: { name: `trip.stops.${props?.index}.locationId`, value: '' } });
              default:
                return onChange(event);
            }
          }}
          valid={{
            locationId: Validation.isValidUUID(props?.data?.locationId) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
          }}
          options={{
            filterLocationByAirport: true,
          }}
          disabled={mode === 'editing' && stopsDisabled}
          readOnly={mode === 'editing' && stopsDisabled}
          tripScheduledTime={trip?.scheduled}
          {...props}
        />
      );
    };
    return TripsStopComponent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitViaHotkeys = useCallback(
    (event) => {
      if (event.key === 'Enter' && event?.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
        if (valid) handleSubmit();
      }
    },
    [valid]
  );

  useEventListener('keydown', handleSubmitViaHotkeys);

  // TODO: replace all FormField components with their respective primitives (Input, Dropdown, etc)
  return (
    <Container fluid>
      <TripViewHeader manifestImportId={trip?.manifestImportId} title={title} />
      <Row>
        <Col xs={6}>
          <FormField
            label="SCH Type:"
            placeholder="Select Type"
            name="trip.type"
            value={trip?.type || ''}
            onChange={onChange}
            valid={validity?.type?.valid}
            searchable
            options={{
              input: {
                as: SelectType,
              },
            }}
          />
          <FormField
            label="Client:"
            placeholder="Select Client"
            name="trip.payerProviderId"
            value={trip?.payerProviderId || ''}
            onChange={(event) => {
              const value = event?.target?.value;
              onChange.bulk([{ target: { name: 'trip.providerId', value } }, { target: { name: 'trip.payerProviderId', value } }]);
            }}
            valid={validity?.payerProviderId?.valid}
            searchable
            options={{
              input: {
                as: SelectClient,
              },
            }}
          />
          <FormField
            label="Airline:"
            placeholder="Select Airline"
            name="trip.servicerIataAirlineCode"
            value={trip?.servicerIataAirlineCode || ''}
            onChange={onChange}
            valid={validity?.servicerIataAirlineCode?.valid}
            searchable
            options={{
              input: {
                as: SelectAirlineIata,
              },
            }}
          />
          <FormField
            label="Airport:"
            placeholder="Select Airport"
            name="trip.airportCode"
            value={trip?.airportCode || ''}
            onChange={(event: EventObject): void => {
              const { value } = event?.target || {};
              if (!value) return;
              onChange.bulk([
                { target: { name: 'trip.airportCode', value } },
                { target: { name: 'trip.doLocationId', value } },
                { target: { name: 'trip.doAirportFilter', value } },
                {
                  target: {
                    name: 'trip.stops',
                    value: (trip?.stops || []).map(
                      (stop: Partial<Stop>): TripStop => ({
                        ...stop,
                        airportFilter: value,
                        locationId: '',
                      })
                    ),
                  },
                },
              ]);
            }}
            valid={validity?.airportCode?.valid}
            searchable
            options={{
              input: {
                as: SelectAirport,
              },
            }}
          />
        </Col>
        <Col xs={6}>
          {mode === 'creating' && (
            <>
              <div className="{font-size:0.8rem;color:var(--bs-primary);margin-top:1.75rem;}">PICKUP DATE:</div>
              <Calendar
                name={'trip.scheduledDays'}
                value={trip?.scheduledDays || []}
                selector="day"
                onChange={onChange}
                range={false}
                multiple={true}
                isValid={!!trip?.scheduledDays?.length}
                isInvalid={!trip?.scheduledDays?.length}
              />
            </>
          )}
          {mode === 'editing' && (
            <>
              <div className="{font-size:0.8rem;color:var(--bs-primary);margin-top:1.75rem;}">PICKUP DATE:</div>
              <Calendar
                name={'trip.scheduled'}
                value={trip?.scheduled ? [trip.scheduled] : []}
                selector="day"
                onChange={(event: EventObject): void => {
                  const { value: initValue } = event?.target || {};
                  const value = (Array.isArray(initValue) ? initValue[0] : initValue) || '';
                  if (!value || trip?.increment) return;
                  const currentScheduledTime = trip?.scheduled?.split('T')?.[1];
                  const newScheduledTime = currentScheduledTime ? value.concat(' ', currentScheduledTime) : value;
                  const result: EventObject[] = [{ target: { name: 'trip.scheduled', value: new Datetime(newScheduledTime).toString() } }];
                  if (trip?.stops?.length) {
                    const originalStops = [...(trip?.stops || [])];
                    const stops = originalStops.map(
                      (stop: Stop): Stop => ({
                        ...stop,
                        scheduled: value && stop?.scheduled ? new Datetime(stop?.scheduled || undefined).setDate(value).toString() : null,
                      })
                    );
                    result.push({ target: { name: 'trip.stops', value: stops } });
                  }
                  onChange.bulk(result);
                }}
                range={false}
                multiple={false}
                disabled={selectedCount > 1 || trip?.increment}
                isValid={!!validity?.scheduled?.valid}
                isInvalid={!validity?.scheduled?.valid}
              />
            </>
          )}
        </Col>
      </Row>
      <div className="d-flex gap-4">
        <FormField
          type="time"
          name="trip.scheduled"
          label="SCH TIME:"
          value={trip?.scheduled ? new Datetime(trip?.scheduled).fullTime : ''}
          onChange={(event: EventObject) => {
            const { name, value } = event.target;
            if (!value) return;
            const formattedTime = new Datetime(trip?.scheduled).setTime(value).time;
            const input = { target: { name, value: formattedTime } };
            onChange.time(input);
          }}
          valid={validity?.scheduled?.valid}
          disabled={stopsDisabled}
          options={{ input: { className: 'Scheduled p-0' } }}
          condensed
        />
        <FormField
          type="time"
          name="trip.actual"
          label="ACT TIME:"
          value={undefined}
          onChange={() => {}}
          disabled={true}
          options={{ group: { className: 'opacity-25' } }}
          condensed
        />
      </div>
      <div className="d-flex gap-4">
        <FormField
          label="Arrival/Departure:"
          placeholder="Select Kind"
          name="trip.kind"
          value={trip?.kind || ''}
          onChange={(event): void => {
            const { value } = event?.target || {};
            const { puLocationId = '', doLocationId = '' } = data || {};
            onChange.bulk([
              { target: { name: 'trip.kind', value } },
              { target: { name: 'trip.puLocationId', value: doLocationId } },
              { target: { name: 'trip.doLocationId', value: puLocationId } },
            ]);
          }}
          valid={validity?.kind?.valid}
          searchable
          options={{
            input: {
              as: SelectKind,
            },
          }}
        />
        <FormField
          label="Flight # / Loop Name:"
          name="trip.loopOrFlightNumber"
          value={loopOrFlightNumber}
          onChange={(event) => {
            const { value } = event?.target || {};
            const isLoopName = value.length > 4 || value.match(/\D/g);
            const flightNumber = !isLoopName ? value : undefined;
            const loopName = isLoopName ? value : undefined;
            setState(
              (current: UseTripViewState): UseTripViewState => ({
                ...current,
                loopOrFlightNumber: value,
                trip: { ...(current?.trip || {}), flightNumber, loopName },
              })
            );
          }}
          onBlur={onBlurFlightNumber}
          valid={validity?.flightNumber?.valid || validity?.loopName?.valid}
        />
      </div>
      <div className="d-flex gap-4">
        <NumberInputField
          label="PLT:"
          name="trip.pilots"
          value={trip?.pilots || 0}
          onChange={(value: number) => onChange({ target: { name: 'trip.pilots', value } })}
          valid={trip?.pilots > 0 || trip?.attendants > 0}
          options={{ field: { className: '{width:19rem;}' } }}
          min={0}
          max={99}
        />
        <NumberInputField
          label="F/A:"
          name="trip.attendants"
          value={trip?.attendants || 0}
          onChange={(value: number) => onChange({ target: { name: 'trip.attendants', value } })}
          valid={trip?.attendants > 0 || trip?.pilots > 0}
          options={{ field: { className: '{width:19rem;}' } }}
          min={0}
          max={99}
        />
      </div>
      {trip?.payerProviderId === '4b4c2baf-5ec7-11ed-a8a3-0242ac130003' && (
        <>
          <NumberInputField
            label="Crew ID:"
            name="trip.crewId"
            value={trip?.crewId}
            onChange={(value: number) => onChange.int({ target: { name: 'trip.crewId', value } })}
            className="CrewId"
            min={0}
          />
          <div className="d-flex gap-4">
            <FormField
              label="First Name:"
              name="trip.firstName"
              value={trip?.firstName || ''}
              onChange={onChange}
              valid={validity?.firstName?.valid}
              condensed
            />
            <FormField
              label="Last Name:"
              name="trip.lastName"
              value={trip?.lastName || ''}
              onChange={onChange}
              valid={validity?.lastName?.valid}
              condensed
              options={{ group: { className: 'mb-4' } }}
            />
          </div>
        </>
      )}
      <TripStop
        data={{
          type: StopTypeEnum.Pu,
          airportFilter: trip?.airportCode || '',
          locationId: trip?.puLocationId,
          scheduled: trip?.scheduled,
        }}
        valid={{ locationId: validity?.puLocationId?.valid, scheduled: validity?.scheduled?.valid }}
        index={-1}
        disabled={stopsDisabled}
        tripScheduledTime={trip?.scheduled}
        onChange={(event: EventObject) => {
          const { name, value } = event?.target || {};
          switch (name.split('.').pop()) {
            case 'locationId':
              return value !== '' ? onChange({ target: { name: 'trip.puLocationId', value } }) : undefined;
            default:
              return onChange({ target: { name: `trip.${name.split('.').pop()}`, value } });
          }
        }}
        options={{
          autoSelectLocation: trip?.kind === TripKindEnum.Arr,
          disableType: true,
          hideCity: true,
          hideScheduled: true,
          location: {
            props: {
              query: {
                airportCode: trip?.airportCode || null,
              },
            },
          },
        }}
      />
      {trip?.stops?.length > 0 && trip?.stops.map((stop, s) => <MemoizedTripStop key={s} index={s} data={stop} />)}
      <TripStop
        data={{
          type: StopTypeEnum.Do,
          locationId: trip?.doLocationId,
          scheduled: trip?.doScheduledDatetime,
          airportFilter: trip?.doAirportFilter || trip?.doLocation?.airports?.[0]?.airportCode || trip?.airportCode || '',
        }}
        tripScheduledTime={trip?.scheduled}
        valid={{ locationId: validity?.doLocationId?.valid }}
        disabled={stopsDisabled}
        index={trip?.stops?.length || 0}
        onChange={(event: EventObject) => {
          const { name, value } = event?.target || {};
          switch (name.split('.').pop()) {
            case 'locationId':
              return value !== '' ? onChange({ target: { name: 'trip.doLocationId', value } }) : undefined;
            case 'airportFilter':
              onChange.bulk([{ target: { name: 'trip.doLocationId', value: '' } }, { target: { name: 'trip.doAirportFilter', value } }]);
            default:
              return onChange({ target: { name: `trip.${name.split('.').pop()}`, value } });
          }
        }}
        options={{
          autoSelectLocation: trip?.kind === TripKindEnum.Dep,
          filterLocationByAirport: true,
          disableType: true,
          disableTime: true,
          hideScheduled: true,
        }}
      />

      <Button name="ADD_STOP" className="w-100 my-3 add-stop-btn" variant="outline-gray" onClick={onAddStop}>
        <i className="fa fa-plus" /> ADD STOP
      </Button>
      <div className="d-flex justify-content-center view-more-button">
        <Button
          variant="text"
          onClick={() => setShowMore((current) => !current)}
          aria-controls="collapse-additional-drawer-fields"
          aria-expanded={showMore}
        >
          {showMore ? 'View Less' : 'View More'}
        </Button>
      </div>
      <Collapse in={showMore}>
        <div id="collapse-additional-drawer-fields">
          <div className="d-flex gap-4">
            <FormField
              type="time"
              name="trip.puActualDatetime"
              label="RFP:"
              value={trip?.puActualDatetime ? new Datetime(trip?.puActualDatetime).fullTime : ''}
              onChange={onChange?.time || onChange}
              disabled={stopsDisabled}
              valid={validity?.puActualDatetime?.valid}
              options={{ group: { className: 'flex-grow-1' }, input: { className: 'Ready p-0' } }}
              tabIndex={-1}
              condensed
            />
            <FormField
              type="time"
              name="trip.puCompletedDatetime"
              label="P/U:"
              value={trip?.puCompletedDatetime ? new Datetime(trip?.puCompletedDatetime).fullTime : ''}
              onChange={onChange?.time || onChange}
              disabled={stopsDisabled}
              valid={validity?.puCompletedDatetime?.valid}
              options={{ group: { className: 'flex-grow-1' }, input: { className: 'Actual' } }}
              tabIndex={-1}
              condensed
            />
          </div>
          <div className="d-flex gap-4">
            <FormField
              label={'Flags:'}
              placeholder="No Flags"
              name="trip.flags"
              value={(trip?.flags || []).map((node) => node.id || node) as string[]}
              onChange={onChange}
              disabled={selectedCount > 1}
              searchable
              options={{
                input: { as: SelectFlag, className: 'Flags' },
              }}
            />
            <FormField
              label={'Status:'}
              placeholder="Select Status"
              name="trip.status"
              value={trip?.status || ''}
              onChange={(event: EventObject): void => (event?.target?.value ? onChange(event) : undefined)}
              valid={validity?.status?.valid}
              searchable
              options={{
                input: { as: SelectStatus, className: 'Status' },
              }}
            />
          </div>
          <div className="d-flex gap-4">
            <FormField
              label="Include this trip in future manifests?"
              id={`future_${uuid}`}
              type="switch"
              name="trip.fromManifest"
              checked={trip?.fromManifest !== undefined ? !!trip?.fromManifest : true}
              onChange={onChange.toggleInt}
              options={{ label: { className: '{text-wrap:wrap;text-align:left;}' } }}
              reverse
            />
            <NumberInputField
              label="Split:"
              name="trip.split"
              value={trip?.split}
              onChange={(value: number) => onChange({ target: { name: 'trip.split', value } })}
              options={{ field: { className: 'w-100' } }}
              min={0}
              max={99}
            />
          </div>
          <FormField
            type="textarea"
            label="Comments:"
            name="trip.comments"
            value={trip?.comments || ''}
            onChange={onChange}
            options={{ group: { className: 'mt-0' }, input: { className: 'Comments {height:5rem;resize:none;}' } }}
          />
          {mode === 'editing' && (
            <div className="d-flex gap-4">
              <FormField
                label="Increment?"
                id={`increment_${uuid}`}
                type="switch"
                name="trip.increment"
                checked={!!trip?.increment}
                onChange={onChange.toggle}
                condensed
              />
              <FormField
                label="Days:"
                type="number"
                name="trip.days"
                value={trip?.days || 0}
                disabled={!trip?.increment}
                onChange={onChange.int}
                condensed
              />
            </div>
          )}
        </div>
      </Collapse>
      <div className="mt-4">
        {mode === 'creating' && (
          <TippyWhen isTrue={!valid} options={{ content: 'No changes have been made, or fields are invalid.' }}>
            <Button className="w-100 my-2" variant="black" onClick={onSaveAndCopy} disabled={!valid}>
              SAVE COPY
            </Button>
          </TippyWhen>
        )}
        <Row className="mb-4">
          <Col xs={6}>
            <Button name="CANCEL" className="w-100" variant="secondary" onClick={handleHide}>
              CANCEL
            </Button>
          </Col>
          <Col xs={6}>
            <TippyWhen isTrue={!valid} options={{ content: 'No changes have been made, or fields are invalid.' }}>
              <Button name="SUBMIT" className="w-100" variant="primary" onClick={handleSubmit} disabled={!valid}>
                SAVE
              </Button>
            </TippyWhen>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default TripDrawerView;

interface TripStopProps {
  data: TripStop;
  onDelete?: () => void;
  onChange: OnChange;
  index: number;
  disabled?: boolean;
  readOnly?: boolean;
  valid?: { locationId?: boolean | Validation.ValidityType; scheduled?: boolean | Validation.ValidityType };
  options?: {
    autoSelectLocation?: boolean;
    filterLocationByAirport?: boolean;
    location?: {
      props?: any;
    };
    disableType?: boolean;
    disableTime?: boolean;
    hideCity?: boolean;
    hideScheduled?: boolean;
  };
  tripScheduledTime?: string;
}
const TripStopComponent = ({
  data: stop,
  onDelete,
  onChange,
  index: s,
  disabled,
  readOnly,
  valid,
  options,
  tripScheduledTime,
}: TripStopProps): JSX.Element => {
  const filters = options?.location?.props?.filter || {};
  return (
    <Row className="TripStop align-items-center pt-2 pb-3">
      <Col xs={2} className="pe-0">
        <InputGroup size={onDelete || !disabled ? 'sm' : undefined}>
          {onDelete && !disabled && (
            <InputGroup.Text>
              <Button variant="icon" className="p-0" onClick={onDelete}>
                <i className="fa fa-minus-circle text-danger" />
              </Button>
            </InputGroup.Text>
          )}
          <SelectStopType
            name={`trip.stops.${s}.type`}
            value={stop?.type || 'PU'}
            onChange={onChange}
            disabled={options?.disableType || disabled}
            readOnly={readOnly}
            placeholder="--"
          />
        </InputGroup>
      </Col>
      <Col
        xs={10 - (options?.hideCity ? 0 : 2) - (options?.hideScheduled ? 0 : 2)}
        className={`ps-1 ${options?.hideCity && options?.hideScheduled ? '' : 'pe-0'}`}
      >
        <FormField
          {...(options?.location?.props || {})}
          query={{
            ...filters,
            airportCode: queryInput(filters?.airportCode || stop?.airportFilter),
          }}
          inputOptions={{ removeAirportPrefix: true, autoSelectLocation: !!options?.autoSelectLocation }}
          placeholder="Select Location"
          name={`trip.stops.${s}.locationId`}
          value={stop?.locationId}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          valid={valid?.locationId}
          searchable
          onCreate={(location: Location): void => onChange({ target: { name: `trip.stops.${s}.locationId`, value: location?.id } })}
          options={{ input: { as: SelectLocation }, group: { className: 'ps-1' } }}
          inline
          condensed
        />
      </Col>
      {!options?.hideCity && (
        <Col xs={2} className="ps-1">
          <FormField
            placeholder="City"
            name={`trip.stops.${s}.airportFilter`}
            value={stop?.airportFilter}
            onChange={onChange}
            disabled={disabled}
            readOnly={readOnly}
            searchable
            options={{ input: { as: SelectAirport }, group: { className: `ps-1 ${options?.hideCity ? 'd-none' : ''}` } }}
            condensed
          />
        </Col>
      )}
      {!options?.hideScheduled && (
        <Col xs={2} className="ps-1">
          <InputGroup className="p-relative">
            <FormField
              type="time"
              name={`trip.stops.${s}.scheduled`}
              key={`trip.stops.${s}.scheduled`}
              value={stop?.scheduled ? new Datetime(stop?.scheduled).time : ''}
              onChange={(event: EventObject) => {
                const { name, value } = event.target;
                const formattedTime = new Datetime(tripScheduledTime).setTime(value).toString();
                const input = { target: { name, value: formattedTime } };
                onChange?.time ? onChange.time(input) : onChange(input);
              }}
              valid={valid?.scheduled}
              disabled={options?.disableTime || disabled}
              readOnly={readOnly}
              options={{ input: { className: 'Scheduled p-0' } }}
              condensed
            />
          </InputGroup>
        </Col>
      )}
    </Row>
  );
};
const TripStop = React.memo(TripStopComponent, (prev: Readonly<TripStopProps>, next: Readonly<TripStopProps>): boolean =>
  stringify.compare(prev?.data, next?.data)
);
