import { Button, Col, Row } from 'react-bootstrap';
import { Datetime, QueryInputType, Validation, queryInput } from '@/utils';
import { FcrType, FcrTypeEdge, SortDirectionEnum } from '@/models/gen/graphql';
import React, { useEffect, useMemo, useRef } from 'react';
import VirtualTable, { DynamicCell, VirtualTableRow } from '@/components/VirtualTable';

import ConfirmationButton from '@/components/ConfirmationButton';
import EditModal from '@/components/EditModal/new';
import FormField from '@/components/FormField';
import RadioButton from '@/components/RadioButton';
import TippyWhen from '@/components/TippyWhen';
import { createComponentState } from '@/state';
import deleteFcrBulk from '@/api/services/fcrs/deleteFcrBulk';
import getFcrTypes from '@/queries/fcrs/getFcrTypes';
import useDb from '@/hooks/useDb';
import useOnChange from '@/hooks/useOnChange';
import { useSearchFcrs } from '@/api/services/fcrs/searchFcrs';
import useTrips from '@/hooks/useTrips';

type EditFcrModalState = {
  show?: boolean;
  loading?: boolean;
  tripId?: string;
  servicerIataAirlineCode?: string;
  flightNumber?: string;
  scheduled?: string;
  fcrTypeId?: string;
  description?: string;
  cause?: string;
  onSubmit?: (data?: any) => Promise<void>;
  onHide?: () => void;
};

const initEditFcrModalState: EditFcrModalState = {
  show: false,
  loading: false,
  tripId: '',
  servicerIataAirlineCode: '',
  flightNumber: '',
  scheduled: '',
  fcrTypeId: '',
  description: '',
  cause: '',
  onSubmit: async (): Promise<void> => undefined,
  onHide: (): void => undefined,
};

export const useEditFcrModal = createComponentState(initEditFcrModalState);

const EditFcr: any = (): JSX.Element => {
  const [state, setState] = useEditFcrModal(({ state, setState }) => [state, setState]);
  const {
    show = false,
    loading = false,
    tripId = '',
    servicerIataAirlineCode = '',
    flightNumber = '',
    scheduled = '',
    fcrTypeId = '',
    description = '',
    cause = '',
    onHide = () => undefined,
    onSubmit = async () => undefined,
  } = state;
  const onChange = useOnChange(setState);
  const deletedFcrs = useRef(false);

  // Init State
  const [validity, valid] = useMemo((): [Record<string, { valid: boolean }>, boolean] => {
    const result: Record<string, { valid: boolean }> = {};
    result.description = {
      valid: (description || '').trim().length > 0 && Validation.isAlphanumeric(description),
    };
    result.cause = {
      valid: (cause || '').trim().length > 0 && Validation.isAlphanumeric(cause),
    };
    result.fcrTypeId = {
      valid: Validation.isValidUUID(fcrTypeId),
    };
    result.tripId = {
      valid: Validation.isValidUUID(tripId),
    };
    const isValid = !(Object.values(result).filter((item: { valid: boolean }): boolean => !item.valid).length > 0);
    return [result, isValid];
  }, [description, cause, fcrTypeId, tripId]);

  // Init Queries
  const [, { handleSubmitFcr }] = useTrips();
  const [{ data: searchFcrsData, loading: searchFcrsLoading }, { refetch: refetchFcrs }] = useSearchFcrs();
  const { rows = [] } = searchFcrsData || {};

  const { data: FcrTypesData = {} } = useDb('FcrTypes', getFcrTypes.query);
  const getFcrTypesData: any = useMemo(
    (): { rows: FcrType[] } => ({
      rows: (FcrTypesData.searchFcrTypes?.fcrTypeConnection?.edges || []).map((edge: FcrTypeEdge): FcrType => edge.node),
    }),
    [FcrTypesData]
  );

  // Crud Handlers
  const handleDeleteFcrs = async (id: string): Promise<void> => {
    await deleteFcrBulk([id]);
    deletedFcrs.current = true;
    refetchFcrs();
  };

  const handleSubmit = async (): Promise<void> => {
    await handleSubmitFcr({ tripId, fcrTypeId, description, cause });
    onSubmit?.();
    onHide?.();
    setState({ show: false });
  };
  const handleHide = (): void => {
    if (deletedFcrs.current) {
      handleSubmit();
    } else {
      onHide?.();
      setState({ show: false });
    }
  };

  useEffect((): void => {
    if (!tripId) return;
    refetchFcrs([
      {
        tripId: queryInput(tripId),
        createdAt: queryInput([], QueryInputType.ISNOTNULL, SortDirectionEnum.Desc),
      },
    ]);
  }, [refetchFcrs, tripId]);

  const title = `Complaint Details - ${(servicerIataAirlineCode || '').toUpperCase()}${flightNumber || ''} ${
    scheduled ? new Datetime(scheduled).frontendDatetimeShort : ''
  }`;

  return (
    <EditModal
      name="editFcr"
      icon="sv sv-fcr"
      show={show}
      onHide={handleHide}
      title={title}
      options={{ Footer: null }}
      loading={searchFcrsLoading}
      size="xl"
    >
      <Row className="d-flex justify-content-center mt-3">
        {/* Radio Component */}
        {getFcrTypesData.rows.map(
          (fcrType: FcrType, idx: number): JSX.Element => (
            <Col key={idx}>
              <RadioButton
                onClick={() => setState((current) => ({ ...current, fcrTypeId: fcrType.id }))}
                checked={fcrTypeId === fcrType.id}
              >
                {fcrType.name}
              </RadioButton>
            </Col>
          )
        )}
      </Row>
      <div className="my-3">
        <FormField
          label="Description:"
          name="description"
          type="textarea"
          onChange={onChange}
          placeholder="Description"
          value={description || ''}
          valid={validity?.description?.valid}
          condensed
        />
        <FormField
          label="Root Cause:"
          name="cause"
          type="textarea"
          onChange={onChange}
          placeholder="Root Cause"
          value={cause || ''}
          valid={validity?.cause?.valid}
        />
        <TippyWhen isTrue={!valid} options={{ content: 'No changes have been made, or fields are invalid.' }}>
          <div className="d-flex justify-content-end">
            <Button className="d-block px-5" name="SUBMIT" variant="green" disabled={!valid} onClick={handleSubmit}>
              ADD COMPLAINT
            </Button>
          </div>
        </TippyWhen>
      </div>
      {/* Table Component */}
      <VirtualTable
        name="tripFcr"
        dynamicRowHeight
        style={{ height: 300 }}
        header={{
          cause: 'Cause',
          description: 'COMPLAINT HISTORY',
          createdAt: 'DATE',
          id: 'Delete',
        }}
        data={rows}
        loading={loading}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => {
          return (
            <VirtualTableRow
              context={{
                ...context,
                rowType: _type,
                data,
                index,
              }}
            >
              <DynamicCell
                selector="cause"
                className="text-center p-0"
                width="calc(100% / 3)"
                render={({ value }: { value: string }): JSX.Element => (
                  <div className="w-100 h-100 border-0 p-2 text-black {text-align:left;font-weight:normal;}">{value}</div>
                )}
              />
              <DynamicCell
                selector="description"
                className="text-center p-0"
                width="calc(100% / 3)"
                render={({ value }: { value: string }): JSX.Element => (
                  <div className="w-100 h-100 border-0 p-2 text-black {text-align:left;font-weight:normal;}">{value}</div>
                )}
              />
              <DynamicCell
                selector="createdAt"
                className="text-center p-0"
                width="calc(100% / 6)"
                render={({ value }: { value: string }): JSX.Element => (
                  <div className="w-100 h-100 border-0 p-2 text-black {font-weight:normal;}">
                    {value ? new Datetime().setAsUTC(value).toLocaleDatetime().frontendDatetime : '--'}
                  </div>
                )}
              />
              <DynamicCell
                selector="id"
                className="text-center"
                width="calc(100% / 12)"
                render={({ value }: { value: string }): JSX.Element => (
                  <ConfirmationButton
                    name="DELETE"
                    feedback="Delete Fcr"
                    disabled={loading}
                    variant="transparent"
                    onConfirm={(): Promise<void> => handleDeleteFcrs(value)}
                    icon={<i className="sv sv-trash2 text-danger {font-size:1.5rem;}" />}
                  />
                )}
              />
            </VirtualTableRow>
          );
        }}
      />
    </EditModal>
  );
};

export default EditFcr;
